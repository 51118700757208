import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../constants';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Divider,
  Image
} from '@chakra-ui/react';
import BullRiseLogo from '../assets/bullrise-logo.svg'; // Importing the logo

// Password strength validation function
const strongPassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecialChar = /[@$!%*?&]/.test(password);

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumbers &&
    hasSpecialChar
  );
};

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle form submission for signup
  const handleSignup = async (e) => {
    e.preventDefault();

    // Validate password strength before sending the request
    if (!strongPassword(password)) {
      setError('Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.');
      return;
    }

    try {
      setLoading(true);
      await axios.post(`${API_URL}/auth/signup`, { email, password, role: 'startup' });
      navigate('/login');
    } catch (error) {
      setError('There was an error during signup. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box bg="#0f172a" minH="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={6}>
      {/* Logo - Centered at the top */}
      <Image src={BullRiseLogo} alt="BullRise Logo" boxSize="40px" mb={8} />

      {/* Signup Form */}
      <Box maxW="lg" w="full" textAlign="center">
        <Text fontSize="3xl" fontWeight="bold" mb={6} color="white">Sign Up</Text>
        <form onSubmit={handleSignup}>
          <VStack spacing={4} align="stretch">
            <FormControl isRequired>
              <FormLabel color="gray.300">Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                bg="#1e293b"
                borderColor="gray.600"
                color="white"
                placeholder="Enter your email"
                focusBorderColor="blue.500"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="gray.300">Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                bg="#1e293b"
                borderColor="gray.600"
                color="white"
                placeholder="Enter your password"
                focusBorderColor="blue.500"
              />
            </FormControl>

            {error && (
              <Text color="red.500" fontSize="sm">
                {error}
              </Text>
            )}

            <Button
              type="submit"
              isLoading={loading}
              bg="#15803d"
              color="white"
              _hover={{ bg: "#166534" }}
              width="full"
              mt={4}
            >
              Sign Up
            </Button>
          </VStack>
        </form>

        <Text mt={4} fontSize="sm" color="gray.300">
          Already a user?{' '}
          <Link to="/login" style={{ color: '#60a5fa' }}>
            Log in here
          </Link>.
        </Text>
      </Box>
    </Box>
  );
};

export default Signup;
