import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Signup from './components/Signup';
import Login from './components/Login';
import AccountPage from './components/AccountPage';
import AuditFormV2 from './components/AuditFormV2';
import AuditCompletedV2 from './components/AuditCompletedV2';
import ReportV2 from './components/ReportV2';
import TaskDashboard from './components/reportv2/tasks/TaskDashboard'; // Import TaskDashboard
import Layout from './components/Layout';
import RoleBasedRoute from './components/RoleBasedRoute';
import CompletionScreen from './components/CompletionScreen';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import { API_URL } from './constants';
import './styles/tailwind.css';
import { AuthProvider, useAuth } from './context/AuthContext';

function AppRoutes() {
  const { refreshAccessToken, token } = useAuth();

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        refreshAccessToken();
      }
    };

    const interval = setInterval(checkTokenExpiration, 300000); // Check token expiration every 5 minutes
    return () => clearInterval(interval);
  }, [token, refreshAccessToken]);

  const OAuthRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
      const handleRedirect = async () => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const refreshToken = params.get('refreshToken');

        if (token && refreshToken) {
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refreshToken);

          const userId = jwtDecode(token).id;
          const startupId = localStorage.getItem('startupId');
          try {
            const statusResponse = await axios.get(`${API_URL}/status/${userId}/${startupId}`, {
              headers: { Authorization: `Bearer ${token}` },
            });

            const statusData = statusResponse.data;
            localStorage.setItem('auditId', statusData.auditId);
            localStorage.setItem('reportId', statusData.reportId);
            localStorage.setItem('reportStatus', statusData.reportStatus);

            if (statusData.reportStatus === 'completed') {
              navigate(`/report/v2/${statusData.reportId}`);
            } else if (statusData.reportStatus === 'failed') {
              navigate(`/audit-completed-v2/${statusData.auditId}`);
            } else {
              navigate('/audit-form-v2');
            }
          } catch (error) {
            console.error('Error fetching status after OAuth:', error);
            navigate('/login');
          }
        }
      };

      handleRedirect();
    }, [navigate]);

    return <div>Loading...</div>;
  };

  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/auth/google/callback" element={<OAuthRedirect />} />

        <Route
          path="*"
          element={
            <Layout>
              {token ? (
                <Routes>
                  <Route
                    path="/audit-form-v2"
                    element={
                      <RoleBasedRoute requiredRole="startup">
                        <AuditFormV2 />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="/audit-completed-v2/:auditId"
                    element={
                      <RoleBasedRoute requiredRole="startup">
                        <AuditCompletedV2 />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="/report/v2/:reportId"
                    element={
                      <RoleBasedRoute requiredRole="startup">
                        <ReportV2 />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="/reportv2/task-dashboard"
                    element={
                      <RoleBasedRoute requiredRole="startup">
                        <TaskDashboard />
                      </RoleBasedRoute>
                    }
                  />
                  <Route path="/account" element={<AccountPage />} />
                  <Route path="/completion-test" element={<CompletionScreen />} />
                  <Route path="*" element={<Navigate to="/audit-form-v2" />} />
                </Routes>
              ) : (
                <Navigate to="/login" />
              )}
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

function App() {
  return (
    <ChakraProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
