// src/reportv2/FinancialsSection.js

import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import FinancialsTopMetrics from './financials/FinancialsTopMetrics';
import FinancialsGrowthChart from './financials/FinancialsGrowthChart';

const FinancialsSection = ({ auditData, reportData }) => {
  return (
    <VStack spacing={0} align="stretch" mb={6}>
      {/* Top Metrics Section */}
      <Box>
        <FinancialsTopMetrics auditData={auditData} reportData={reportData} />
      </Box>

      {/* Growth Chart Section */}
      <FinancialsGrowthChart auditData={auditData} reportData={reportData} />
    </VStack>
  );
};

export default FinancialsSection;
