import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Switch, Grid, ButtonGroup, Button, Collapse, IconButton, useBreakpointValue, Divider } from '@chakra-ui/react'; 
import { SettingsIcon } from '@chakra-ui/icons';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns'; // Import date adapter

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

const GrowthChart = ({ reportData }) => {
    const [chartData, setChartData] = useState(null);
    const [showRevenue, setShowRevenue] = useState(true);
    const [showCost, setShowCost] = useState(true);
    const [showBurn, setShowBurn] = useState(true);
    const [showValuation, setShowValuation] = useState(false);
    const [showCustomers, setShowCustomers] = useState(false);
    const [showEmployees, setShowEmployees] = useState(false);
    const [timeRange, setTimeRange] = useState('ALL');
    const [availableRanges, setAvailableRanges] = useState([]);
    const [showSettings, setShowSettings] = useState(false);
    const columns = useBreakpointValue({ base: 'repeat(2, 1fr)', md: 'repeat(6, 1fr)' });

    // Retrieve auditData from local storage
    const auditData = JSON.parse(localStorage.getItem('auditData')) || {};
    const foundingDate = auditData?.foundingDate || '2021-06';

    // Dynamically calculate available ranges based on years since founding
    useEffect(() => {
        if (!foundingDate) return;

        const currentYear = new Date().getFullYear();
        const foundingYear = parseInt(foundingDate.split('-')[0]);
        const yearsSinceFounding = currentYear - foundingYear;

        const ranges = ['3M', '6M', 'YTD'];
        if (yearsSinceFounding >= 1) ranges.push('1Y');
        if (yearsSinceFounding >= 2) ranges.push('2Y');
        if (yearsSinceFounding >= 5) ranges.push('5Y');
        ranges.push('ALL');

        setAvailableRanges(ranges);
    }, [foundingDate]);

    // Helper to calculate start date based on the selected range
    const getStartDateForRange = () => {
        const today = new Date();
        let startDate;
    
        switch (timeRange) {
            case '3M':
                startDate = new Date(today.setMonth(today.getMonth() - 3));
                break;
            case '6M':
                startDate = new Date(today.setMonth(today.getMonth() - 6));
                break;
            case 'YTD':
                startDate = new Date(today.getFullYear(), 0, 1); // Start of the current year
                break;
            case '1Y':
                startDate = new Date(today.setFullYear(today.getFullYear() - 1));
                break;
            case '2Y':
                startDate = new Date(today.setFullYear(today.getFullYear() - 2));
                break;
            case '5Y':
                startDate = new Date(today.setFullYear(today.getFullYear() - 5));
                break;
            case 'ALL':
                startDate = new Date(foundingDate); // Use founding date for 'ALL'
                break;
            default:
                startDate = new Date(foundingDate); // Default to founding date
        }
    
        // Ensure we do not show data earlier than the founding date
        const foundingDateObj = new Date(foundingDate);
        return startDate < foundingDateObj ? foundingDateObj : startDate;
    };    
    
    // Helper function for creating datasets with solid and dashed lines
    const createSegmentedDataset = (label, data, color, yAxisID) => {
      const lastSolidPointIndex = data.findIndex((point) => point.y !== 0);
      return {
          label,
          data,
          borderColor: `rgba(${color}, 1)`,
          borderWidth: 2,
          pointRadius: 0,
          pointHoverBorderWidth: 3,
          pointHoverRadius: 10, 
          hitRadius: 10,
          tension: 0.2,
          spanGaps: true,
          yAxisID,
          segment: {
              borderDash: (ctx) => {
                  if (timeRange === 'ALL' && ctx.p0DataIndex < lastSolidPointIndex) {
                      return [5, 5]; // Dashed before the first real data point in "ALL" range
                  }
                  return [];
              },
              borderColor: (ctx) => ctx.p0DataIndex < lastSolidPointIndex
                  ? 'rgba(128, 128, 128, 1)' // Dashed line color for missing data
                  : `rgba(${color}, 1)`, // Solid line for real data
              backgroundColor: (ctx) => ctx.p0DataIndex >= lastSolidPointIndex
                  ? createSolidLineGradient(ctx.chart.ctx, ctx.chart.chartArea, color)
                  : 'transparent'
          },
          fill: true,
      };
  };

    // Inline helper to create gradient background for filled areas
    const createSolidLineGradient = (ctx, chartArea, color) => {
        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
        gradient.addColorStop(0, `rgba(${color}, 0.3)`); // Start at 30% opacity
        gradient.addColorStop(1, `rgba(${color}, 0)`);   // Fade to 0% opacity
        return gradient;
    };

    useEffect(() => {
        if (reportData && reportData.timeSeriesData) {
          const startDate = getStartDateForRange();
          console.log("Start date for selected range:", startDate);
          console.log("Time Series Data:", reportData.timeSeriesData);
      
          const revenueHistory = reportData.timeSeriesData.revenueHistory || [];
          const burnHistory = reportData.timeSeriesData.burnHistory || [];
          const costHistory = reportData.timeSeriesData.costHistory || [];
          const valuationHistory = reportData.timeSeriesData.valuationHistory || [];
          const customerHistory = reportData.timeSeriesData.customerHistory || [];
          const employeeHistory = reportData.timeSeriesData.employeeHistory || [];
      
          console.log("Revenue History:", revenueHistory);
          console.log("Burn History:", burnHistory);
          console.log("Cost History:", costHistory);
          console.log("Valuation History:", valuationHistory);
          console.log("Customer History:", customerHistory);
          console.log("Employee History:", employeeHistory);
      
          const datasets = [];
      
          if (showRevenue) {
            const revenueValues = revenueHistory
              .filter(point => new Date(point.date) >= startDate)
              .map(point => ({ x: point.date, y: point.revenue }));
            console.log("Filtered Revenue Values:", revenueValues);
            datasets.push(createSegmentedDataset('Revenue', revenueValues, '75, 192, 192', 'y-axis-monetary'));
          }
      
          if (showBurn) {
            const burnValues = burnHistory
              .filter(point => new Date(point.date) >= startDate)
              .map((point) => ({ x: point.date, y: Math.abs(point.burn) }));
            console.log("Filtered Burn Values:", burnValues);
            datasets.push(createSegmentedDataset('Burn', burnValues, '255, 99, 132', 'y-axis-monetary'));
          }
      
          if (showCost) {
            const costValues = costHistory
              .filter(point => new Date(point.date) >= startDate)
              .map(point => ({ x: point.date, y: point.cost }));
            console.log("Filtered Cost Values:", costValues);
            datasets.push(createSegmentedDataset('Cost', costValues, '255, 206, 86', 'y-axis-monetary'));
          }
      
          if (showValuation) {
            const valuationValues = valuationHistory
              .filter(point => new Date(point.date) >= startDate)
              .map(point => ({ x: point.date, y: point.valuation }));
            console.log("Filtered Valuation Values:", valuationValues);
            datasets.push(createSegmentedDataset('Valuation', valuationValues, '153, 102, 255', 'y-axis-monetary'));
          }
      
          if (showCustomers) {
            const customerValues = customerHistory
              .filter(point => new Date(point.date) >= startDate)
              .map(point => ({ x: point.date, y: point.customers }));
            console.log("Filtered Customer Values:", customerValues);
            datasets.push(createSegmentedDataset('Customers', customerValues, '54, 162, 235', 'y-axis-numbers'));
          }
      
          if (showEmployees) {
            const employeeValues = employeeHistory
              .filter(point => new Date(point.date) >= startDate)
              .map(point => ({ x: point.date, y: point.employees }));
            console.log("Filtered Employee Values:", employeeValues);
            datasets.push(createSegmentedDataset('Employees', employeeValues, '255, 159, 64', 'y-axis-numbers'));
          }
      
          if (datasets.length === 0) {
            console.warn("No datasets available to display. Check the data and selected filters.");
          }
      
          setChartData({ datasets });
        } else {
          console.warn("reportData or timeSeriesData not found in GrowthChart");
        }
      }, [reportData, showRevenue, showBurn, showCost, showValuation, showCustomers, showEmployees, timeRange]);
      

    // Define time units based on selected range
    const timeUnit = {
        '3M': 'week',
        '6M': 'week',
        'YTD': 'month',
        '1Y': 'month',
        '2Y': 'month',
        '5Y': 'year',
        'ALL': 'year'
    }[timeRange] || 'month';

    const options = {
        responsive: true,
        plugins: {
          tooltip: { mode: 'index', intersect: false },
          legend: { display: false },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: timeUnit, // Dynamic unit based on time range
              displayFormats: {
                day: 'MMM dd',
                week: 'MMM dd',
                month: 'yyyy-MM',
                year: 'yyyy',
              },
              tooltipFormat: 'yyyy-MM-dd',
            },
            min: getStartDateForRange(),
            grid: { color: 'rgba(75, 85, 99, 0.3)' },
            ticks: { color: '#D1D5DB', autoSkip: true, maxTicksLimit: 12 },
          },
          'y-axis-monetary': {
            type: 'linear',
            position: 'left',
            title: { display: false, text: '$', color: '#D1D5DB' },
            ticks: {
              color: '#D1D5DB',
              callback: (value) =>
                value >= 1000000
                ? `$${Math.round(value / 1000000)}M`
                : `$${Math.round(value / 1000)}K`,
            },
            grid: { color: 'rgba(75, 85, 99, 0.3)' },
            min: timeRange === 'ALL'
              ? Math.min(
                  0,
                  ...(chartData?.datasets
                    ?.filter(ds => ds.yAxisID === 'y-axis-monetary')
                    .flatMap(ds => ds.data.map(point => point.y)) || [0])
                ) // Explicitly include negative values
              : undefined,
            suggestedMin:
              timeRange !== 'ALL'
                ? Math.min(
                    0,
                    ...(chartData?.datasets
                      ?.filter(ds => ds.yAxisID === 'y-axis-monetary')
                      .flatMap(ds => ds.data.map(point => point.y)) || [0])
                  ) // Dynamically set suggested minimum
                : undefined,
            suggestedMax:
              Math.max(
                ...(chartData?.datasets
                  ?.filter(ds => ds.yAxisID === 'y-axis-monetary')
                  .flatMap(ds => ds.data.map(point => point.y)) || [0])
              ) * 1.25, // Extend max value by 25% for better spacing
          },
          ...(showCustomers || showEmployees ? {
            'y-axis-numbers': {
              type: 'linear',
              position: 'right',
              title: { display: true, text: 'Employees / Customers', color: '#D1D5DB' },
              ticks: {
                color: '#D1D5DB',
                beginAtZero: true,
                callback: (value) => Math.round(value),
              },
              grid: { drawOnChartArea: false },
              min: 0,
              suggestedMax: Math.max(...(chartData?.datasets
                ?.filter(ds => ds.yAxisID === 'y-axis-numbers')
                .flatMap(ds => ds.data.map(point => point.y))) || [0]) * 1.25,
            }
          } : {}),
        },
      };      

    if (!chartData) {
        return <Text color="red.500">No data available.</Text>;
    }

    return (
      <Box mb={12} p={4} bg="rgba(15, 23, 42, 0.4)" borderRadius="md" mt={8}>
        {/* Settings Icon and Date Toggle */}
        <Flex direction="column" mb={4}>
          <Flex align="center" justify="space-between" mb={2}>
            {/* Settings Menu Toggle */}
            <IconButton
              icon={<SettingsIcon />}
              onClick={() => setShowSettings(!showSettings)}
              variant="ghost"
              size="lg"
              color="gray.400"
              _hover={{ color: 'white' }}
              aria-label="Toggle settings"
            />
  
            {/* Horizontally Scrollable Date Toggles */}
            <Flex overflowX="auto" whiteSpace="nowrap" alignItems="center" gap={2}>
              <ButtonGroup size="sm">
                {availableRanges.map((range) => (
                  <Button
                    key={range}
                    variant="unstyled"
                    color={timeRange === range ? 'white' : 'gray.400'}
                    fontWeight={timeRange === range ? 'bold' : 'normal'}
                    onClick={() => setTimeRange(range)}
                    _hover={{ color: 'white' }}
                    _focus={{ boxShadow: 'none' }}
                    bg={timeRange === range ? 'gray.600' : 'transparent'}
                    borderRadius="md"
                    px={3}
                  >
                    {range}
                  </Button>
                ))}
              </ButtonGroup>
            </Flex>
          </Flex>
  
          {/* Horizontal Divider */}
          {showSettings && <Divider orientation="horizontal" borderColor="gray.600" mb={4} />}
  
          {/* Collapsible Settings Menu */}
          <Collapse in={showSettings} animateOpacity>
            <Grid templateColumns={columns} gap={4}>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Revenue</Text>
                <Switch
                  isChecked={showRevenue}
                  onChange={() => setShowRevenue(!showRevenue)}
                  colorScheme="teal"
                />
              </Flex>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Cost</Text>
                <Switch
                  isChecked={showCost}
                  onChange={() => setShowCost(!showCost)}
                  colorScheme="yellow"
                />
              </Flex>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Burn</Text>
                <Switch
                  isChecked={showBurn}
                  onChange={() => setShowBurn(!showBurn)}
                  colorScheme="red"
                />
              </Flex>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Valuation</Text>
                <Switch
                  isChecked={showValuation}
                  onChange={() => setShowValuation(!showValuation)}
                  colorScheme="purple"
                />
              </Flex>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Customers</Text>
                <Switch
                  isChecked={showCustomers}
                  onChange={() => setShowCustomers(!showCustomers)}
                  colorScheme="blue"
                />
              </Flex>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Employees</Text>
                <Switch
                  isChecked={showEmployees}
                  onChange={() => setShowEmployees(!showEmployees)}
                  colorScheme="orange"
                />
              </Flex>
            </Grid>
          </Collapse>
        </Flex>
  
        {/* Chart Area */}
        <Line data={chartData} options={options} />
      </Box>
    );
  };

export default GrowthChart;
