import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, Tooltip, Divider } from '@chakra-ui/react';

const TeamGrowthRate = ({ auditData }) => {
  const [growthRate, setGrowthRate] = useState(0);
  const [currentTeamSize, setCurrentTeamSize] = useState(0);
  const [startingTeamSize, setStartingTeamSize] = useState(0);

  useEffect(() => {
    // Fetch auditData from localStorage if not provided
    const storedAuditData = JSON.parse(localStorage.getItem('auditData')) || auditData;

    if (storedAuditData) {
      const startingSize = storedAuditData.numberOfFounders || 0;
      const currentSize = storedAuditData.companySize || 0;

      if (startingSize > 0) {
        const calculatedGrowthRate = ((currentSize - startingSize) / startingSize) * 100;
        setGrowthRate(calculatedGrowthRate);
        setCurrentTeamSize(currentSize);
        setStartingTeamSize(startingSize);
      } else {
        setGrowthRate(0);
        setCurrentTeamSize(currentSize);
        setStartingTeamSize(startingSize);
      }
    } else {
      console.warn("Audit data is missing in both props and localStorage.");
    }
  }, [auditData]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Team Growth Rate</Text>
              <Text>
                Shows the growth rate of the team based on employee count, from starting size to current size.
              </Text>
            </>
          }
          aria-label="Team Growth Rate tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">
            Team Growth Rate
          </StatLabel>
        </Tooltip>

        <StatNumber fontSize="2xl" color="white">
          {growthRate.toFixed(1)}%
        </StatNumber>
      </Stat>

      <Divider mt={3} mb={2} borderColor="gray.600" />

      <Text fontSize="sm" color="gray.400" mt={2}>
        Current Team Size: <Text as="span" color="white">{currentTeamSize}</Text>
      </Text>
      <Text fontSize="sm" color="gray.400" mt={1}>
        Starting Team Size: <Text as="span" color="white">{startingTeamSize}</Text>
      </Text>
    </Box>
  );
};

export default TeamGrowthRate;
