import React, { useMemo } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import TeamStrengthScore from './topMetrics/TeamStrengthScore';
import FounderExperience from './topMetrics/FounderExperience';
import DiverseSkillsScore from './topMetrics/DiverseSkillsScore';
import AdvisorSupportNetwork from './topMetrics/AdvisorSupportNetwork';
import TeamGrowthRate from './topMetrics/TeamGrowthRate';

const TeamTopMetrics = () => {
  const auditData = useMemo(() => JSON.parse(localStorage.getItem('auditData')), []);
  const reportData = useMemo(() => JSON.parse(localStorage.getItem('reportData')), []);

  const {
    sectionScores: { teamStrength = 0 } = {},
    teamMetrics: { diverseSkillsScore = 0 } = {},
  } = reportData;

  const {
    founderExperience = 'Not specified',
    advisors = false,
    numberOfFounders = 0,
    companySize = 0,
  } = auditData;

  return (
    <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={6}>
      <TeamStrengthScore score={teamStrength} />
      <FounderExperience experience={founderExperience} />
      <DiverseSkillsScore score={diverseSkillsScore} />
      <AdvisorSupportNetwork hasAdvisors={advisors} />
      <TeamGrowthRate
        startingTeamSize={numberOfFounders}
        currentTeamSize={companySize}
      />
    </SimpleGrid>
  );
};

export default TeamTopMetrics;
