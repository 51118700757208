// src/components/RoleBasedRoute.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../constants';
import { useAuth } from '../context/AuthContext';

const RoleBasedRoute = ({ children, requiredRole }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();
  const { handleLogout } = useAuth(); // For logging out on error

  useEffect(() => {
    const verifyUserRole = async () => {
      const token = localStorage.getItem('token'); // Retrieve token directly from localStorage

      if (!token) {
        navigate('/login'); // Redirect to login if token is missing
        return;
      }

      try {
        // Verify the token by calling the backend for the user role
        const response = await axios.get(`${API_URL}/auth/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { role } = response.data;

        // Check if the user's role matches the required role
        if (role === requiredRole) {
          setIsAuthorized(true); // Allow access if roles match
        } else {
          navigate('/unauthorized'); // Redirect if roles do not match
        }
      } catch (error) {
        console.error('Error verifying user role:', error);
        handleLogout(); // Log out if there's an error
      } finally {
        setLoading(false); // Stop loading indicator
      }
    };

    verifyUserRole();
  }, [navigate, requiredRole, handleLogout]);

  if (loading) {
    return <div>Loading...</div>; // Optional: Loading indicator while verifying role
  }

  return isAuthorized ? children : null;
};

export default RoleBasedRoute;
