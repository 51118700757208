// src/reportv2/customers/topMetrics/CustomerLifetimeValue.js

import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, Tooltip, Divider, VStack } from '@chakra-ui/react';

const CustomerLifetimeValue = ({ auditData, reportData }) => {
  const [customerLTV, setCustomerLTV] = useState(0);
  const [customerCAC, setCustomerCAC] = useState(0);
  const [ltvCacRatio, setLtvCacRatio] = useState('N/A');

  useEffect(() => {
    const storedAuditData = JSON.parse(localStorage.getItem('auditData')) || auditData;
    const storedReportData = JSON.parse(localStorage.getItem('reportData')) || reportData;

    if (storedAuditData && storedReportData) {
      // Calculate LTV
      const revenueLastMonth = storedAuditData.revenueLastMonths?.lastMonthRevenue || 0;
      const totalCustomers = storedAuditData.totalCustomers || 1; // Avoid divide-by-zero with default
      const churnRate = 0.05; // Placeholder 5% monthly churn rate

      const arpu = revenueLastMonth / totalCustomers;
      const estimatedLifetime = 1 / churnRate;
      const calculatedLTV = arpu * estimatedLifetime;

      setCustomerLTV(calculatedLTV);

      // Calculate CAC based on the latest monthly cost and 20% allocation for customer acquisition
      const costHistory = storedReportData.timeSeriesData?.costHistory || [];
      const latestMonthlyCost = costHistory[costHistory.length - 1]?.cost || 0;
      const newCustomersAcquired = storedAuditData.customersWonPerMonth || 1; // Avoid divide-by-zero

      const calculatedCAC = newCustomersAcquired > 0
        ? (0.2 * latestMonthlyCost) / newCustomersAcquired
        : 0;
      setCustomerCAC(calculatedCAC);

      // Calculate LTV/CAC ratio
      const calculatedLtvCacRatio = calculatedCAC > 0 ? (calculatedLTV / calculatedCAC).toFixed(2) : 'N/A';
      setLtvCacRatio(`${calculatedLtvCacRatio} : 1`);
    }
  }, [auditData, reportData]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Customer Lifetime Value (LTV)</Text>
              <Text>
                LTV represents the estimated revenue from a customer over their lifetime, based on recent revenue and churn rate.
              </Text>
              <Text mt={2} fontWeight="bold">Note:</Text>
              <Text fontSize="sm">
                The LTV/CAC ratio is a measure of the return on investment for acquiring customers. A higher ratio indicates
                more value derived from each customer.
              </Text>
            </>
          }
          aria-label="Customer Lifetime Value tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Customer Lifetime Value</StatLabel>
        </Tooltip>

        <StatNumber color="white" fontSize="2xl" mt={0}>
          ${customerLTV.toLocaleString()}
        </StatNumber>

        <Divider my={2} borderColor="gray.600" />

        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.400">
            LTV/CAC Ratio:
          </Text>
          <Text fontSize="sm" color="white" mt={0}>
            {ltvCacRatio}
          </Text>
        </VStack>
      </Stat>
    </Box>
  );
};

export default CustomerLifetimeValue;
