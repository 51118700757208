// All countries alphabetically
export const allCountries = [
  "Afghanistan", "Algeria", "Andorra", "Angola", "Argentina", "Armenia", "Australia", "Austria", 
  "Azerbaijan", "Bahrain", "Bangladesh", "Belarus", "Belgium", "Bhutan", "Bolivia", "Bosnia & Herzegovina", 
  "Botswana", "Brazil", "Brunei", "Bulgaria", "Burma (Myanmar)", "Cambodia", "Cameroon", "Canada", "Chile", 
  "China", "Colombia", "Croatia", "Czech Republic", "Denmark", "Ecuador", "Egypt", "Estonia", 
  "Ethiopia", "Fiji", "Finland", "France", "Georgia", "Germany", "Ghana", "Greece", "Greenland", "Hungary", 
  "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Japan", 
  "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", 
  "Liechtenstein", "Lithuania", "Luxembourg", "Malaysia", "Maldives", "Malta", "Marshall Islands", 
  "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Namibia", 
  "Nauru", "Nepal", "Netherlands", "New Zealand", "Nigeria", "North Macedonia", "Norway", "Oman", 
  "Pakistan", "Palau", "Palestine", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", 
  "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "San Marino", "Saudi Arabia", "Senegal", "Serbia", 
  "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "Spain", 
  "Sri Lanka", "Sudan", "Sweden", "Switzerland", "Syria", "Tajikistan", "Tanzania", "Thailand", 
  "Timor-Leste", "Tonga", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", 
  "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", "Vietnam", "Yemen", 
  "Zambia", "Zimbabwe"
];

// Grouping all countries by region
export const countries = {
  NorthAmerica: [
    "Bermuda", "Canada", "Greenland", "Mexico", "United States"
  ],
  SouthAmerica: [
    "Argentina", "Bolivia", "Brazil", "Chile", "Colombia", "Ecuador", "Paraguay", "Peru", "Uruguay", "Venezuela"
  ],
  Europe: [
    "Andorra", "Austria", "Belarus", "Belgium", "Bosnia & Herzegovina", "Bulgaria", "Croatia", "Czech Republic", 
    "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Iceland", "Ireland", "Italy", 
    "Latvia", "Liechtenstein", "Lithuania", "Luxembourg", "Malta", "Moldova", "Monaco", "Montenegro", 
    "Netherlands", "North Macedonia", "Norway", "Poland", "Portugal", "Romania", "Russia", "San Marino", 
    "Serbia", "Slovakia", "Slovenia", "Spain", "Sweden", "Switzerland", "Ukraine", "United Kingdom"
  ],
  Africa: [
    "Algeria", "Angola", "Botswana", "Cameroon", "Egypt", "Ethiopia", "Ghana", "Ivory Coast", "Kenya", 
    "Morocco", "Mozambique", "Namibia", "Nigeria", "Rwanda", "Senegal", "Somalia", "South Africa", "Sudan", 
    "Tanzania", "Tunisia", "Uganda", "Zambia", "Zimbabwe"
  ],
  Asia: [
    "Afghanistan", "Armenia", "Azerbaijan", "Bangladesh", "Bhutan", "Brunei", "Cambodia", "China", "Georgia", 
    "India", "Indonesia", "Japan", "Kazakhstan", "Kyrgyzstan", "Laos", "Maldives", "Malaysia", "Myanmar", 
    "Nepal", "Pakistan", "Philippines", "Singapore", "South Korea", "Sri Lanka", "Tajikistan", "Thailand", 
    "Timor-Leste", "Turkmenistan", "Uzbekistan", "Vietnam"
  ],
  MiddleEast: [
    "Bahrain", "Iran", "Iraq", "Israel", "Jordan", "Kuwait", "Lebanon", "Oman", "Palestine", "Qatar", 
    "Saudi Arabia", "Syria", "United Arab Emirates", "Yemen"
  ],
  Oceania: [
    "Australia", "Fiji", "Kiribati", "Marshall Islands", "Micronesia", "Nauru", "New Zealand", "Palau", 
    "Papua New Guinea", "Samoa", "Solomon Islands", "Tonga", "Tuvalu", "Vanuatu"
  ]
};

// Regions
export const regions = [
  "Africa",
  "Asia",
  "Europe",
  "Middle East",
  "North America",
  "Oceania",
  "South America",
  "Global",
];

// Industry list (expanded based on recent changes)
export const industries = [
  "AdTech", "AgriTech", "AI & Machine Learning", "Automotive", "Biotech", "Blockchain", "CleanTech", "Cloud Computing", 
  "Consumer Goods", "Cybersecurity", "Data Analytics", "Digital Health", "Drones & Robotics", "E-commerce", "EdTech", 
  "Energy", "Enterprise Software", "Entertainment", "FashionTech", "FinTech", "Food & Beverage", "Gaming", "GreenTech", 
  "HealthTech", "HR Tech", "InsurTech", "IoT", "LegalTech", "Logistics & Supply Chain", "Manufacturing", "MarTech", 
  "Media", "MedTech", "Mobility", "PropTech", "Renewable Energy", "RetailTech", "SaaS", "Sharing Economy", 
  "Social Media", "SpaceTech", "SportsTech", "Telecommunications", "Travel & Hospitality", "VR/AR", "Wellness & Fitness"
];

// Customer types
export const customerTypes = [
  "B2B", "B2C", "B2G", "SMBs", "Enterprises", "Freelancers", "Non-Profits", "Startups", "Educational Institutions", 
  "Healthcare Providers"
];

// Investment stages
export const investmentStages = [
  "Pre-seed", "Seed", "Series A", "Series B", "Series C", "Growth", "Exit"
];

// Use of Funds
export const useOfFunds = [
  "Product Development", "Marketing", "Sales", "Operations", "Hiring", "Expansion"
];

// Stage options used elsewhere in your project
export const stageOptions = [
  "New", "Viewed", "Screening", "In Progress", "Committed", "Rejected"
];

// Market opportunity ranges
export const marketOpportunities = [
  "$0 - $100M", "$100M - $500M", "$500M - $1B", "$1B+", "I don't know"
];

// Competitor landscape options 
export const competitorLandscapes = [
  "0 Competitors", "1-3 Competitors", "4-6 Competitors", "7+ Competitors"
];

// Product roadmap stages
export const productRoadmaps = [
  "MVP", "Beta", "Launched", "Scaling"
];

// GTM (Go-to-Market) strategies
export const gtmStrategies = [
  "Direct Sales", "Partnerships", "Online Marketing", "Events", "Influencer Marketing"
];

// Founder experience options
export const founderExperiences = [
  "0-2 years", "3-5 years", "5+ years"
];

// Month dropdown options
export const months = [
  "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
];
