// src/reportv2/product/topMetrics/DevelopmentStage.js

import React from 'react';
import { Box, Stat, StatLabel, StatNumber, Tooltip, Text } from '@chakra-ui/react';

const DevelopmentStage = ({ auditData }) => {
  const developmentStage = auditData?.productRoadmap || "N/A";

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Development Stage</Text>
              <Text>Shows the current phase of the product’s development, indicating its market readiness.</Text>
            </>
          }
          aria-label="Development Stage tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Development Stage</StatLabel>
        </Tooltip>

        <StatNumber color="white" fontSize="2xl" mt={0}>
          {developmentStage}
        </StatNumber>
      </Stat>
    </Box>
  );
};

export default DevelopmentStage;
