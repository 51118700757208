import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Button,
  Text,
  Input,
  IconButton,
  Tooltip,
  Center,
  Badge,
} from '@chakra-ui/react';
import { ArrowUpIcon, AttachmentIcon, CheckCircleIcon, InfoOutlineIcon } from '@chakra-ui/icons';

const taskList = [
  { id: 1, title: 'Submit Audit', icon: CheckCircleIcon, completed: true },
  { id: 2, title: 'Create founder profiles', icon: InfoOutlineIcon, completed: false },
  { id: 3, title: 'Share cost breakdown', icon: InfoOutlineIcon, completed: false },
];

const TaskDashboard = () => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isChatStarted, setIsChatStarted] = useState(false);

  const handleSendMessage = () => {
    if (message.trim()) {
      setMessages([...messages, { user: true, text: message }]);
      setMessage('');
      setIsChatStarted(true);
    }
  };

  useEffect(() => {
    if (selectedTask) {
      setMessages([{ user: false, text: `Task: ${selectedTask.title}` }]);
      setIsChatStarted(true);
    }
  }, [selectedTask]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleSendMessage();
  };

  return (
    <VStack spacing={4} align="stretch" p={6} bg="#1e293b" minHeight="100vh">
      {/* Task Selection View */}
      {!isChatStarted && (
        <Center flex="1" textAlign="center">
          <VStack spacing={6}>
            {/* "Coming Soon" Badge */}
            <Badge
              colorScheme="green"
              fontSize="md"
              mb={2}
              px={2}
              py={1}
              borderRadius="md"
              variant="outline" // Adds an outlined style to the badge
            >
              Coming Soon
            </Badge>

            <Text fontSize="3xl" color="white" mb={4} fontWeight="bold">
              Let's get to know your startup!
            </Text>
            <Box display="flex" flexWrap="wrap" justifyContent="center" gap={3}>
              {taskList.map((task) => (
                <Button
                  key={task.id}
                  leftIcon={<task.icon color="white" />}
                  onClick={() => setSelectedTask(task)}
                  variant="solid"
                  colorScheme="gray"
                  bg={task.completed ? 'gray.600' : '#2d3748'}
                  color="gray.100"
                  borderRadius="full"
                  px={6}
                  py={4}
                  fontWeight="medium"
                  _hover={{ bg: task.completed ? 'gray.500' : 'gray.600' }}
                  textDecoration={task.completed ? 'line-through' : 'none'}
                >
                  {task.title}
                </Button>
              ))}
            </Box>

            {/* Centered Input Field */}
            <HStack
              spacing={3}
              p={3}
              bg="rgba(28, 35, 54)"
              borderRadius="full"
              width="100%"
              maxWidth="90%"
              position="relative"
              bottom={0}
              zIndex="10"
            >
              <Tooltip label="Attach file" fontSize="md">
                <IconButton
                  icon={<AttachmentIcon />}
                  variant="ghost"
                  color="white"
                  _hover={{ color: 'white' }}
                  _focus={{ boxShadow: 'none' }}
                  fontSize="large"
                  mr={-5} // Bring the icon closer to the input
                />
              </Tooltip>
              <Input
                placeholder="Click a task above or ask any question"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                bg="rgba(28, 35, 54)"
                color="white"
                _placeholder={{ color: 'gray.500' }}
                borderRadius="full"
                border="none"
                focusBorderColor="transparent"
              />
              <IconButton
                icon={<ArrowUpIcon />}
                colorScheme="green"
                onClick={handleSendMessage}
                borderRadius="full"
              />
            </HStack>
          </VStack>
        </Center>
      )}

      {/* Conversation View */}
      {isChatStarted && (
        <Center width="100%" maxWidth="72%" mx="auto">
          <VStack
            spacing={4}
            align="stretch"
            mt={6}
            bg="transparent"
            p={4}
            borderRadius="lg"
            width="100%"
            height="60vh"
            overflowY="auto"
          >
            {/* Display Task as First Message */}
            {selectedTask && (
              <Box
                alignSelf="flex-start"
                bg="rgba(28, 35, 54, 0.5)"
                color="white"
                p={3}
                borderRadius="20px"
                maxWidth="70%"
                mb={2}
              >
                <Text fontWeight="bold">{selectedTask.title}</Text>
              </Box>
            )}

            {/* Message History */}
            {messages.map((msg, index) => (
              <Box
                key={index}
                alignSelf={msg.user ? 'flex-end' : 'flex-start'}
                bg="rgba(28, 35, 54, 0.5)"
                color="white"
                p={3}
                borderRadius="20px"
                maxWidth="100%"
                mb={2}
              >
                <Text>{msg.text}</Text>
              </Box>
            ))}
          </VStack>

          {/* Sticky Chat Input Field at Bottom with Same Style */}
          <HStack
            spacing={3}
            p={3}
            bg="rgba(28, 35, 54)"
            borderRadius="full"
            width="100%"
            maxWidth="60%"
            position="fixed"
            bottom={8}
            left="50%"
            transform="translateX(-50%)"
            zIndex="10"
          >
            <Tooltip label="Attach file" fontSize="md">
              <IconButton
                icon={<AttachmentIcon />}
                variant="ghost"
                color="white"
                _hover={{ color: 'white' }}
                _focus={{ boxShadow: 'none' }}
                fontSize="large"
                mr={-5} // Bring the icon closer to the input
              />
            </Tooltip>
            <Input
              placeholder="Fire away..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              bg="rgba(28, 35, 54)"
              color="white"
              _placeholder={{ color: 'gray.500' }}
              borderRadius="full"
              border="none"
              focusBorderColor="transparent"
            />
            <IconButton
              icon={<ArrowUpIcon />}
              colorScheme="green"
              onClick={handleSendMessage}
              borderRadius="full"
            />
          </HStack>
        </Center>
      )}
    </VStack>
  );
};

export default TaskDashboard;
