// src/reportv2/market/topMetrics/PayingCustomersRatio.js

import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Progress, Divider, Flex, Tooltip } from '@chakra-ui/react';
import { TriangleUpIcon, TriangleDownIcon, CheckCircleIcon } from '@chakra-ui/icons';

const getColorScheme = (ratio) => {
  if (ratio < 40) return 'red';
  if (ratio >= 40 && ratio <= 60) return 'yellow';
  return 'green';
};

const PayingCustomersRatio = ({ reportData }) => {
  const [payingCustomersRatio, setPayingCustomersRatio] = useState(0);
  const [isIncreasing, setIsIncreasing] = useState(false);

  useEffect(() => {
    const storedReportData = JSON.parse(localStorage.getItem('reportData'));
    const storedAuditData = JSON.parse(localStorage.getItem('auditData'));

    const finalReportData = reportData || storedReportData;
    const finalAuditData = storedAuditData;

    if (finalReportData && finalAuditData) {
      const totalCustomers = finalAuditData?.totalCustomers || 0;
      const lastMonthCustomers = finalAuditData.payingCustomersLastMonths?.lastMonthCustomers || 0;
      const threeMonthsAgoCustomers = finalAuditData.payingCustomersLastMonths?.threeMonthsAgoCustomers || 0;

      const currentRatio = totalCustomers > 0 ? (lastMonthCustomers / totalCustomers) * 100 : 0;
      setPayingCustomersRatio(currentRatio);

      const previousRatio = totalCustomers > 0 ? (threeMonthsAgoCustomers / totalCustomers) * 100 : 0;
      setIsIncreasing(currentRatio > previousRatio);
    }
  }, [reportData]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip 
          label="The Paying Customers Ratio shows the percentage of paying customers. A higher ratio is healthier." 
          aria-label="Paying Customers Ratio tooltip"
        >
          <StatLabel color="gray.300" textAlign="left" cursor="help">
            Paying Customers Ratio
          </StatLabel>
        </Tooltip>

        <Progress
          value={payingCustomersRatio}
          colorScheme={getColorScheme(payingCustomersRatio)}
          bg="#1e293b"
          size="xxl"
          height="29px"
          borderRadius="sm"
          mt={2}
        />

        <Divider mt={3} mb={2} borderColor="gray.600" />

        <Tooltip 
          label={`The Paying Customers Ratio is ${isIncreasing ? 'increasing' : 'stable at 100%'}.`} 
          aria-label="Paying Customers Ratio percentage tooltip"
        >
          <Flex justifyContent="center" alignItems="center" cursor="help">
            {payingCustomersRatio === 100 ? (
              <CheckCircleIcon color="green.500" boxSize={5} mr={1} />
            ) : (
              isIncreasing ? (
                <TriangleUpIcon color="green.500" boxSize={5} mr={1} />
              ) : (
                <TriangleDownIcon color="red.500" boxSize={5} mr={1} />
              )
            )}
            <StatNumber fontSize="2xl" color="white">
              {payingCustomersRatio.toFixed(1)}%
            </StatNumber>
          </Flex>
        </Tooltip>
      </Stat>
    </Box>
  );
};

export default PayingCustomersRatio;
