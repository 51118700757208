import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, StatHelpText, StatArrow, Tooltip, Divider } from '@chakra-ui/react';

const BurnRateManagement = ({ reportData }) => {
  const [burnHistory, setBurnHistory] = useState([]);
  const [currentBurnRate, setCurrentBurnRate] = useState(0);

  // Fetch data from local storage or props (reportData)
  useEffect(() => {
    const storedReportData = JSON.parse(localStorage.getItem('reportData'));

    if (storedReportData && storedReportData.timeSeriesData) {
      const { burnHistory } = storedReportData.timeSeriesData;
      setBurnHistory(burnHistory || []);
      setCurrentBurnRate(burnHistory[burnHistory.length - 1]?.burn || 0);
    } else if (reportData) {
      const { burnHistory } = reportData.timeSeriesData;
      setBurnHistory(burnHistory || []);
      setCurrentBurnRate(burnHistory[burnHistory.length - 1]?.burn || 0);
    }
  }, [reportData]);

  // Ensure burnHistory has at least one previous entry for the last known burn rate
  const lastKnownBurnRate = burnHistory.length > 1
    ? burnHistory[burnHistory.length - 2]?.burn
    : 0;

  // Calculate recent burn rate change percentage
  const recentBurnRateChange = lastKnownBurnRate
    ? ((currentBurnRate - lastKnownBurnRate) / lastKnownBurnRate) * 100
    : 0;

  // Format burn rates for display
  const formattedCurrentBurnRate = (currentBurnRate / 1000).toFixed(1) + 'K';
  const formattedLastKnownBurnRate = (lastKnownBurnRate / 1000).toFixed(1) + 'K';

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <StatLabel color="gray.300">Burn Rate Management</StatLabel>

        {/* Tooltip explaining current burn rate */}
        <Tooltip label="Burn rate represents the monthly cash outflow or expense rate." aria-label="Burn rate tooltip">
          <StatNumber color="white" cursor="help">
            ${formattedCurrentBurnRate}
          </StatNumber>
        </Tooltip>

        {/* Subtle horizontal divider */}
        <Divider borderColor="gray.600" my={2} />

        {/* Last known burn rate information */}
        <StatHelpText color="gray.400" cursor="help" display="inline">
          Last Known Burn Rate:
          <Text as="span" color="white"> ${formattedLastKnownBurnRate}</Text>
        </StatHelpText>

        {/* Display recent change next to last known burn rate */}
        <StatHelpText display="inline" color="white">
          &nbsp;<StatArrow type={recentBurnRateChange >= 0 ? 'increase' : 'decrease'} />
          {Math.abs(recentBurnRateChange).toFixed(1)}%
        </StatHelpText>
      </Stat>
    </Box>
  );
};

export default BurnRateManagement;
