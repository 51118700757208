import * as Yup from 'yup';

// Define the v1ValidationSchema if needed for fallback purposes
const v1ValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company name is required'),
  industry: Yup.string().required('Industry is required'),
  location: Yup.string().required('Location is required'),
});

// Common fields for both versions
const commonValidationSchema = {
  companyName: Yup.string().required('Company name is required'),
  industry: Yup.string().required('Industry is required'),
  location: Yup.string().required('Location is required'),
  numberOfFounders: Yup.number()
    .min(1, 'At least 1 founder is required')
    .required('Number of founders is required'),
  investmentStage: Yup.string().required('Investment stage is required'),
  fundingGoal: Yup.number().required('Funding goal is required'),
  currentFunding: Yup.number(),
  payingCustomers: Yup.string().required('Number of paying customers is required'),
  competitorLandscape: Yup.string().required('Competitor landscape is required'),
  competitorUrls: Yup.array().of(Yup.string().url('Invalid URL format')),
  targetMarket: Yup.array().min(1, 'At least one target market is required').required('Target market is required'),
  customerType: Yup.string().required('Customer type is required'),
  productRoadmap: Yup.string().required('Product roadmap is required'),
  productDifferentiation: Yup.string().required('Product differentiation is required'),
  businessRisks: Yup.array().min(1, 'At least one business risk is required').required('Business risks are required'),
  fundAllocation: Yup.array().min(1, 'At least one fund allocation option is required').required('Fund allocation is required'),
  exitStrategy: Yup.string().oneOf(['Acquisition', 'IPO', 'Stay Private'], 'Invalid exit strategy').required('Exit strategy is required'),
};

// Version 2 specific validation rules
const v2ValidationSchema = Yup.object().shape({
  ...commonValidationSchema,

  // Founding Date validation
foundingDate: Yup.string()
  .matches(/^\d{4}-(0[1-9]|1[0-2])$/, 'Invalid founding date. Must be in the format YYYY-MM')
  .required('Founding date is required'),


  // Revenue validation
  revenueLastMonths: Yup.object().shape({
    lastMonthRevenue: Yup.number().nullable().required('Last month revenue is required'),
    threeMonthsAgoRevenue: Yup.number().nullable().required('Three months ago revenue is required'),
  }),

  // Burn Rate validation
  monthlyBurn: Yup.object().shape({
    lastMonthBurn: Yup.number().required('Last month burn rate is required'),
    threeMonthsAgoBurn: Yup.number().required('Three months ago burn rate is required'),
  }),

  // Market Size as a dropdown (string-based validation)
  marketSize: Yup.string().required('Market size is required'),

  // Patents or Proprietary Technologies
  patents: Yup.string().oneOf(['Yes', 'No'], 'Invalid response').required('Patent information is required'),

  // Product Description and Benefits
  productDescription: Yup.string().required('Product description is required'),
  productBenefits: Yup.string().required('Product benefits are required'),

  // Go-to-Market Strategy
  gtmStrategy: Yup.string().required('Go-to-market strategy is required'),

  // Customer Acquisition Channels
  customerAcquisitionChannels: Yup.array().min(1, 'At least one acquisition channel is required').required('Customer acquisition channels are required'),
  effectiveChannels: Yup.array().min(1, 'At least one effective channel is required').required('Effective channels are required'),

  // Pricing Strategy
  pricingStrategy: Yup.string().required('Pricing strategy is required'),

  // Customer Acquisition and Loss Metrics
  customerAcquisitionTime: Yup.number().required('Customer acquisition time is required'),
  customersWonPerMonth: Yup.number().required('Number of customers won per month is required'),
  customerLossPerMonth: Yup.number().required('Number of customers lost per month is required'),

  // Founder Experience
  founderExperience: Yup.string().required('Founder experience is required'),
  teamSkills: Yup.string().required('Team skills information is required'),

  // Milestones
  milestones: Yup.array().nullable(),

  // Advisors or Mentors
  advisors: Yup.string().oneOf(['Yes', 'No'], 'Invalid response').required('Advisor information is required'),

  // Business Risks and Mitigation
  businessRisks: Yup.array().min(1, 'At least one business risk is required').required('Business risks are required'),
  riskMitigation: Yup.string().nullable(),

  // Audit Feedback and Improvement Priorities
  auditExpectations: Yup.array().min(1, 'At least one expectation is required').required('Audit expectations are required'),
  improvementPriorities: Yup.string().nullable(),
});

// Function to select the appropriate validation schema based on version
export const getAuditValidationSchema = (version = 'v1') => {
  if (version === 'v2') {
    return v2ValidationSchema;
  }
  return v1ValidationSchema;
};
