import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, CircularProgress, CircularProgressLabel, Tooltip, Text } from '@chakra-ui/react';

const InvestabilityMetric = ({ score }) => {
  const [displayScore, setDisplayScore] = useState(0);

  useEffect(() => {
    // Simulate animation by gradually updating the displayed score
    let start = 0;
    const interval = setInterval(() => {
      start += 1;
      if (start >= Math.round(score)) {
        clearInterval(interval);
      }
      setDisplayScore(start);
    }, 20); // Increase 1 every 20ms for a smooth animation
  }, [score]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md" textAlign="center">
      <Stat>
        {/* Tooltip explaining the overall investability score */}
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Overall Investability</Text>
              <Text>This score reflects a combined assessment of financial health, product readiness, team strength, and market opportunity.</Text>
            </>
          }
          aria-label="Overall investability score tooltip"
          hasArrow
          cursor="help"
        >
          <Box>
            <StatLabel color="gray.300" mb={4}>Overall Investability</StatLabel>

            {/* Circular progress bar with animation */}
            <CircularProgress
              value={displayScore} // Display animated score
              color={displayScore >= 70 ? 'green.400' : displayScore >= 50 ? 'yellow.400' : 'red.400'}
              trackColor="#1e293b"  // Background color for the circular progress
              size="80px"  // Reduced size for better performance
              thickness="10px" // Thinner progress bar
            >
              <CircularProgressLabel color="white" fontSize="3xl" fontWeight="medium">
                {Math.round(displayScore)}{/* Round to nearest whole number */}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
        </Tooltip>
      </Stat>
    </Box>
  );
};

export default InvestabilityMetric;
