import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, Tooltip, Divider } from '@chakra-ui/react';

const InvestmentStageMetric = ({ reportData, auditData }) => {
  const [stageScore, setStageScore] = useState(0);
  const [investmentStage, setInvestmentStage] = useState('');

  // Define expectations for different investment stages
  const stageExpectations = {
    PreSeed: { revenueMin: 0, growthRateMin: 5, fundingMin: 100000, fundingMax: 500000 },
    Seed: { revenueMin: 10000, growthRateMin: 10, fundingMin: 500000, fundingMax: 2000000 },
    SeriesA: { revenueMin: 50000, growthRateMin: 20, fundingMin: 2000000, fundingMax: 10000000 },
    SeriesB: { revenueMin: 100000, growthRateMin: 30, fundingMin: 10000000, fundingMax: 20000000 },
    SeriesC: { revenueMin: 500000, growthRateMin: 40, fundingMin: 20000000, fundingMax: 50000000 },
  };

  useEffect(() => {
    // Fetch data from local storage if not provided
    const storedAuditData = JSON.parse(localStorage.getItem('auditData'));
    const storedReportData = JSON.parse(localStorage.getItem('reportData'));

    const finalAuditData = auditData || storedAuditData;
    const finalReportData = reportData || storedReportData;

    if (finalReportData && finalAuditData) {
      // Get claimed investment stage and financial data
      const claimedStage = finalAuditData?.investmentStage || 'Unknown';
      setInvestmentStage(claimedStage);

      const { lastMonthRevenue, threeMonthsAgoRevenue } = finalAuditData?.revenueLastMonths || { lastMonthRevenue: 0, threeMonthsAgoRevenue: 0 };
      const monthlyRevenue = lastMonthRevenue || 0;
      const revenueGrowthRate = threeMonthsAgoRevenue > 0 ? ((lastMonthRevenue - threeMonthsAgoRevenue) / threeMonthsAgoRevenue) * 100 : 0;
      const fundingGoal = finalAuditData?.fundingGoal || 0;

      // Get the expectations for the claimed stage
      const stageCriteria = stageExpectations[claimedStage] || stageExpectations.Seed;

      // Start with a score of 100
      let score = 100;

      // Deduct points if the monthly revenue is below the expected minimum
      if (monthlyRevenue < stageCriteria.revenueMin) {
        score -= 30; // Major penalty for not meeting revenue expectations
      }

      // Deduct points if the growth rate is below the minimum for the stage
      if (revenueGrowthRate < stageCriteria.growthRateMin) {
        score -= 20; // Penalty for insufficient growth rate
      }

      // Deduct points if the funding goal is too high or too low for the stage
      if (fundingGoal < stageCriteria.fundingMin || fundingGoal > stageCriteria.fundingMax) {
        score -= 20; // Penalty for unrealistic funding goal
      }

      // Ensure the score remains between 0 and 100
      setStageScore(Math.max(0, Math.min(100, score)));
    }
  }, [reportData, auditData]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      {/* Single tooltip over both the Investment Stage Realism label and score */}
      <Tooltip
        label={
          <>
            <Text fontWeight="bold">Investment Stage Realism</Text>
            <Text>The score is calculated based on the:</Text>
            <Text>- Monthly revenue vs. expected minimum for the stage</Text>
            <Text>- Revenue growth rate vs. expected rate for the stage</Text>
            <Text>- Funding goal alignment with typical amounts for the stage</Text>
          </>
        }
        aria-label="Stage realism info"
        placement="top"
        hasArrow
        bg="gray.700"
        color="white"
        p={3}
        borderRadius="md"
        fontSize="xs"
        textAlign="left"
      >
        <Stat>
          <StatLabel color="gray.300" cursor="help">
            Investment Stage Realism
          </StatLabel>

          {/* Show the calculated score */}
          <StatNumber color="white" cursor="help">
            {stageScore}%
          </StatNumber>
        </Stat>
      </Tooltip>

      {/* Subtle horizontal line below the score */}
      <Divider mt={3} mb={2} borderColor="gray.600" />

      {/* Display the claimed investment stage */}
      <Text fontSize="sm" color="gray.400" mt={2}>
        Stage: <Text as="span" color="white">{investmentStage}</Text>
      </Text>
    </Box>
  );
};

export default InvestmentStageMetric;
