import React, { useEffect, useState } from 'react';
import { Grid, Box, Text, Tooltip, Divider } from '@chakra-ui/react';

const Recommendations = ({ reportData, section }) => {
  const [sectionData, setSectionData] = useState(null);

  useEffect(() => {
    // Retrieve from local storage if reportData is not passed as a prop 
    if (!reportData) {
      const storedData = JSON.parse(localStorage.getItem('reportData'));
      if (storedData) {
        setSectionData(storedData.chatGPTResponses?.[section]);
      }
    } else {
      setSectionData(reportData.chatGPTResponses?.[section]);
    }
  }, [reportData, section]);

  // Define colors for top recommendations
  const getColors = (index) => {
    switch (index) {
      case 0: return { borderColor: "rgba(255, 215, 0, 0.7)", textColor: "rgba(255, 215, 0, 0.9)" }; // Gold
      case 1: return { borderColor: "rgba(192, 192, 192, 0.6)", textColor: "rgba(192, 192, 192, 0.9)" }; // Silver
      case 2: return { borderColor: "rgba(205, 127, 50, 0.4)", textColor: "rgba(205, 127, 50, 0.9)" };  // Bronze
      default: return { borderColor: "rgba(255, 255, 255, 0.3)", textColor: "rgba(255, 255, 255, 0.9)" }; // Neutral
    }
  };

  return (
    <Box bg="rgba(28, 35, 54, 1)" p={6} borderRadius="lg" mt={6} mb={6}>
      <Box display="flex" justifyContent="center" alignItems="center" mb={6}>
        <Tooltip 
          label="Recommendations to strengthen the current section based on identified strengths and weaknesses." 
          aria-label="Recommendations tooltip"
        >
          <Text color="gray.300" fontSize="lg" fontWeight="bold" cursor="help">
            Recommendations
          </Text>
        </Tooltip>
      </Box>
      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6}>
        {sectionData?.recommendations?.map((rec, index) => {
          const { borderColor, textColor } = getColors(index);
          return (
            <Box 
              key={index} 
              p={6} 
              bg="rgba(28, 35, 54, 0.8)" 
              borderRadius="md" 
              border="2px solid" 
              borderColor={borderColor}
              boxShadow="md"
              textAlign="left"
            >
              <Text fontSize="6xl" color={textColor} fontWeight="bold" mb={4}>{index + 1}</Text>
              <Divider borderColor="gray.500" opacity={0.6} my={2} />
              <Text fontSize="md" color="white" lineHeight="1.6">{rec}</Text>
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Recommendations;
