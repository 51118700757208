// src/reportv2/market/topMetrics/CustomerGrowthRate.js

import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, StatArrow, Tooltip, Text, Flex } from '@chakra-ui/react';

const CustomerGrowthRate = ({ reportData }) => {
  const [growthRate, setGrowthRate] = useState(0);

  useEffect(() => {
    // Attempt to fetch data from localStorage or fallback to provided reportData prop
    const storedReportData = JSON.parse(localStorage.getItem('reportData'));
    const finalReportData = reportData || storedReportData;

    if (finalReportData && finalReportData.timeSeriesData && finalReportData.timeSeriesData.customerHistory) {
      const { customerHistory } = finalReportData.timeSeriesData;

      // Ensure at least two entries to calculate the growth rate
      if (customerHistory.length > 1) {
        const latestCustomers = customerHistory[customerHistory.length - 1]?.customers || 0;
        const previousCustomers = customerHistory[customerHistory.length - 2]?.customers || 0;

        // Calculate growth rate if previous customer data is available
        if (previousCustomers > 0) {
          const calculatedGrowthRate = ((latestCustomers - previousCustomers) / previousCustomers) * 100;
          setGrowthRate(calculatedGrowthRate);
        }
      }
    }
  }, [reportData]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md" textAlign="center">
      <Flex direction="column" alignItems="center" justifyContent="center" height="100%">
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Customer Growth Rate</Text>
              <Text>Measures the percentage growth in the customer base over a specific period, indicating market reach expansion.</Text>
            </>
          }
          aria-label="Customer growth rate tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <Stat>
            <StatLabel color="gray.300" textAlign="center" cursor="help">Customer Growth Rate</StatLabel>
            <StatNumber fontSize="2xl" color="white" mt={8} textAlign="center">
              {growthRate >= 0 ? <StatArrow type="increase" /> : <StatArrow type="decrease" />}
              {Math.abs(growthRate).toFixed(1)}%
            </StatNumber>
          </Stat>
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default CustomerGrowthRate;
