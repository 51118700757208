// src/reportv2/customers/topMetrics/CustomerAcquisitionCost.js

import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, Tooltip, Divider, VStack } from '@chakra-ui/react';

const CustomerAcquisitionCost = ({ auditData, reportData }) => {
  const [customerCAC, setCustomerCAC] = useState(0);
  const [acquisitionTime, setAcquisitionTime] = useState(0);

  useEffect(() => {
    const storedAuditData = JSON.parse(localStorage.getItem('auditData')) || auditData;
    const storedReportData = JSON.parse(localStorage.getItem('reportData')) || reportData;

    // Fetch acquisition time from auditData
    if (storedAuditData) {
      setAcquisitionTime(storedAuditData.customerAcquisitionTime || 0);
    }

    // Calculate CAC using the most recent entry in costHistory
    if (storedAuditData && storedReportData) {
      const costHistory = storedReportData.timeSeriesData?.costHistory || [];
      const latestMonthlyCost = costHistory[costHistory.length - 1]?.cost || 0;
      const newCustomersAcquired = storedAuditData.customersWonPerMonth || 0;

      // Estimated CAC: assume 20% of latest monthly costs go toward customer acquisition
      const estimatedCAC = newCustomersAcquired > 0
        ? (0.2 * latestMonthlyCost) / newCustomersAcquired
        : 0;

      setCustomerCAC(estimatedCAC);
    }
  }, [auditData, reportData]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Customer Acquisition Cost (CAC)</Text>
              <Text>
                CAC estimates the average cost to acquire a single customer. This is based on an industry standard until
                more specific data is available.
              </Text>
              <Text mt={2} fontWeight="bold">Note:</Text>
              <Text fontSize="sm">
                In early-stage startups, approximately 20% of monthly costs are often allocated toward customer acquisition.
                This estimation is calculated using the most recent monthly cost data available.
              </Text>
            </>
          }
          aria-label="Customer Acquisition Cost tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Customer Acquisition Cost</StatLabel>
        </Tooltip>

        <StatNumber color="white" fontSize="2xl" mt={0}>
          ${customerCAC.toLocaleString()}
        </StatNumber>

        <Divider my={2} borderColor="gray.600" />

        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.400">
            Acquisition Time:
          </Text>
          <Text fontSize="sm" color="white" mt={0}>
            {acquisitionTime} days
          </Text>
        </VStack>
      </Stat>
    </Box>
  );
};

export default CustomerAcquisitionCost;
