import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, Tooltip, Divider, Flex } from '@chakra-ui/react';

const CompetitorsCount = () => {
  const [competitorLevel, setCompetitorLevel] = useState("Unknown");
  const [competitorText, setCompetitorText] = useState("No data available");

  useEffect(() => {
    const storedAuditData = JSON.parse(localStorage.getItem('auditData'));
    const landscape = storedAuditData?.competitorLandscape || "Unknown";

    const competitorMapping = {
      "0 Competitors": { level: "Unbelievable", text: "0 competitors" },
      "1-3 Competitors": { level: "Low", text: "1-3 competitors" },
      "4-6 Competitors": { level: "Moderate", text: "4-6 competitors" },
      "7+ Competitors": { level: "High", text: "7+ competitors" },
    };

    const { level, text } = competitorMapping[landscape] || { level: "Unknown", text: "No data available" };
    setCompetitorLevel(level);
    setCompetitorText(text);

    console.log("Fetched competitor landscape:", landscape);
  }, []); // Fetch only once on mount

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md" textAlign="left">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Competitor Landscape</Text>
              <Text>Indicates the level of competition in the market based on the number of known competitors.</Text>
            </>
          }
          aria-label="Competitor count tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Competition</StatLabel>
        </Tooltip>

        <Flex direction="column" alignItems="left" mt={0}>
          <StatNumber color={
            competitorLevel === "Unbelievable" ? "blue.400" :
            competitorLevel === "Low" ? "green.400" :
            competitorLevel === "Moderate" ? "yellow.400" :
            "red.400"
          }>
            {competitorLevel}
          </StatNumber>

          <Divider my={2} borderColor="gray.600" />

          <Text fontSize="sm" color="gray.400">
            Last reported:
          </Text>
          <Text fontSize="sm" color="white" mt={0}>
            {competitorText}
          </Text>
        </Flex>
      </Stat>
    </Box>
  );
};

export default CompetitorsCount;
