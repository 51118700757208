// src/reportv2/team/topMetrics/FounderExperience.js

import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, Tooltip, Divider, VStack } from '@chakra-ui/react';

const FounderExperience = ({ auditData }) => {
  const [founderExperience, setFounderExperience] = useState('Not Provided');
  const [numberOfFounders, setNumberOfFounders] = useState('N/A');

  useEffect(() => {
    const storedAuditData = JSON.parse(localStorage.getItem('auditData')) || auditData;

    if (storedAuditData) {
      setFounderExperience(storedAuditData.founderExperience || 'Not Provided');
      setNumberOfFounders(storedAuditData.numberOfFounders || 'N/A');
    }
  }, [auditData]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Founder Experience</Text>
              <Text>
                Represents the experience level of the founding team, measured by their years of expertise in relevant fields.
              </Text>
              <Text mt={2} fontWeight="bold">Note:</Text>
              <Text fontSize="sm">
                A highly experienced founding team can greatly impact the success and credibility of the startup.
              </Text>
            </>
          }
          aria-label="Founder Experience tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Founder Experience</StatLabel>
        </Tooltip>

        <StatNumber color="white" fontSize="2xl" mt={0}>
          {founderExperience}
        </StatNumber>

        <Divider my={2} borderColor="gray.600" />

        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.400">
            Number of Founders:
          </Text>
          <Text fontSize="sm" color="white" mt={0}>
            {numberOfFounders}
          </Text>
        </VStack>
      </Stat>
    </Box>
  );
};

export default FounderExperience;
