// constants/constants.js

export const API_URL = process.env.REACT_APP_API_URL;

export const INDUSTRY_GROUPS = {
    "Technology": [
      "AdTech",
      "AI & Machine Learning",
      "Blockchain",
      "Cloud Computing",
      "Cybersecurity",
      "Data Analytics",
      "Enterprise Software",
      "FinTech",
      "Internet of Things (IoT)",
      "SaaS (Software as a Service)",
      "Virtual & Augmented Reality (VR/AR)"
    ],
    "Healthcare": [
      "Biotech",
      "Digital Health",
      "HealthTech",
      "MedTech"
    ],
    "Energy & Sustainability": [
      "CleanTech",
      "Energy",
      "GreenTech",
      "Renewable Energy"
    ],
    "Consumer & Retail": [
      "Consumer Goods",
      "E-commerce",
      "FashionTech",
      "Food & Beverage",
      "RetailTech"
    ],
    "Transportation & Mobility": [
      "Automotive",
      "Mobility",
      "Logistics & Supply Chain",
      "SpaceTech"
    ],
    "Media & Entertainment": [
      "Entertainment",
      "Gaming",
      "MarTech",
      "Media",
      "Social Media",
      "SportsTech"
    ],
    "Other": [
      "AgriTech",
      "Drones & Robotics",
      "EdTech",
      "Human Resources (HR Tech)",
      "InsurTech",
      "LegalTech",
      "Manufacturing",
      "PropTech (Real Estate Tech)",
      "Sharing Economy",
      "Telecommunications",
      "Travel & Hospitality",
      "Wellness & Fitness"
    ]
  };
  
  export const CUSTOMER_TYPES = [
    "B2B",
    "B2C",
    "B2G",
    "SMBs",
    "Enterprises",
    "Freelancers",
    "Non-Profits",
    "Startups",
    "Educational Institutions",
    "Healthcare Providers"
  ];
  
  export const INVESTMENT_STAGES = [
    "Pre-Seed",
    "Seed",
    "Pre-A",
    "Series A",
    "Series B",
    "Series C"
  ];
  
  export const REGIONS = [
    "North America",
    "Europe",
    "Asia-Pacific",
    "Latin America",
    "Africa",
    "Middle East",
    "Global"
  ];
  
  export const COUNTRIES = [
    { name: "United States", region: "North America" },
    { name: "Canada", region: "North America" },
    { name: "United Kingdom", region: "Europe" },
    { name: "Germany", region: "Europe" },
    { name: "France", region: "Europe" },
    { name: "Spain", region: "Europe" },
    { name: "China", region: "Asia-Pacific" },
    { name: "Japan", region: "Asia-Pacific" },
    { name: "India", region: "Asia-Pacific" },
    { name: "Australia", region: "Asia-Pacific" },
    { name: "Brazil", region: "Latin America" },
    { name: "Mexico", region: "Latin America" },
    { name: "South Africa", region: "Africa" },
    { name: "Kenya", region: "Africa" },
    { name: "United Arab Emirates", region: "Middle East" },
    { name: "Saudi Arabia", region: "Middle East" },
    { name: "Israel", region: "Middle East" },
    { name: "Global", region: "Global" }
  ];
  
  // Utility function to filter countries by region
  export const getCountriesByRegion = (region) => {
    return COUNTRIES.filter(country => country.region === region);
  };
  