import React from 'react';
import { Box, Stat, StatLabel, Progress, Text, Divider } from '@chakra-ui/react';

// Helper function to format numbers in M for millions and K for thousands
const formatNumber = (number) => {
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(1)}M`;
  } else if (number >= 1000) {
    return `${(number / 1000).toFixed(0)}K`;
  }
  return number.toLocaleString();
};

const InvestmentGoalMetric = ({ goal, raised }) => {
  // Calculate the percentage of the funding goal
  const percentage = goal > 0 ? (raised / goal) * 100 : 0;

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <StatLabel color="gray.300">
          Investment Goal
        </StatLabel>

        {/* Progress bar with a small dot if percentage is 0 */}
        <Progress
          colorScheme="green" // Green progress bar
          bg="#1e293b" // Dark blue background
          size="xxl"
          value={percentage > 0 ? percentage : 1} // Ensure a tiny dot shows if percentage is 0
          borderRadius="sm"
          mt={2}
          height="29px" // Fat progress bar
        />

        {/* Subtle horizontal line below the progress bar */}
        <Divider mt={3} mb={2} borderColor="gray.600" />

        {/* Display raised and goal amounts, with white for the raised */}
        <Text fontSize="sm" color="gray.400">
          Committed:
        </Text>
        <Text fontSize="sm" color="white">
          ${formatNumber(raised)} / ${formatNumber(goal)}
        </Text>
      </Stat>
    </Box>
  );
};

export default InvestmentGoalMetric;
