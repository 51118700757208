import React, { useMemo } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import FinancialHealthScore from './topMetrics/FinancialHealthScore';
import RevenueGrowthRate from './topMetrics/RevenueGrowthRate';
import BurnToRevenueRatio from './topMetrics/BurnToRevenueRatio';
import BurnRateManagement from './topMetrics/BurnRateManagement';
import ProfitabilityTrajectory from './topMetrics/ProfitabilityTrajectory';

const FinancialsTopMetrics = () => {
  const auditData = useMemo(() => JSON.parse(localStorage.getItem('auditData')), []);
  const reportData = useMemo(() => JSON.parse(localStorage.getItem('reportData')), []);

  return (
    <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={6}>
      <FinancialHealthScore score={reportData?.sectionScores?.financialHealth || 0} />
      <RevenueGrowthRate growthRate={reportData?.financialMetrics?.revenueGrowthRate || 0} />
      <BurnToRevenueRatio ratio={reportData?.financialMetrics?.burnToRevenueRatio || 0} />
      <BurnRateManagement burnRate={reportData?.financialMetrics?.burnRate || 0} />
      <ProfitabilityTrajectory trajectory={reportData?.financialMetrics?.profitabilityTrajectory || 0} />
    </SimpleGrid>
  );
};

export default FinancialsTopMetrics;
