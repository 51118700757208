import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
  Flex,
  VStack,
  Image,
  useBreakpointValue,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import {
  UserCircleIcon,
  ChartBarIcon,
  ClipboardDocumentCheckIcon,
} from '@heroicons/react/24/outline';
import BullRiseLogo from '../assets/bullrise-logo.svg';
import { useAuth } from '../context/AuthContext';

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const { handleLogout } = useAuth();

  // Fetch reportId and reportStatus from localStorage as they are not stored in AuthContext
  const reportId = localStorage.getItem('reportId');
  const reportStatus = localStorage.getItem('reportStatus');
  const isMobile = useBreakpointValue({ base: true, md: false }); // Detect if it's a mobile device

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Account popup for mobile
  const AccountPopup = () => (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent
        bg="#1e293b"
        color="white"
        borderRadius="lg"
        bottom="0"
        position="absolute"
        zIndex="popover"
        overflow="hidden"
      >
        <ModalBody p={6}>
          <Box textAlign="center">
            <Button
              w="full"
              mb={4}
              bg="gray.600"
              color="white"
              _hover={{ bg: 'gray.700' }}
              onClick={() => {
                onClose();
                navigate('/account');
              }}
            >
              Account Settings
            </Button>
            <Button
              w="full"
              bg="red.600"
              color="white"
              _hover={{ bg: 'red.700' }}
              onClick={() => {
                handleLogout();
                onClose();
              }}
            >
              Logout
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );

  // Bottom navigation for mobile
  const BottomNav = () => (
    <HStack
      position="fixed"
      bottom="0"
      w="100%"
      bg="#0f172a"
      color="white"
      justifyContent="space-around"
      py={2}
      borderTop="1px solid"
      borderColor="gray.700"
      zIndex="1000"
    >
      <NavLink to={reportStatus === 'pending' ? '/audit-form-v2' : '/reportv2/task-dashboard'}>
        {({ isActive }) => (
          <IconButton
            icon={<ClipboardDocumentCheckIcon className="h-6 w-6" />}
            aria-label="Audit"
            variant="ghost"
            fontSize="lg"
            color={isActive ? 'white' : 'gray.400'}
            _hover={{ color: 'white' }}
          />
        )}
      </NavLink>
      {reportId && reportStatus === 'completed' && (
        <NavLink to={`/report/v2/${reportId}`}>
          {({ isActive }) => (
            <IconButton
              icon={<ChartBarIcon className="h-6 w-6" />}
              aria-label="Report"
              variant="ghost"
              fontSize="lg"
              color={isActive ? 'white' : 'gray.400'}
              _hover={{ color: 'white' }}
            />
          )}
        </NavLink>
      )}
      <IconButton
        icon={<UserCircleIcon className="h-6 w-6" />}
        aria-label="Account"
        variant="ghost"
        fontSize="lg"
        color="gray.400"
        _hover={{ color: 'white' }}
        onClick={onOpen}
      />
      <AccountPopup />
    </HStack>
  );

  // Sidebar for desktop
  const Sidebar = () => (
    <VStack
      w="60px"
      bg="#0f172a"
      color="white"
      spacing={2}
      py={2}
      borderRight="1px solid"
      borderColor="gray.700"
      align="center"
      justify="space-between"
      position="sticky"
      top={0}
      height="100vh"
      zIndex="1"
    >
      <Box mb={-1} mt={2} px={2}>
        <Image src={BullRiseLogo} alt="BullRise Logo" boxSize="30px" mb={4} />
      </Box>
      <VStack spacing={2} flex="1">
        <NavLink to={reportStatus === 'pending' ? '/audit-form-v2' : '/reportv2/task-dashboard'}>
          {({ isActive }) => (
            <IconButton
              icon={<ClipboardDocumentCheckIcon className="h-6 w-6" />}
              aria-label="Audit or Tasks"
              variant="ghost"
              fontSize="lg"
              color={isActive ? 'white' : 'gray.400'}
              _hover={{ color: 'white' }}
            />
          )}
        </NavLink>
        {reportId && reportStatus === 'completed' && (
          <NavLink to={`/report/v2/${reportId}`}>
            {({ isActive }) => (
              <IconButton
                icon={<ChartBarIcon className="h-6 w-6" />}
                aria-label="ReportV2"
                variant="ghost"
                fontSize="lg"
                color={isActive ? 'white' : 'gray.400'}
                _hover={{ color: 'white' }}
              />
            )}
          </NavLink>
        )}
      </VStack>
      <VStack spacing={2} mb={4}>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<UserCircleIcon className="h-6 w-6" />}
            aria-label="Account"
            variant="ghost"
            fontSize="lg"
            color="gray.400"
            _hover={{ color: 'white' }}
            _active={{ bg: 'rgba(15, 23, 42, 0.9)', color: 'white' }}
          />
          <MenuList
            bg="rgba(15, 23, 42, 0.9)"
            borderColor="#475569"
            boxShadow="lg"
            zIndex="dropdown"
          >
            <MenuItem
              onClick={() => navigate('/account')}
              bg="rgba(15, 23, 42, 0.9)"
              color="#cbd5e1"
              _hover={{ bg: '#334155', color: 'white' }}
            >
              Account
            </MenuItem>
            <MenuItem
              onClick={handleLogout}
              bg="rgba(15, 23, 42, 0.9)"
              color="#cbd5e1"
              _hover={{ bg: '#334155', color: 'white' }}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </VStack>
    </VStack>
  );

  return (
    <Flex height="100vh" direction="column" bg="#334155" zIndex="0">
      <Flex flex="1">
        {isMobile ? <BottomNav /> : <Sidebar />}
        <Box flex="1" p={6} overflowY="auto" bg="#1e293b" zIndex="0">
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Layout;
