// src/reportv2/team/ProfileDetailsModal.js

import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Avatar,
  Text,
  VStack,
  HStack,
  Divider,
  SimpleGrid,
  Icon,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'; // Chakra's default checkmark

const ProfileDetailsModal = ({ isOpen, onClose, profile }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent bg="gray.800" color="white">
        <ModalHeader>{profile.name}'s Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}> {/* Added padding to the bottom */}
          <VStack spacing={6} align="stretch">
            {/* Profile Picture and Name */}
            <HStack spacing={6}>
              <Avatar src={profile.profileImage} size="2xl" />
              <VStack align="flex-start" spacing={1}>
                <Text fontSize="2xl" fontWeight="bold">
                  {profile.name}
                </Text>
                <Text fontSize="lg" color="gray.400">
                  {profile.role}
                </Text>
                <Text fontSize="md" color="gray.300">
                  {profile.experience}
                </Text>
              </VStack>
            </HStack>

            <Divider borderColor="gray.600" />

            {/* Career Summary */}
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={3}>
                Career Summary
              </Text>
              <Text color="gray.300">
                {profile.careerSummary ||
                  'Highly skilled software engineer with over 10 years of experience in AI and full-stack development. Proven track record in leading development teams and building scalable products for global markets.'}
              </Text>
            </Box>

            {/* Work Experience */}
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={3}>
                Work Experience
              </Text>
              <VStack spacing={4} align="stretch">
                <Box>
                  <Text fontSize="md" fontWeight="bold" color="gray.100">
                    Senior AI Engineer at TechCorp
                  </Text>
                  <Text fontSize="sm" color="gray.400">
                    2018 - Present
                  </Text>
                  <Text color="gray.300">
                    Spearheaded the development of AI-powered applications that improved operational efficiency by 20%.
                    Led a team of 10 engineers and collaborated with cross-functional departments to deliver
                    cutting-edge solutions.
                  </Text>
                </Box>

                <Box>
                  <Text fontSize="md" fontWeight="bold" color="gray.100">
                    Lead Developer at InnovateNow
                  </Text>
                  <Text fontSize="sm" color="gray.400">
                    2015 - 2018
                  </Text>
                  <Text color="gray.300">
                    Led the development of a full-stack application serving over 500,000 users. Implemented a cloud-based
                    architecture that reduced operational costs by 15%.
                  </Text>
                </Box>
              </VStack>
            </Box>

            <Divider borderColor="gray.600" />

            {/* Education */}
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={3}>
                Education
              </Text>
              <VStack align="stretch" spacing={2}>
                <Box>
                  <Text fontSize="md" fontWeight="bold" color="gray.100">
                    M.Sc. in Artificial Intelligence
                  </Text>
                  <Text fontSize="sm" color="gray.400">
                    University of Advanced Technology, 2013 - 2015
                  </Text>
                </Box>
                <Box>
                  <Text fontSize="md" fontWeight="bold" color="gray.100">
                    B.Sc. in Computer Science
                  </Text>
                  <Text fontSize="sm" color="gray.400">
                    Global Tech University, 2009 - 2013
                  </Text>
                </Box>
              </VStack>
            </Box>

            {/* Skills Section */}
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={3}>
                Skills & Expertise
              </Text>
              <SimpleGrid columns={2} spacing={4}>
                <Text color="gray.300">AI & Machine Learning</Text>
                <Text color="gray.300">Full-Stack Development</Text>
                <Text color="gray.300">Cloud Architecture</Text>
                <Text color="gray.300">Team Leadership</Text>
                <Text color="gray.300">Python, JavaScript, React</Text>
                <Text color="gray.300">Agile Methodologies</Text>
              </SimpleGrid>
            </Box>

            <Divider borderColor="gray.600" />

            {/* Achievements Section */}
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={3}>
                Key Achievements
              </Text>
              <VStack align="stretch" spacing={3}>
                <HStack spacing={3}>
                  <Box
                    bg="green.500"
                    w={6}
                    h={6}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="full"
                  >
                    <Icon as={CheckIcon} boxSize={3} color="white" />
                  </Box>
                  <Text color="gray.300">
                    Increased user engagement by 30% through the implementation of advanced AI algorithms.
                  </Text>
                </HStack>

                <HStack spacing={3}>
                  <Box
                    bg="green.500"
                    w={6}
                    h={6}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="full"
                  >
                    <Icon as={CheckIcon} boxSize={3} color="white" />
                  </Box>
                  <Text color="gray.300">
                    Reduced cloud infrastructure costs by 15% through the development of scalable cloud solutions.
                  </Text>
                </HStack>

                <HStack spacing={3}>
                  <Box
                    bg="green.500"
                    w={6}
                    h={6}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="full"
                  >
                    <Icon as={CheckIcon} boxSize={3} color="white" />
                  </Box>
                  <Text color="gray.300">
                    Won the “Best Innovator Award” at InnovateNow for groundbreaking work in AI research.
                  </Text>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProfileDetailsModal;
