const auditQuestionsV2 = [
  // Company Information Section
  {
    id: 1,
    question: "What's your company called?",
    inputType: "text",
    fieldName: "companyName",
    placeholder: "Enter your amazing company name!",
    validation: { required: true },
    section: "Company Information",
  },
  {
    id: 2,
    question: "Which industry are you shaking up?",
    inputType: "dropdown",
    fieldName: "industry",
    options: 'industries',
    placeholder: "Pick your industry, you're the star!",
    validation: { required: true },
    section: "Company Information",
  },
  {
    id: 3,
    question: "Where's your HQ?",
    inputType: "dropdown",
    fieldName: "location",
    options: 'allCountries',
    placeholder: "Select your global HQ",
    validation: { required: true },
    section: "Company Information",
  },
  {
    id: 4,
    question: "When did you kick things off?",
    inputType: "month-year-dropdown",
    fieldName: "foundingDate",
    placeholder: "Select the birth month",
    validation: { required: true },
    section: "Company Information",
  },
  {
    id: 5,
    question: "How many founders are behind the magic?",
    inputType: "founder-dropdown",
    fieldName: "numberOfFounders",
    placeholder: "Select the number of awesome founders",
    validation: { required: true },
    section: "Company Information",
  },
  {
    id: 6,
    question: "How big is your dream team?",
    inputType: "number",
    fieldName: "companySize",
    placeholder: "Enter the number of team members",
    validation: { required: true },
    section: "Company Information",
  },

  // Funding and Financial Information Section
  {
    id: 7,
    question: "What's your current investment stage?",
    inputType: "dropdown",
    fieldName: "investmentStage",
    options: 'investmentStages',
    placeholder: "Pick your stage – are you early, mid, or late?",
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 8,
    question: "How much funding are you shooting for?",
    inputType: "formatted-number",
    fieldName: "fundingGoal",
    placeholder: "Enter the big number you're aiming for!",
    validation: { required: true },
    format: "currency",
    section: "Funding and Financial Information",
  },
  {
    id: 9,
    question: "How much funding have you secured so far?",
    inputType: "formatted-number",
    fieldName: "currentFunding",
    placeholder: "Enter the funds you've locked in",
    validation: { required: false },
    format: "currency",
    section: "Funding and Financial Information",
  },
  {
    id: 10,
    question: "Have you raised funds before?",
    inputType: "dropdown",
    fieldName: "raisedMoneyBefore",
    options: ["Yes", "No"],
    placeholder: "Select Yes or No",
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 11,
    question: "How much have you raised previously?",
    inputType: "formatted-number",
    fieldName: "previousFunding",
    placeholder: "Enter the total amount of previous funding",
    validation: { required: false },
    format: "currency",
    section: "Funding and Financial Information",
    conditional: { field: "raisedMoneyBefore", value: "Yes" },
  },
  {
    id: 12,
    question: "Who has invested in you? (Angel, VC, Corporate)",
    inputType: "multi-select",
    fieldName: "investorTypes",
    options: ["Angel", "VC", "Corporate", "Other"],
    placeholder: "Select the types of your investors",
    validation: { required: false },
    section: "Funding and Financial Information",
    conditional: { field: "raisedMoneyBefore", value: "Yes" },
  },
  {
    id: 13,
    question: "What was your revenue last month and three months ago?",
    inputType: "multi-number",
    fieldName: "revenueLastMonths",
    fields: [
      { name: "lastMonthRevenue", label: "$ Last month", format: "currency", placeholder: "Enter last month's revenue" },
      { name: "threeMonthsAgoRevenue", label: "$ Three months ago", format: "currency", placeholder: "Enter revenue from 3 months ago" }
    ],
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 14,
    question: "How many total customers do you have?",
    inputType: "number",
    fieldName: "totalCustomers",
    placeholder: "Enter the total number of customers",
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 15,
    question: "What was your number of paying customers last month and three months ago?",
    inputType: "multi-number",
    fieldName: "payingCustomersLastMonths",
    fields: [
      { name: "lastMonthCustomers", label: "Last month", format: "number", placeholder: "Last month's number of paying customers" },
      { name: "threeMonthsAgoCustomers", label: "Three months ago", format: "number", placeholder: "Number of paying customers from 3 months ago" }
    ],
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 16,
    question: "What was your cost last month and three months ago?",
    inputType: "multi-number",
    fieldName: "monthlyCost",
    fields: [
      { name: "lastMonthCost", label: "$ Last month", format: "currency", placeholder: "Enter last month's cost" },
      { name: "threeMonthsAgoCost", label: "$ Three months ago", format: "currency", placeholder: "Enter your cost from 3 months ago" }
    ],
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 17,
    question: "How many months of runway do you have?",
    inputType: "number",
    fieldName: "runway",
    placeholder: "Enter the number of months",
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 18,
    question: "How will you spend the money you raise?",
    inputType: "multi-select",
    fieldName: "fundAllocation",
    options: ["R&D", "Marketing", "Sales", "Hiring", "Operations"],
    placeholder: "Select how you’ll use the funds",
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 19,
    question: "What's your exit strategy?",
    inputType: "dropdown",
    fieldName: "exitStrategy",
    options: ["Acquisition", "IPO", "Stay Private"],
    placeholder: "Pick your exit plan",
    validation: { required: true },
    section: "Funding and Financial Information",
  },

  // Market and Customer Information Section
  {
    id: 20,
    question: "How many direct competitors do you have?",
    inputType: "dropdown",
    fieldName: "competitorLandscape",
    options: 'competitorLandscapes',
    placeholder: "How many rivals do you have?",
    validation: { required: true },
    section: "Market and Customer Information",
  },
  {
    id: 21,
    question: "Please provide the website URLs of up to 3 key competitors.",
    inputType: "multi-text",
    fieldName: "competitorUrls",
    placeholder: "Enter their website URLs here",
    validation: { required: false },
    section: "Market and Customer Information",
  },
  {
    id: 22,
    question: "What are the primary target markets for your product?",
    inputType: "multi-select",
    fieldName: "targetMarket",
    options: 'regions',
    placeholder: "Select your main markets",
    validation: { required: true },
    section: "Market and Customer Information",
  },
  {
    id: 23,
    question: "Who are your primary customers?",
    inputType: "dropdown",
    fieldName: "customerType",
    options: 'customerTypes',
    placeholder: "Select your primary customer type",
    validation: { required: true },
    section: "Market and Customer Information",
  },
  {
    id: 24,
    question: "What is the estimated size of your target market?",
    inputType: "dropdown",
    fieldName: "marketOpportunity",
    options: 'marketOpportunities',
    placeholder: "Select the estimated market size",
    validation: { required: true },
    section: "Market and Customer Information",
  },

  // Product and Innovation Section
  {
    id: 25,
    question: "What is your current product development stage?",
    inputType: "dropdown",
    fieldName: "productRoadmap",
    options: 'productRoadmaps',
    placeholder: "Pick your current stage of development",
    validation: { required: true },
    section: "Product and Innovation",
  },
  {
    id: 26,
    question: "Do you have any patents or proprietary technologies?",
    inputType: "dropdown",
    fieldName: "patents",
    options: ["Yes", "No"],
    placeholder: "Select Yes or No",
    validation: { required: true },
    section: "Product and Innovation",
  },
  {
    id: 27,
    question: "How is your product differentiated from competitors?",
    inputType: "dropdown",
    fieldName: "productDifferentiation",
    options: ["Price", "Features", "Technology", "User Experience", "Other"],
    placeholder: "Select what makes you stand out",
    validation: { required: true },
    section: "Product and Innovation",
  },
  {
    id: 28,
    question: "Describe your product in 100 words or less.",
    inputType: "textarea",
    fieldName: "productDescription",
    placeholder: "Describe your product briefly, focus on key features.",
    validation: { required: true },
    section: "Product and Innovation",
  },
  {
    id: 29,
    question: "What are the key benefits of using your product or service? How do these benefits align with your customers' needs?",
    inputType: "textarea",
    fieldName: "productBenefits",
    placeholder: "Three or more key benefits are the norm.",
    validation: { required: true },
    section: "Product and Innovation",
  },

  // Go-to-Market (GTM) Strategy Section
  {
    id: 30,
    question: "What is your go-to-market strategy?",
    inputType: "dropdown",
    fieldName: "gtmStrategy",
    options: 'gtmStrategies',
    placeholder: "Select your GTM approach",
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 31,
    question: "Which channels are you using to acquire customers?",
    inputType: "multi-select",
    fieldName: "customerAcquisitionChannels",
    options: ["SEO", "Paid Ads", "Content Marketing", "Partnerships", "Cold Outreach", "Social Media", "Events"],
    placeholder: "Select your customer acquisition channels",
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 32,
    question: "Which of these channels has been the most effective so far?",
    inputType: "multi-select",
    fieldName: "effectiveChannels",
    options: ["SEO", "Paid Ads", "Content Marketing", "Partnerships", "Cold Outreach", "Social Media", "Events"],
    placeholder: "Select your most effective channels",
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 33,
    question: "What is your pricing strategy?",
    inputType: "dropdown",
    fieldName: "pricingStrategy",
    options: ["Subscription", "Freemium", "Pay-per-use", "One-time fee", "Tiered Pricing", "Volume Discounts"],
    placeholder: "Pick your pricing model",
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 34,
    question: "How long does it take you to get a customer? (in Days)",
    inputType: "number",
    fieldName: "customerAcquisitionTime",
    placeholder: "Enter the average time in days",
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 35,
    question: "How many customers do you win per month on average?",
    inputType: "number",
    fieldName: "customersWonPerMonth",
    placeholder: "Enter your average monthly customer wins",
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 36,
    question: "How many customers do you lose per month on average?",
    inputType: "number",
    fieldName: "customerLossPerMonth",
    placeholder: "Enter your average monthly customer loss",
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },

  // Founder and Team Experience Section
  {
    id: 37,
    question: "How many years of experience do the founders have in this industry?",
    inputType: "dropdown",
    fieldName: "founderExperience",
    options: ["0-2 years", "3-5 years", "6-10 years", "10+ years"],
    placeholder: "Select the total experience of your founders",
    validation: { required: true },
    section: "Founder and Team Experience",
  },
  {
    id: 38,
    question: "Does your team have a diverse set of skills across product, marketing, finance, etc.?",
    inputType: "dropdown",
    fieldName: "teamSkills",
    options: ["Yes", "No"],
    placeholder: "Select Yes or No",
    validation: { required: true },
    section: "Founder and Team Experience",
  },
  {
    id: 39,
    question: "What key achievements or milestones has your team accomplished so far?",
    inputType: "multi-select",
    fieldName: "milestones",
    options: ["Launched product", "Raised significant funding", "Secured strategic partnerships", "Won industry awards", "Expanded internationally", "Reached profitability"],
    placeholder: "Select your team's key achievements",
    validation: { required: false },
    section: "Founder and Team Experience",
  },
  {
    id: 40,
    question: "Do you have any advisors or mentors supporting your business?",
    inputType: "dropdown",
    fieldName: "advisors",
    options: ["Yes", "No"],
    placeholder: "Select Yes or No",
    validation: { required: true },
    section: "Founder and Team Experience",
  },

  // Risks and Challenges Section
  {
    id: 41,
    question: "What are the biggest risks to your business?",
    inputType: "multi-select",
    fieldName: "businessRisks",
    options: ["Market Competition", "Regulatory Issues", "Funding Challenges", "Product-Market Fit", "Supply Chain Disruptions"],
    placeholder: "Select your key business risks",
    validation: { required: true },
    section: "Risks and Challenges",
  },
  {
    id: 42,
    question: "What actions have you taken to mitigate these risks?",
    inputType: "textarea",
    fieldName: "riskMitigation",
    placeholder: "Enter any risk mitigation strategies",
    validation: { required: false },
    section: "Risks and Challenges",
  },

  // Last Question (Feedback)
  {
    id: 43,
    question: "What do you expect to get out of this audit?",
    inputType: "multi-select",
    fieldName: "auditExpectations",
    options: ["Identify funding gaps", "Improve pitch for investors", "Refine go-to-market strategy", "Identify operational challenges", "Better understand financial health", "No specific expectations"],
    placeholder: "Select your expectations from this audit",
    validation: { required: true },
    section: "Feedback",
  },
  {
    id: 44,
    question: "What are your top priorities for improvement?",
    inputType: "textarea",
    fieldName: "improvementPriorities",
    placeholder: "Share your top improvement priorities",
    validation: { required: false },
    section: "Feedback",
  },
];

export default auditQuestionsV2;
