// src/reportv2/product/topMetrics/ProductMarketFit.js

import React from 'react';
import { Box, Stat, StatLabel, StatNumber, Tooltip, Text } from '@chakra-ui/react';

const ProductMarketFit = ({ auditData }) => {
  const marketFitScore = auditData?.marketFitScore || "N/A";

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Product-Market Fit</Text>
              <Text>Indicates how well the product aligns with the needs of the market, based on initial customer feedback.</Text>
            </>
          }
          aria-label="Product-Market Fit tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Product-Market Fit</StatLabel>
        </Tooltip>

        <StatNumber color="gray.300" fontSize="2xl" mt={0}>
          Coming soon!
        </StatNumber>
      </Stat>
    </Box>
  );
};

export default ProductMarketFit;
