// src/reportv2/product/topMetrics/PatentsProprietaryTech.js

import React from 'react';
import { Box, Stat, StatLabel, StatNumber, Tooltip, Text } from '@chakra-ui/react';

const PatentsProprietaryTech = ({ auditData }) => {
  const hasPatents = auditData?.patents ? "Yes" : "No";

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Patents/Proprietary Tech</Text>
              <Text>Indicates whether the product has patented technology or unique intellectual property.</Text>
            </>
          }
          aria-label="Patents/Proprietary Tech tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Patents/Proprietary Tech</StatLabel>
        </Tooltip>

        <StatNumber color="white" fontSize="2xl" mt={0}>
          {hasPatents}
        </StatNumber>
      </Stat>
    </Box>
  );
};

export default PatentsProprietaryTech;
