import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Box, Button, VStack, FormControl, FormLabel, Text, Progress, CircularProgress } from '@chakra-ui/react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import withUserProfileCheck from '../hoc/withUserProfileCheck';
import auditQuestionsV2 from '../utils/auditQuestionsV2';
import AuditLandingPage from '../components/AuditLandingPage';
import { initializeFormValues, validateStep, renderField, shouldDisplayQuestion, generateStepValidationSchema } from '../utils/formUtils';
import { API_URL } from '../constants';
import { useAuth } from '../context/AuthContext';

const AuditFormV2 = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState(initializeFormValues('v2'));
  const [shake, setShake] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [showAutoSaveMessage, setShowAutoSaveMessage] = useState(false); // State for autosave message visibility
  const totalQuestions = auditQuestionsV2.length;
  const navigate = useNavigate();
  
  const { token, refreshAccessToken, handleLogout } = useAuth();

  const userId = localStorage.getItem('userId');
  const startupId = localStorage.getItem('startupId');

  const AUDIT_PROGRESS_KEY = `auditFormProgressV2_${userId}_${startupId}`;
  const AUDIT_COMPLETED_KEY = `auditFormCompletedV2_${userId}_${startupId}`;

  // Toggle the autosave message every 20 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setShowAutoSaveMessage(true);  // Show the message
      setTimeout(() => setShowAutoSaveMessage(false), 5000); // Hide message after 5 seconds
    }, 20000); // Toggle every 20 seconds

    return () => clearInterval(interval);
  }, []);

  const handleStatusRedirection = () => {
    const auditId = localStorage.getItem(`auditIdV2_${userId}_${startupId}`);
    const reportId = localStorage.getItem('reportId');
    const completedStatus = localStorage.getItem(AUDIT_COMPLETED_KEY);

    if (auditId && reportId) {
      console.log('Redirecting to report page...');
      navigate(`/report/v2/${reportId}`);
      return true;
    }

    if (auditId && completedStatus) {
      console.log('Redirecting to audit completed page...');
      navigate(`/audit-completed-v2/${auditId}`);
      return true;
    }

    return false;
  };

  const loadSavedProgress = () => {
    const savedProgress = localStorage.getItem(AUDIT_PROGRESS_KEY);
    if (savedProgress) {
      const { values, step } = JSON.parse(savedProgress);
      let validStep = step;
      while (validateStep(validStep, values, 'v2')) {
        validStep++;
      }
      setFormValues(values);
      setCurrentStep(validStep);
    } else {
      setFormValues(initializeFormValues('v2'));
      setCurrentStep(0);
    }
  };

  useEffect(() => {
    const redirected = handleStatusRedirection();
    if (!redirected) {
      loadSavedProgress();
    }
    setLoading(false);
  }, [navigate]);

  const saveProgress = (values, step) => {
    const progress = { values, step };
    localStorage.setItem(AUDIT_PROGRESS_KEY, JSON.stringify(progress));
  };

  const handleNext = (values, validateForm) => {
    validateForm(values).then((errors) => {
      if (Object.keys(errors).length === 0) {
        let nextStep = currentStep + 1;
        while (validateStep(nextStep, values, 'v2')) {
          nextStep += 1;
        }
        saveProgress(values, nextStep);
        setFormValues(values);
        setCurrentStep(nextStep);
        setShake(false);
      } else {
        setShake(true);
        setTimeout(() => setShake(false), 500);
      }
    });
  };

  const handleBack = () => {
    let previousStep = currentStep - 1;
    while (validateStep(previousStep, formValues, 'v2')) {
      previousStep -= 1;
    }
    setCurrentStep(previousStep);
    saveProgress(formValues, previousStep);
  };

  const handleSubmit = async (values) => {
    try {
      const formValues = {
        ...values,
        startupId,
        advisors: values.advisors === 'Yes',
        teamSkills: values.teamSkills === 'Yes',
        patents: values.patents === 'Yes',
        raisedMoneyBefore: values.raisedMoneyBefore === 'Yes',
        fundingGoal: Number(values.fundingGoal),
        currentFunding: Number(values.currentFunding),
        payingCustomers: Number(values.payingCustomers),
        runway: Number(values.runway),
        numberOfFounders: Number(values.numberOfFounders),
        customerAcquisitionTime: Number(values.customerAcquisitionTime),
        customersWonPerMonth: Number(values.customersWonPerMonth),
        customerLossPerMonth: Number(values.customerLossPerMonth),
        revenueLastMonths: {
          lastMonthRevenue: Number(values.revenueLastMonths.lastMonthRevenue),
          threeMonthsAgoRevenue: Number(values.revenueLastMonths.threeMonthsAgoRevenue),
        },
        monthlyCost: {
          lastMonthCost: Number(values.monthlyCost.lastMonthCost),
          threeMonthsAgoCost: Number(values.monthlyCost.threeMonthsAgoCost),
        },
      };

      await refreshAccessToken();
      const auditResponse = await axios.post(`${API_URL}/v2/audits`, formValues, {
        headers: { Authorization: `Bearer ${token}` },
      });

      let auditIdV2 = auditResponse.data.auditId;

      if (!auditIdV2) {
        console.log('Audit ID not found in the response, fetching manually...');
        const fallbackAuditResponse = await axios.get(`${API_URL}/v2/audits/startup/${startupId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        auditIdV2 = fallbackAuditResponse.data.audit._id;
      }

      localStorage.setItem(`auditIdV2_${userId}_${startupId}`, auditIdV2);
      localStorage.setItem(AUDIT_COMPLETED_KEY, 'true');
      navigate(`/audit-completed-v2/${auditIdV2}`);
      
    } catch (error) {
      setError('Error submitting the form. Please try again.');
      console.error('Error during audit submission:', error);
      if (error.response?.status === 401) handleLogout();
    }
  };

  // Show loading spinner while loading is true
  if (loading) {
    return (
      <VStack spacing={6} align="center" justify="center" bg="#1e293b" p={8} rounded="lg" minHeight="70vh">
        <Text fontSize="2xl" color="white">Loading...</Text>
        <CircularProgress isIndeterminate color="green.300" size="120px" thickness="4px" />
      </VStack>
    );
  }

  return (
    <Formik
    key={currentStep}
    enableReinitialize
    initialValues={formValues}
    validationSchema={generateStepValidationSchema(currentStep, formValues, 'v2')}
    validateOnChange={currentStep > 0}
    validateOnBlur={currentStep > 0}
    onSubmit={handleSubmit}
  >
    {({ values, setFieldValue, validateForm, errors, touched }) => (
      <Form>
        {/* Error Message Box */}
        {error && (
          <Box
            bg="red.100"
            color="red.700"
            p={4}
            mb={4}
            borderRadius="md"
            width={{ base: "90%", md: "70%" }}
            mx="auto"
          >
            {error}
          </Box>
        )}
  
        {/* Landing Page for Step 0 */}
        {currentStep === 0 && (
          <AuditLandingPage
            onStartAudit={() => handleNext(values, validateForm)}
          />
        )}
  
        {/* Main Form Steps */}
        {currentStep > 0 && currentStep <= totalQuestions && (
          <>
            {/* Progress Bar */}
            <VStack
              width={{ base: "90%", md: "70%" }}
              mx="auto"
              spacing={4}
              align="stretch"
            >
              <Progress
                value={(currentStep / totalQuestions) * 100}
                colorScheme="green"
                bg="gray.700"
                width="100%"
                height="6px"
                borderRadius="full"
              />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Text color="white" fontSize="sm">
                  {auditQuestionsV2[currentStep - 1].section}
                </Text>
                <Box display="flex" alignItems="center">
                  <AnimatePresence>
                    {showAutoSaveMessage && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Text color="green.300" fontSize="xs" mr={2}>
                          Progress saved
                        </Text>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <Text color="white" fontSize="xs">
                    {Math.round((currentStep / totalQuestions) * 100)}%
                  </Text>
                </Box>
              </Box>
            </VStack>
  
            {/* Question and Input Section */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <VStack
                spacing={6}
                align="stretch"
                justify="center"
                bg="#1e293b"
                p={{ base: 4, md: 8 }}
                rounded="lg"
                minHeight="70vh"
                width={{ base: "100%", sm: "90%", lg: "70%" }}
                mx="auto"
              >
                <FormControl
                  isInvalid={
                    !!errors[auditQuestionsV2[currentStep - 1].fieldName] &&
                    touched[auditQuestionsV2[currentStep - 1].fieldName]
                  }
                >
                  <FormLabel
                    color="gray.300"
                    fontSize={{ base: "lg", md: "2xl" }}
                    fontWeight="500"
                    textAlign="left"
                    mb={4}
                  >
                    {auditQuestionsV2[currentStep - 1].question}
                  </FormLabel>
                  <motion.div
                    animate={shake ? { x: [-10, 10, -10, 10, 0] } : {}}
                    transition={{ type: "spring", stiffness: 260, damping: 20 }}
                  >
                    {shouldDisplayQuestion(
                      auditQuestionsV2[currentStep - 1],
                      values
                    ) &&
                      renderField(
                        auditQuestionsV2[currentStep - 1],
                        values,
                        setFieldValue
                      )}
                  </motion.div>
                  <ErrorMessage
                    name={auditQuestionsV2[currentStep - 1].fieldName}
                    component={Text}
                    color="red.500"
                  />
                </FormControl>
  
                {/* Navigation Buttons */}
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  mt={4}
                >
                  <Button
                    onClick={handleBack}
                    disabled={currentStep === 1}
                    bg="gray.600"
                    color="gray.300"
                    _hover={{ bg: "gray.500" }}
                    _disabled={{ bg: "gray.700", color: "gray.500" }}
                    size={{ base: "lg", md: "lg" }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={() => {
                      if (currentStep === totalQuestions) {
                        handleSubmit(values);
                      } else {
                        handleNext(values, validateForm);
                      }
                    }}
                    colorScheme="green"
                    size={{ base: "lg", md: "lg" }}
                  >
                    {currentStep === totalQuestions ? "Submit Audit" : "Next"}
                  </Button>
                </Box>
              </VStack>
            </motion.div>
          </>
        )}
  
        {/* Completed Screen */}
        {currentStep > totalQuestions && (
          <VStack
            spacing={6}
            align="center"
            justify="center"
            bg="#1e293b"
            p={8}
            rounded="lg"
            minHeight="70vh"
          >
            <Text fontSize="2xl" color="white">
              Audit Completed!
            </Text>
            <Text color="gray.300">
              Your report will be ready soon. Grab some coffee while waiting...
            </Text>
            <CircularProgress
              isIndeterminate
              color="green.300"
              size="120px"
              thickness="4px"
            />
          </VStack>
        )}
      </Form>
    )}
  </Formik>  
  );
};

export default withUserProfileCheck(AuditFormV2);
