// src/reportv2/ProductSection.js

import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import ProductTopMetrics from './product/ProductTopMetrics';
import ProductDescriptionSection from './product/ProductDescriptionSection';

const ProductSection = ({ auditData, reportData }) => {
  return (
    <VStack spacing={6} align="stretch" mb={6}>
      {/* Top Metrics Section */}
      <Box>
        <ProductTopMetrics auditData={auditData} reportData={reportData} />
      </Box>

      {/* Product Description Section */}
      <ProductDescriptionSection />
    </VStack>
  );
};

export default ProductSection;
