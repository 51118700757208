// src/reportv2/team/topMetrics/AdvisorSupportNetwork.js

import React from 'react';
import { Box, Stat, StatLabel, StatNumber, Tooltip, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const AdvisorSupportNetwork = ({ hasAdvisors }) => (
  <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
    <Stat>
      <Tooltip
        label={<Text>Indicates whether the team has external advisors or mentors.</Text>}
        aria-label="Advisor Support Network tooltip"
        placement="top"
        hasArrow
        bg="gray.700"
        color="white"
        p={3}
        borderRadius="md"
        fontSize="xs"
      >
        <StatLabel color="gray.300" cursor="help">Advisor Support Network</StatLabel>
      </Tooltip>
      <StatNumber color="white" fontSize="2xl">
        {hasAdvisors ? <CheckCircleIcon color="green.400" /> : "No Advisors"}
      </StatNumber>
    </Stat>
  </Box>
);

export default AdvisorSupportNetwork;
