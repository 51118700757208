// src/reportv2/market/topMetrics/MarketPenetrationSpeed.js

import React from 'react';
import { Box, Stat, StatLabel, StatNumber, Tooltip, Text, Divider, VStack } from '@chakra-ui/react';

const MarketPenetrationSpeed = ({ reportData }) => {
  const penetrationRate = reportData?.marketMetrics?.penetrationRate || 12.5;
  const penetrationSpeedLabel = reportData?.marketMetrics?.penetrationSpeed || 'Moderate';
  const monthlyCustomerGrowth = reportData?.marketMetrics?.monthlyCustomerGrowth || 5;

  const getColor = (speed) => {
    switch (speed) {
      case 'High': return 'green.400';
      case 'Moderate': return 'yellow.400';
      case 'Low': return 'red.400';
      default: return 'gray.400';
    }
  };

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text>
                <strong>Market Penetration Speed</strong> measures how quickly the company is gaining share in the target market over a period. Higher speeds mean faster growth and adaptation, typically influenced by customer acquisition, product-market fit, and marketing strategies.
              </Text>
              <Text mt={2}>
                <strong>Market Penetration Rate</strong> represents the percentage of the total addressable market (TAM) that the company has captured so far. A higher penetration rate indicates a more established presence, while speed reflects the rate of growth.
              </Text>
            </>
          }
          aria-label="Market Penetration Speed and Rate tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Market Penetration Speed</StatLabel>
        </Tooltip>

        <StatNumber color={getColor(penetrationSpeedLabel)} fontSize="2xl" mt={0}>
          {penetrationSpeedLabel}
          <Text as="span" fontSize="lg" color="white" ml={2}>
            {penetrationRate}%
          </Text>
        </StatNumber>

        <Divider my={2} borderColor="gray.600" />

        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.400">
            Monthly Customer Growth:
          </Text>
          <Text fontSize="sm" color="white">
            {monthlyCustomerGrowth} new customers/month
          </Text>
        </VStack>
      </Stat>
    </Box>
  );
};

export default MarketPenetrationSpeed;
