import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Switch,
  Grid,
  ButtonGroup,
  Button,
  Collapse,
  IconButton,
  useBreakpointValue,
  Divider,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

const FinancialsGrowthChart = ({ reportData }) => {
  const [chartData, setChartData] = useState(null);
  const [showRevenue, setShowRevenue] = useState(true);
  const [showCost, setShowCost] = useState(true);
  const [showBurn, setShowBurn] = useState(false);
  const [showCash, setShowCash] = useState(false);
  const [timeRange, setTimeRange] = useState('ALL');
  const [availableRanges, setAvailableRanges] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const columns = useBreakpointValue({ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' });

  const auditData = JSON.parse(localStorage.getItem('auditData')) || {};
  const foundingDate = auditData?.foundingDate || '2021-06';

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const foundingYear = parseInt(foundingDate.split('-')[0]);
    const yearsSinceFounding = currentYear - foundingYear;

    const ranges = ['3M', '6M', 'YTD'];
    if (yearsSinceFounding >= 1) ranges.push('1Y');
    if (yearsSinceFounding >= 2) ranges.push('2Y');
    if (yearsSinceFounding >= 5) ranges.push('5Y');
    ranges.push('ALL');

    setAvailableRanges(ranges);
  }, [foundingDate]);

  const getStartDateForRange = () => {
    const today = new Date();
    let startDate;
    switch (timeRange) {
      case '3M':
        startDate = new Date(today.setMonth(today.getMonth() - 3));
        break;
      case '6M':
        startDate = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case 'YTD':
        startDate = new Date(today.getFullYear(), 0, 1);
        break;
      case '1Y':
        startDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case '2Y':
        startDate = new Date(today.setFullYear(today.getFullYear() - 2));
        break;
      case '5Y':
        startDate = new Date(today.setFullYear(today.getFullYear() - 5));
        break;
      case 'ALL':
        startDate = new Date(foundingDate);
        break;
      default:
        startDate = new Date(foundingDate);
    }
    return startDate;
  };

  const createSegmentedDataset = (label, data, color, yAxisID) => {
    const lastSolidPointIndex = data.findIndex((point) => point.y !== 0);
    return {
      label,
      data,
      borderColor: `rgba(${color}, 1)`,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverBorderWidth: 3,
      pointHoverRadius: 10,
      hitRadius: 10, 
      tension: 0.2,
      spanGaps: true,
      yAxisID,
      segment: {
        borderDash: (ctx) =>
          ctx.p0DataIndex < lastSolidPointIndex ? [5, 5] : [],
        borderColor: (ctx) =>
          ctx.p0DataIndex < lastSolidPointIndex
            ? 'rgba(128, 128, 128, 1)'
            : `rgba(${color}, 1)`,
        backgroundColor: (ctx) =>
          ctx.p0DataIndex >= lastSolidPointIndex
            ? createSolidLineGradient(ctx.chart.ctx, ctx.chart.chartArea, color)
            : 'transparent',
      },
      fill: true,
    };
  };

  const createSolidLineGradient = (ctx, chartArea, color) => {
    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
    gradient.addColorStop(0, `rgba(${color}, 0.3)`);
    gradient.addColorStop(1, `rgba(${color}, 0)`);
    return gradient;
  };

  const calculateCashDataset = (burnRate, initialCash, lastRealDataPoint) => {
    const cashData = [];
    let remainingCash = initialCash;
    let currentDate = new Date(lastRealDataPoint?.x);

    while (remainingCash > 0) {
      cashData.push({ x: currentDate.toISOString().slice(0, 10), y: remainingCash });
      currentDate.setMonth(currentDate.getMonth() + 1);
      remainingCash = Math.max(remainingCash - burnRate, 0);
    }

    return cashData;
  };

  useEffect(() => {
    if (reportData && reportData.timeSeriesData) {
      const startDate = getStartDateForRange();
      const revenueHistory = reportData.timeSeriesData.revenueHistory || [];
      const burnHistory = reportData.timeSeriesData.burnHistory || [];
      const costHistory = reportData.timeSeriesData.costHistory || [];

      const datasets = [];

      if (showRevenue) {
        const revenueValues = revenueHistory
          .filter((point) => new Date(point.date) >= startDate)
          .map((point) => ({ x: point.date, y: point.revenue }));
        datasets.push(createSegmentedDataset('Revenue', revenueValues, '75, 192, 192', 'y-axis-monetary'));
      }

      if (showBurn) {
        const burnValues = burnHistory
          .filter((point) => new Date(point.date) >= startDate)
          .map((point) => ({ x: point.date, y: Math.abs(point.burn) }));
        datasets.push(createSegmentedDataset('Burn', burnValues, '255, 99, 132', 'y-axis-monetary'));
      }

      if (showCost) {
        const costValues = costHistory
          .filter((point) => new Date(point.date) >= startDate)
          .map((point) => ({ x: point.date, y: point.cost }));
        datasets.push(createSegmentedDataset('Cost', costValues, '255, 206, 86', 'y-axis-monetary'));
      }

      if (showCash) {
        const lastRealDataPoint = burnHistory[burnHistory.length - 1] || {};
        const burnRate = Math.abs(lastRealDataPoint?.burn || 0);
        const initialCash = (auditData?.runway || 0) * burnRate;

        if (burnRate > 0 && initialCash > 0 && lastRealDataPoint?.date) {
          const cashValues = calculateCashDataset(burnRate, initialCash, {
            x: lastRealDataPoint.date,
            y: initialCash,
          });
          datasets.push(createSegmentedDataset('Cash', cashValues, '54, 162, 235', 'y-axis-monetary'));
        }
      }

      setChartData({ datasets });
    }
  }, [reportData, showRevenue, showBurn, showCost, showCash, timeRange]);

  const chartOptions = {
    responsive: true,
    plugins: {
      tooltip: { mode: 'index', intersect: false },
      legend: { display: false },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: timeRange === 'ALL' ? 'year' : 'month',
          displayFormats: { month: 'yyyy-MM', year: 'yyyy' },
        },
        min: getStartDateForRange(),
        grid: { color: 'rgba(75, 85, 99, 0.3)' },
      },
      'y-axis-monetary': {
        type: 'linear',
        position: 'left',
        ticks: {
          color: '#D1D5DB',
          callback: (value) =>
            value >= 1000000
              ? `$${Math.round(value / 1000000)}M`
              : `$${Math.round(value / 1000)}K`,
        },
        grid: { color: 'rgba(75, 85, 99, 0.3)' },
        min: 0,
      },
    },
  };

  if (!chartData) return <Text color="red.500">No data available.</Text>;

  return (
    <Box mb={12} p={4} bg="rgba(15, 23, 42, 0.4)" borderRadius="md" mt={8}>
      <Flex direction="column" mb={4}>
        <Flex align="center" justify="space-between" mb={2}>
          <IconButton
            icon={<SettingsIcon />}
            onClick={() => setShowSettings(!showSettings)}
            variant="ghost"
            size="lg"
            color="gray.400"
            _hover={{ color: 'white' }}
            aria-label="Toggle settings"
          />
          <Flex overflowX="auto" whiteSpace="nowrap" alignItems="center" gap={2}>
            <ButtonGroup size="sm">
              {availableRanges.map((range) => (
                <Button
                  key={range}
                  variant="unstyled"
                  color={timeRange === range ? 'white' : 'gray.400'}
                  fontWeight={timeRange === range ? 'bold' : 'normal'}
                  onClick={() => setTimeRange(range)}
                  _hover={{ color: 'white' }}
                  _focus={{ boxShadow: 'none' }}
                  bg={timeRange === range ? 'gray.600' : 'transparent'}
                  borderRadius="md"
                  px={3}
                >
                  {range}
                </Button>
              ))}
            </ButtonGroup>
          </Flex>
        </Flex>

        {/* Horizontal Divider */}
        {showSettings && <Divider orientation="horizontal" borderColor="gray.600" mb={4} />}

        {/* Collapsible Settings Menu */}
        {showSettings && (
          <Collapse in={showSettings} animateOpacity>
            <Grid templateColumns={columns} gap={4}>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Revenue</Text>
                <Switch
                  isChecked={showRevenue}
                  onChange={() => setShowRevenue(!showRevenue)}
                  colorScheme="teal"
                />
              </Flex>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Cost</Text>
                <Switch
                  isChecked={showCost}
                  onChange={() => setShowCost(!showCost)}
                  colorScheme="yellow"
                />
              </Flex>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Burn</Text>
                <Switch
                  isChecked={showBurn}
                  onChange={() => setShowBurn(!showBurn)}
                  colorScheme="red"
                />
              </Flex>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Cash</Text>
                <Switch
                  isChecked={showCash}
                  onChange={() => setShowCash(!showCash)}
                  colorScheme="cyan"
                />
              </Flex>
            </Grid>
          </Collapse>
        )}

      </Flex>
      <Line data={chartData} options={chartOptions} />
    </Box>
  );
};

export default FinancialsGrowthChart;
