import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../constants';

const withUserProfileCheck = (WrappedComponent) => {
  const UserProfileCheckHOC = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const checkUserProfile = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            navigate('/login');
            return;
          }

          // Check if user has auditId or reportId
          const response = await axios.get(`${API_URL}/auth/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const { auditId, reportId } = response.data;

          if (auditId || reportId) {
            // Redirect based on the available information
            if (reportId) {
              navigate(`/report/v2/${reportId}`);
            } else if (auditId) {
              navigate(`/audit-completed-v2/${auditId}`);
            }
          }
        } catch (error) {
          console.error('Error checking user profile:', error);
          navigate('/login');
        }
      };

      checkUserProfile();
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return UserProfileCheckHOC;
};

export default withUserProfileCheck;
