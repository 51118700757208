import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, Tooltip, Divider, Flex } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const ProfitabilityTrajectory = ({ reportData }) => {
  const [projectedDays, setProjectedDays] = useState(0);
  const [latestRevenue, setLatestRevenue] = useState(0);
  const [latestBurn, setLatestBurn] = useState(0);

  useEffect(() => {
    const storedReportData = JSON.parse(localStorage.getItem('reportData'));

    if (storedReportData && storedReportData.timeSeriesData) {
      const { revenueHistory, burnHistory } = storedReportData.timeSeriesData;
      
      const latestRevenueEntry = revenueHistory?.[revenueHistory.length - 1]?.revenue || 0;
      const latestBurnEntry = burnHistory?.[burnHistory.length - 1]?.burn || 0;

      setLatestRevenue(latestRevenueEntry);
      setLatestBurn(latestBurnEntry);

      const projectedMonths = storedReportData.financialMetrics?.projectedMonths || 0;
      setProjectedDays(projectedMonths * 30);
    } else if (reportData) {
      const { revenueHistory, burnHistory } = reportData.timeSeriesData || {};
      
      const latestRevenueEntry = revenueHistory?.[revenueHistory.length - 1]?.revenue || 0;
      const latestBurnEntry = burnHistory?.[burnHistory.length - 1]?.burn || 0;

      setLatestRevenue(latestRevenueEntry);
      setLatestBurn(latestBurnEntry);

      const projectedMonths = reportData.financialMetrics?.projectedMonths || 0;
      setProjectedDays(projectedMonths * 30);
    }
  }, [reportData]);

  const monthlyBurn = latestBurn - latestRevenue;
  const estimatedProfit = latestRevenue >= latestBurn ? latestRevenue - latestBurn : (projectedDays / 30) * monthlyBurn;
  const formattedEstimatedProfit = (Math.abs(estimatedProfit) / 1000).toFixed(1) + 'K';

  const reachDate = new Date();
  reachDate.setDate(reachDate.getDate() + projectedDays);
  const reachMonth = reachDate.toLocaleString('default', { month: 'long' });

  const hasReachedProfitability = latestRevenue >= latestBurn;

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip 
          label="The Profitability Trajectory estimates days to reach profitability based on revenue and burn."
          aria-label="Profitability Trajectory tooltip"
        >
          <StatLabel color="gray.300" cursor="help">
            Profitability Trajectory
          </StatLabel>
        </Tooltip>

        <Flex alignItems="center" justifyContent="left" mt={1}>
          {hasReachedProfitability && (
            <CheckCircleIcon color="green.400" boxSize={6} mr={2} />
          )}
          <StatNumber color="white">
            {hasReachedProfitability ? 'Reached' : `${projectedDays} days`}
          </StatNumber>
        </Flex>

        <Divider mt={1} mb={2} borderColor="gray.600" />

        <Text fontSize="sm" color="gray.400">
          {hasReachedProfitability ? "Actual Profit:" : "Expected Profit:"} <Text as="span" color="white">${formattedEstimatedProfit}</Text>
        </Text>

        <Tooltip 
          label="This is the month in which you are projected to reach profitability."
          aria-label="Reach month tooltip"
        >
          <Text fontSize="sm" color="gray.400" mt={1} cursor="help">
            Reached in: <Text as="span" color="white">{reachMonth}</Text>
          </Text>
        </Tooltip>
      </Stat>
    </Box>
  );
};

export default ProfitabilityTrajectory;
