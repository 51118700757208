import React, { useMemo } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import TotalCustomers from './topMetrics/TotalCustomers';
import CustomerGrowthRate from './topMetrics/CustomerGrowthRate';
import PayingCustomersRatio from './topMetrics/PayingCustomersRatio';
import CustomerAcquisitionCost from './topMetrics/CustomerAcquisitionCost';
import CustomerLifetimeValue from './topMetrics/CustomerLifetimeValue';

const CustomersTopMetrics = () => {
  // Retrieve auditData and reportData from local storage
  const auditData = useMemo(() => JSON.parse(localStorage.getItem('auditData')), []);
  const reportData = useMemo(() => JSON.parse(localStorage.getItem('reportData')), []);

  console.log('Audit Data:', auditData);  // Debugging log
  console.log('Report Data:', reportData);  // Debugging log

  return (
    <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={6}>
      {/* Total Customers Metric */}
      <TotalCustomers reportData={reportData} />

      {/* Customer Growth Rate Metric */}
      <CustomerGrowthRate reportData={reportData} />

      {/* Paying Customers Ratio Metric */}
      <PayingCustomersRatio reportData={reportData} />

      {/* Customer Acquisition Cost Metric */}
      <CustomerAcquisitionCost reportData={reportData} />

      {/* Customer Lifetime Value Metric */}
      <CustomerLifetimeValue reportData={reportData} />
    </SimpleGrid>
  );
};

export default CustomersTopMetrics;
