// src/reportv2/team/topMetrics/TeamStrengthScore.js

import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, CircularProgress, CircularProgressLabel, Tooltip, Text } from '@chakra-ui/react';

const TeamStrengthScore = ({ score }) => {
  const [displayScore, setDisplayScore] = useState(0);

  useEffect(() => {
    let start = 0;
    const interval = setInterval(() => {
      start += 1;
      if (start >= Math.round(score)) {
        clearInterval(interval);
      }
      setDisplayScore(start);
    }, 20); // Animation interval for smoother score update
    return () => clearInterval(interval);
  }, [score]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md" textAlign="center">
      <Tooltip
        label={
          <>
            <Text fontWeight="bold">Team Strength Score</Text>
            <Text>Reflects the overall strength of the team based on experience, diverse skills, and collaboration.</Text>
            <Text mt={2} fontWeight="bold">Note:</Text>
            <Text fontSize="sm">
              A higher team strength score suggests better alignment, productivity, and ability to execute the company’s vision.
            </Text>
          </>
        }
        aria-label="Team Strength Score tooltip"
        placement="top"
        hasArrow
        bg="gray.700"
        color="white"
        p={3}
        borderRadius="md"
        fontSize="xs"
        textAlign="left"
      >
        <Stat>
          <StatLabel color="gray.300" mb={4} cursor="help">Team Strength Score</StatLabel>
          <CircularProgress
            value={displayScore}
            color={displayScore >= 70 ? 'green.400' : displayScore >= 50 ? 'yellow.400' : 'red.400'}
            trackColor="#1e293b"
            size="80px"
            thickness="10px"
          >
            <CircularProgressLabel color="white" fontSize="3xl" fontWeight="medium">
              {Math.round(displayScore)}
            </CircularProgressLabel>
          </CircularProgress>
        </Stat>
      </Tooltip>
    </Box>
  );
};

export default TeamStrengthScore;
