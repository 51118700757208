// auditQuestions.js

const auditQuestions = [
  // Company Information Section
  {
    id: 1,
    question: "What is your company name?",
    inputType: "text",
    fieldName: "companyName",
    validation: { required: true },
    section: "Company Information",
  },
  {
    id: 2,
    question: "Which industry does your company operate in?",
    inputType: "dropdown",
    fieldName: "industry",
    options: 'industries',
    validation: { required: true },
    section: "Company Information",
  },
  {
    id: 3,
    question: "Where is your company headquartered or operating from?",
    inputType: "dropdown",
    fieldName: "location",
    options: 'allCountries',
    validation: { required: true },
    section: "Company Information",
  },
  {
    id: 4,
    question: "What year was your company founded?",
    inputType: "year-dropdown",
    fieldName: "foundingYear",
    validation: { required: true },
    section: "Company Information",
  },
  {
    id: 5,
    question: "How many founders does your company have?",
    inputType: "founder-dropdown",
    fieldName: "numberOfFounders",
    validation: { required: true },
    section: "Company Information",
  },
  // Funding and Financial Information Section
  {
    id: 6,
    question: "What stage of investment is your company currently in?",
    inputType: "dropdown",
    fieldName: "investmentStage",
    options: 'investmentStages',
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 7,
    question: "How much funding are you seeking in your next round?",
    inputType: "formatted-number",
    fieldName: "fundingGoal",
    validation: { required: true },
    format: "currency",  // Add format type for 1000 separators and currency symbol
    section: "Funding and Financial Information",
  },
  {
    id: 8,
    question: "How much funding have you raised so far?",
    inputType: "formatted-number",
    fieldName: "currentFunding",
    validation: { required: false },
    format: "currency",  // Add format type for 1000 separators and currency symbol
    section: "Funding and Financial Information",
  },
  {
    id: 9,
    question: "What was your company’s revenue last month?",
    inputType: "formatted-number",
    fieldName: "revenueLastMonth",
    validation: { required: false },
    format: "currency",  // Add format type for 1000 separators and currency symbol
    section: "Funding and Financial Information",
  },
  {
    id: 10,
    question: "How many paying customers do you currently have?",
    inputType: "dropdown",
    fieldName: "payingCustomers",
    options: ["0", "1-10", "11-50", "51-100", "100+"],
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  {
    id: 11,
    question: "What is your monthly burn rate?",
    inputType: "formatted-number",
    fieldName: "monthlyBurnRate",
    validation: { required: true },
    format: "currency",  // Add format type for 1000 separators and currency symbol
    section: "Funding and Financial Information",
  },
  {
    id: 12,
    question: "How many months of runway do you have left?",
    inputType: "dropdown",
    fieldName: "runway",
    options: ["0-3", "4-6", "7-12", "12+"],
    validation: { required: true },
    section: "Funding and Financial Information",
  },
  // Market and Customer Information Section
  {
    id: 13,
    question: "How large is your total addressable market (TAM)?",
    inputType: "dropdown",
    fieldName: "marketOpportunity",
    options: 'marketOpportunities',
    validation: { required: true },
    section: "Market and Customer Information",
  },
  {
    id: 14,
    question: "How many direct competitors do you have?",
    inputType: "dropdown",
    fieldName: "competitorLandscape",
    options: 'competitorLandscapes',
    validation: { required: true },
    section: "Market and Customer Information",
  },
  {
    id: 15,
    question: "Please provide the website URLs of your key competitors.",
    inputType: "multi-text",  // Allows multiple URLs
    fieldName: "competitorUrls",
    validation: { required: false },
    section: "Market and Customer Information",
  },
  {
    id: 16,
    question: "What are the primary target markets for your product?",
    inputType: "multi-select",
    fieldName: "targetMarket",
    options: 'regions',
    validation: { required: true },
    section: "Market and Customer Information",
  },
  {
    id: 17,
    question: "Who are your primary customers?",
    inputType: "dropdown",
    fieldName: "customerType",
    options: 'customerTypes',
    validation: { required: true },
    section: "Market and Customer Information",
  },
  {
    id: 18,
    question: "How do you collect and utilize customer feedback?",
    inputType: "dropdown",
    fieldName: "customerFeedback",
    options: ["Regular surveys", "One-on-one interviews", "Customer reviews", "Feedback loops in product"],
    validation: { required: false },
    section: "Market and Customer Information",
  },
  // Product and Innovation Section
  {
    id: 19,
    question: "What is your current product development stage?",
    inputType: "dropdown",
    fieldName: "productRoadmap",
    options: 'productRoadmaps',
    validation: { required: true },
    section: "Product and Innovation",
  },
  {
    id: 20,
    question: "How is your product differentiated from competitors?",
    inputType: "dropdown",
    fieldName: "productDifferentiation",
    options: ["Price", "Features", "Technology", "User Experience", "Other"],
    validation: { required: true },
    section: "Product and Innovation",
  },
  {
    id: 21,
    question: "What technology stack does your product use?",
    inputType: "multi-select",
    fieldName: "technologyStack",
    options: ["JavaScript", "Python", "Ruby", "Go", "Blockchain", "AI/ML", "Not relevant in my business"],
    validation: { required: true },
    section: "Product and Innovation",
  },
  {
    id: 22,
    question: "How reliant is your product on emerging or disruptive technologies?",
    inputType: "dropdown",
    fieldName: "techReliance",
    options: ["Not at all", "Somewhat", "Highly reliant"],
    validation: { required: false },
    section: "Product and Innovation",
  },
  // Go-to-Market (GTM) Strategy Section
  {
    id: 23,
    question: "What is your go-to-market strategy?",
    inputType: "dropdown",
    fieldName: "gtmStrategy",
    options: 'gtmStrategies',
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 24,
    question: "Which channels are you using to acquire customers?",
    inputType: "multi-select",
    fieldName: "customerAcquisitionChannels",
    options: ["SEO", "Paid Ads", "Content Marketing", "Partnerships", "Cold Outreach"],
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 25,
    question: "What is your pricing strategy?",
    inputType: "dropdown",
    fieldName: "pricingStrategy",
    options: ["Subscription", "Freemium", "Pay-per-use", "One-time fee"],
    validation: { required: true },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 26,
    question: "What is your current Customer Acquisition Cost (CAC)?",
    inputType: "dropdown",
    fieldName: "customerAcquisitionCost",
    options: ["<$50", "$50-$100", "$100-$500", "$500-$1000", "$1000+", "I don't know"],
    validation: { required: false },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 27,
    question: "What is your current Customer Lifetime Value (LTV)?",
    inputType: "dropdown",
    fieldName: "customerLifetimeValue",
    options: ["<$100", "$100-$500", "$500-$1000", "$1000-$5000", "$5000+", "I don't know"],
    validation: { required: false },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 28,
    question: "What is your customer retention rate?",
    inputType: "dropdown",
    fieldName: "customerRetentionRate",
    options: ["0-25%", "25-50%", "50-75%", "75%+", "I don't know"],
    validation: { required: false },
    section: "Go-to-Market (GTM) Strategy",
  },
  {
    id: 29,
    question: "What is your churn rate?",
    inputType: "dropdown",
    fieldName: "churnRate",
    options: ["0-5%", "5-10%", "10-20%", "20%+", "I don't know"],
    validation: { required: false },
    section: "Go-to-Market (GTM) Strategy",
  },
  // Founder and Team Experience Section
  {
    id: 30,
    question: "Have the founders had any previous successful startups or exits?",
    inputType: "dropdown",
    fieldName: "previousSuccess",
    options: ["Yes", "No", "In progress", "I don't know"],
    validation: { required: false },
    section: "Founder and Team Experience",
  },
  {
    id: 31,
    question: "Please provide details on the founders' previous successful startups or exits.",
    inputType: "textarea",
    fieldName: "successDetails",
    validation: { required: false },
    section: "Founder and Team Experience",
    conditional: { field: "previousSuccess", value: "Yes" }, 
  },
  {
    id: 32,
    question: "How many years of experience do the founders have in this industry?",
    inputType: "dropdown",
    fieldName: "founderExperience",
    options: ["0-2 years", "3-5 years", "6-10 years", "10+ years"],
    validation: { required: true },
    section: "Founder and Team Experience",
  },
  {
    id: 33,
    question: "What key achievements or milestones has your team accomplished so far?",
    inputType: "multi-select",
    fieldName: "milestones",
    options: ["Launched product", "Raised significant funding", "Secured strategic partnerships", "Won industry awards", "Expanded internationally"],
    validation: { required: false },
    section: "Founder and Team Experience",
  },
  {
    id: 34,
    question: "What is the composition of your team?",
    inputType: "dropdown",
    fieldName: "teamComposition",
    options: ["Mostly technical", "Mostly non-technical", "Balanced", "Other"],
    validation: { required: false },
    section: "Founder and Team Experience",
  },
  // Traction and Milestones Section
  {
    id: 35,
    question: "What is your user growth rate? (Monthly)",
    inputType: "dropdown",
    fieldName: "userGrowthRate",
    options: ["0-25%", "25-50%", "50-100%", "100%+"],
    validation: { required: true },
    section: "Traction and Milestones",
  },
  {
    id: 36,
    question: "What is your revenue growth rate? (Monthly)",
    inputType: "dropdown",
    fieldName: "revenueGrowthRate",
    options: ["0-25%", "25-50%", "50-100%", "100%+"],
    validation: { required: true },
    section: "Traction and Milestones",
  },
  {
    id: 37,
    question: "Which product adoption metrics do you track?",
    inputType: "multi-select",
    fieldName: "adoptionMetrics",
    options: ["Daily Active Users (DAU)", "Monthly Active Users (MAU)", "Churn Rate", "Retention Rate", "Customer Satisfaction (NPS)"],
    validation: { required: false },
    section: "Traction and Milestones",
  },
  // Risks and Challenges Section
  {
    id: 38,
    question: "What are the biggest risks to your business?",
    inputType: "multi-select",
    fieldName: "businessRisks",
    options: ["Market Competition", "Regulatory Issues", "Funding Challenges", "Product-Market Fit"],
    validation: { required: true },
    section: "Risks and Challenges",
  },
  {
    id: 39,
    question: "What operational challenges are you currently facing?",
    inputType: "multi-select",
    fieldName: "operationalChallenges",
    options: ["Hiring Talent", "Scaling Operations", "Customer Acquisition", "Supply Chain Issues"],
    validation: { required: true },
    section: "Risks and Challenges",
  },
  {
    id: 40,
    question: "Are there any significant regulatory challenges your business is facing?",
    inputType: "dropdown",
    fieldName: "regulatoryChallenges",
    options: ["Yes", "No"],
    validation: { required: false },
    section: "Risks and Challenges",
  },
  {
    id: 41,
    question: "Please provide details of any regulatory challenges.",
    inputType: "textarea",
    fieldName: "regulatoryDetails",
    validation: { required: true },
    section: "Risks and Challenges",
    conditional: { field: "regulatoryChallenges", value: "Yes" }, 
  },
  {
    id: 42,
    question: "Are there any external risks that could impact your business?",
    inputType: "multi-select",
    fieldName: "externalRisks",
    options: ["Economic downturn", "Political instability", "Market saturation", "Technological disruption"],
    validation: { required: false },
    section: "Risks and Challenges",
  },
  {
    id: 43,
    question: "How do you plan to allocate the funding you raise?",
    inputType: "multi-select",
    fieldName: "fundAllocation",
    options: ["R&D", "Marketing", "Sales", "Hiring", "Operations"],
    validation: { required: true },
    section: "Use of Funds",
  },
  {
    id: 44,
    question: "What is your exit strategy?",
    inputType: "dropdown",
    fieldName: "exitStrategy",
    options: ["Acquisition", "IPO", "Stay Private"],
    validation: { required: true },
    section: "Miscellaneous",
  },
];

export default auditQuestions;
