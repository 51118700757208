import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Switch,
  Grid,
  ButtonGroup,
  Button,
  Collapse,
  IconButton,
  Divider,
  useBreakpointValue,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, TimeScale);

const MarketGrowthChart = ({ auditData, reportData }) => {
  const [chartData, setChartData] = useState(null);
  const [showCustomers, setShowCustomers] = useState(true);
  const [showCompetitors, setShowCompetitors] = useState(false);
  const [timeRange, setTimeRange] = useState('ALL');
  const [availableRanges, setAvailableRanges] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const columns = useBreakpointValue({ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' });

  const foundingDate = auditData?.foundingDate || '2021-06';

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const foundingYear = parseInt(foundingDate.split('-')[0]);
    const yearsSinceFounding = currentYear - foundingYear;

    const ranges = ['3M', '6M', 'YTD'];
    if (yearsSinceFounding >= 1) ranges.push('1Y');
    if (yearsSinceFounding >= 2) ranges.push('2Y');
    if (yearsSinceFounding >= 5) ranges.push('5Y');
    ranges.push('ALL');

    setAvailableRanges(ranges);
  }, [foundingDate]);

  const getStartDateForRange = () => {
    const today = new Date();
    let startDate;
    switch (timeRange) {
      case '3M':
        startDate = new Date(today.setMonth(today.getMonth() - 3));
        break;
      case '6M':
        startDate = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case 'YTD':
        startDate = new Date(today.getFullYear(), 0, 1);
        break;
      case '1Y':
        startDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case '2Y':
        startDate = new Date(today.setFullYear(today.getFullYear() - 2));
        break;
      case '5Y':
        startDate = new Date(today.setFullYear(today.getFullYear() - 5));
        break;
      case 'ALL':
        startDate = new Date(foundingDate);
        break;
      default:
        startDate = new Date(foundingDate);
    }
    return startDate;
  };

  const createSegmentedDataset = (label, data, color, yAxisID) => {
    const lastSolidPointIndex = data.findIndex((point) => point.y !== 0);
    return {
      label,
      data,
      borderColor: `rgba(${color}, 1)`,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverBorderWidth: 3,
      pointHoverRadius: 10, 
      hitRadius: 10,
      tension: 0.2,
      yAxisID,
      spanGaps: true,
      segment: {
        borderDash: (ctx) =>
          ctx.p0DataIndex < lastSolidPointIndex ? [5, 5] : [],
        borderColor: (ctx) =>
          ctx.p0DataIndex < lastSolidPointIndex
            ? 'rgba(128, 128, 128, 1)'
            : `rgba(${color}, 1)`,
        backgroundColor: (ctx) =>
          ctx.p0DataIndex >= lastSolidPointIndex
            ? createSolidLineGradient(ctx.chart.ctx, ctx.chart.chartArea, color)
            : 'transparent',
      },
      fill: true,
    };
  };

  const createSolidLineGradient = (ctx, chartArea, color) => {
    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
    gradient.addColorStop(0, `rgba(${color}, 0.3)`);
    gradient.addColorStop(1, `rgba(${color}, 0)`);
    return gradient;
  };

  useEffect(() => {
    const startDate = getStartDateForRange();
    const customerData = reportData?.timeSeriesData?.customerHistory || [];
    const competitorData = reportData?.timeSeriesData?.competitorHistory || [];

    const datasets = [];

    if (showCustomers) {
      const customerValues = customerData
        .filter((point) => new Date(point.date) >= startDate)
        .map((point) => ({ x: point.date, y: point.customers }));
      datasets.push(createSegmentedDataset('Customers', customerValues, '54, 162, 235', 'y-axis-numbers'));
    }
    if (showCompetitors) {
      const competitorValues = competitorData
        .filter((point) => new Date(point.date) >= startDate)
        .map((point) => ({ x: point.date, y: point.competitors }));
      datasets.push(createSegmentedDataset('Competitors', competitorValues, '255, 159, 64', 'y-axis-numbers'));
    }

    setChartData({ datasets });
  }, [reportData, showCustomers, showCompetitors, timeRange]);

  const chartOptions = {
    responsive: true,
    plugins: {
      tooltip: { mode: 'index', intersect: false },
      legend: { display: false },
    },
    scales: {
      x: {
        type: 'time',
        time: { unit: timeRange === 'ALL' ? 'year' : 'month', displayFormats: { month: 'yyyy-MM', year: 'yyyy' } },
        min: getStartDateForRange(),
        grid: { color: 'rgba(75, 85, 99, 0.3)' },
      },
      'y-axis-numbers': {
        type: 'linear',
        position: 'left',
        ticks: {
          color: '#D1D5DB',
          callback: (value) => value.toLocaleString(),
        },
        grid: { color: 'rgba(75, 85, 99, 0.3)' },
        beginAtZero: true,
      },
    },
  };

  if (!chartData) return <Text color="red.500">No data available.</Text>;

  return (
    <Box mb={12} p={4} bg="rgba(15, 23, 42, 0.4)" borderRadius="md" mt={8}>
      <Flex direction="column" mb={4}>
        <Flex align="center" justify="space-between" mb={2}>
          <IconButton
            icon={<SettingsIcon />}
            onClick={() => setShowSettings(!showSettings)}
            variant="ghost"
            size="lg"
            color="gray.400"
            _hover={{ color: 'white' }}
            aria-label="Toggle settings"
          />
          <Flex overflowX="auto" whiteSpace="nowrap" alignItems="center" gap={2}>
            <ButtonGroup size="sm">
              {availableRanges.map((range) => (
                <Button
                  key={range}
                  variant="unstyled"
                  color={timeRange === range ? 'white' : 'gray.400'}
                  fontWeight={timeRange === range ? 'bold' : 'normal'}
                  onClick={() => setTimeRange(range)}
                  _hover={{ color: 'white' }}
                  _focus={{ boxShadow: 'none' }}
                  bg={timeRange === range ? 'gray.600' : 'transparent'}
                  borderRadius="md"
                  px={3}
                >
                  {range}
                </Button>
              ))}
            </ButtonGroup>
          </Flex>
        </Flex>

        {/* Horizontal Divider */}
        {showSettings && <Divider orientation="horizontal" borderColor="gray.600" mb={4} />}

        {/* Collapsible Settings Menu */}
        {showSettings && (
          <Collapse in={showSettings} animateOpacity>
            <Grid templateColumns={columns} gap={4}>
              <Flex align="center">
                <Text mr={2} color="gray.300" fontSize="sm">Customers</Text>
                <Switch
                  isChecked={showCustomers}
                  onChange={() => setShowCustomers(!showCustomers)}
                  colorScheme="blue"
                />
              </Flex>
            </Grid>
          </Collapse>
        )}
      </Flex>
      <Line data={chartData} options={chartOptions} />
    </Box>
  );
};

export default MarketGrowthChart
