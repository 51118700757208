import React, { useState, useEffect } from 'react';
import withUserProfileCheck from '../hoc/withUserProfileCheck';
import axios from 'axios';
import { API_URL } from '../constants';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from '../context/AuthContext';
import {
  Box,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  SimpleGrid,
  Heading,
  Divider,
  InputGroup,
} from '@chakra-ui/react';

const AccountPage = () => {
  const [user, setUser] = useState({
    email: '',
    name: '',
    linkedIn: '',
  });
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { token, refreshAccessToken } = useAuth();

  useEffect(() => {
    const initializeUserData = async () => {
      if (token) {
        const decodedToken = jwtDecode(token);
        setUser((prevUser) => ({
          ...prevUser,
          email: decodedToken.email || '',
        }));
      }

      await refreshAccessToken();
      try {
        const response = await axios.get(`${API_URL}/users/account`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser((prevUser) => ({
          ...prevUser,
          name: response.data.realName || '',
          linkedIn: response.data.linkedinProfile || '',
        }));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    initializeUserData();
  }, [token, refreshAccessToken]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await refreshAccessToken();
      await axios.put(
        `${API_URL}/users/account`,
        {
          email: user.email,
          oldPassword: oldPassword || '',
          password: newPassword || '',
          realName: user.name,
          linkedinProfile: user.linkedIn,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSuccess('Account updated successfully');
      setError('');
      setOldPassword('');
      setNewPassword('');
    } catch (error) {
      console.error('Error updating account:', error);
      setError('Error updating account');
      setSuccess('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box maxW="4xl" mx="auto" p={{ base: 4, md: 6 }} bg="#1e293b" rounded="lg">
      <Heading mb={6} color="gray.300" fontSize={{ base: '2xl', md: '3xl' }}>
        Account Settings
      </Heading>
      <form onSubmit={handleUpdate}>
        <VStack spacing={6} align="stretch">
          {/* Section 1: Account Information */}
          <Box p={4} bg="#0f172a" rounded="md">
            <Text fontSize="lg" fontWeight="bold" mb={4} color="gray.300">
              Account Information
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
              <FormControl>
                <FormLabel htmlFor="name" color="gray.400">
                  Name
                </FormLabel>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={user.name || ''}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  bg="gray.700"
                  borderColor="gray.600"
                  color="white"
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="linkedIn" color="gray.400">
                  LinkedIn Profile
                </FormLabel>
                <Input
                  type="url"
                  name="linkedIn"
                  id="linkedIn"
                  value={user.linkedIn || ''}
                  onChange={(e) => setUser({ ...user, linkedIn: e.target.value })}
                  bg="gray.700"
                  borderColor="gray.600"
                  color="white"
                />
              </FormControl>
            </SimpleGrid>

            <FormControl mt={4}>
              <FormLabel htmlFor="email" color="gray.400">
                Email
              </FormLabel>
              <Input
                type="email"
                name="email"
                id="email"
                value={user.email || ''}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                bg="gray.700"
                borderColor="gray.600"
                color="white"
              />
            </FormControl>
          </Box>

          <Divider borderColor="gray.600" />

          {/* Section 2: Update Password */}
          <Box p={4} bg="#0f172a" rounded="md">
            <Text fontSize="lg" fontWeight="bold" mb={4} color="gray.300">
              Update Password
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
              <FormControl>
                <FormLabel htmlFor="old-password" color="gray.400">
                  Old Password
                </FormLabel>
                <Input
                  type="password"
                  name="oldPassword"
                  id="old-password"
                  value={oldPassword || ''}
                  onChange={(e) => setOldPassword(e.target.value)}
                  bg="gray.700"
                  borderColor="gray.600"
                  color="white"
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="new-password" color="gray.400">
                  New Password
                </FormLabel>
                <Input
                  type="password"
                  name="newPassword"
                  id="new-password"
                  value={newPassword || ''}
                  onChange={(e) => setNewPassword(e.target.value)}
                  bg="gray.700"
                  borderColor="gray.600"
                  color="white"
                />
              </FormControl>
            </SimpleGrid>
          </Box>

          {/* Error and Success Messages */}
          <Box>
            {error && <Text color="red.500">{error}</Text>}
            {success && <Text color="green.500">{success}</Text>}
          </Box>

          {/* Submit Button */}
          <Button
            type="submit"
            bg="#16a34a"
            color="white"
            _hover={{ bg: '#15803d' }}
            _active={{ bg: '#166534' }}
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Update Account
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default withUserProfileCheck(AccountPage);
