import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { VStack, Text, CircularProgress } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../constants';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const AuditCompletedV2 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reportStatus, setReportStatus] = useState(null);

  const { token, refreshAccessToken, handleLogout } = useAuth();
  const headers = useMemo(() => ({ Authorization: `Bearer ${token}` }), [token]);

  const fetchStatusAndRedirect = useCallback(async () => {
    try {
      await refreshAccessToken();
      const userId = localStorage.getItem('userId');
      const startupId = localStorage.getItem('startupId');
      const response = await axios.get(`${API_URL}/status/${userId}/${startupId}`, { headers });
      const statusData = response.data;

      // Store auditId and reportId in local storage if missing
      if (statusData.auditId && !localStorage.getItem('auditId')) {
        localStorage.setItem('auditId', statusData.auditId);
      }
      if (statusData.reportId && !localStorage.getItem('reportId')) {
        localStorage.setItem('reportId', statusData.reportId);
      }

      // Update reportStatus state
      setReportStatus(statusData.reportStatus);

      // Redirect to report page if analysis is completed or failed
      if ((statusData.reportStatus === 'completed' || statusData.reportStatus === 'failed') && statusData.reportId) {
        navigate(`/report/v2/${statusData.reportId}`);
      }

      setLoading(false);
    } catch (err) {
      console.error('Error fetching status:', err);
      setError('Error processing your request. Please try again later.');
      setLoading(false);
      if (err.response?.status === 401) handleLogout();
    }
  }, [headers, navigate, refreshAccessToken, handleLogout]);

  useEffect(() => {
    fetchStatusAndRedirect();
  }, [fetchStatusAndRedirect]);

  // Polling logic to periodically recheck status
  useEffect(() => {
    if (reportStatus !== 'completed') {
      const interval = setInterval(() => {
        fetchStatusAndRedirect();
      }, 5000); // Poll every 5 seconds until completed

      return () => clearInterval(interval);
    }
  }, [reportStatus, fetchStatusAndRedirect]);

  if (error) {
    return (
      <VStack spacing={6} align="center" justify="center" bg="#1e293b" p={8} rounded="lg" minHeight="70vh">
        <Text fontSize="2xl" color="white">Error</Text>
        <Text color="gray.300">{error}</Text>
      </VStack>
    );
  }

  if (loading || reportStatus !== 'completed') {
    return (
      <VStack spacing={6} align="center" justify="center" bg="#1e293b" p={8} rounded="lg" minHeight="70vh">
        <Text fontSize="2xl" color="white">“Hold tight! Your report is brewing... ☕️”</Text>
        <Text color="gray.300">This is a strong brew, not instant coffee. It'll be worth the wait!</Text>
        <CircularProgress isIndeterminate color="green.300" size="120px" thickness="4px" />
      </VStack>
    );
  }

  return (
    <VStack spacing={6} align="center" justify="center" bg="#1e293b" p={8} rounded="lg" minHeight="70vh">
      <Text fontSize="2xl" color="white">Your report is ready. Let’s caffeinate those insights! ☕️</Text>
    </VStack>
  );
};

export default AuditCompletedV2;
