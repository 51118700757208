import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, SimpleGrid, Divider, Input, Button, AspectRatio, Highlight } from '@chakra-ui/react';

// Stop words to exclude from keyword detection
const stopWords = new Set([
  "the", "is", "and", "a", "an", "of", "to", "in", "for", "on", "with", "at", "by", "from", "or", "this", "that", "it",
  "as", "are", "was", "were", "be", "been", "being", "have", "has", "had", "do", "does", "did", "but", "so", "if",
  "they", "we", "you", "i", "he", "she", "my", "our", "your", "their", "its", "not", "can", "will", "all"
]);

// Extract keywords dynamically
const extractKeywords = (text) => {
  const wordCounts = {};
  const words = text
    .toLowerCase()
    .replace(/[^a-z\s]/g, '') // Remove punctuation
    .split(/\s+/); // Split into words

  words.forEach((word) => {
    if (!stopWords.has(word) && word.length > 2) {
      wordCounts[word] = (wordCounts[word] || 0) + 1;
    }
  });

  // Sort by frequency and return the top 5 keywords
  return Object.entries(wordCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(([word]) => word);
};

const ProductDescriptionSection = () => {
  const [structuredBenefits, setStructuredBenefits] = useState(null);
  const [productDescription, setProductDescription] = useState("");
  const [productBenefitsText, setProductBenefitsText] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [inputUrl, setInputUrl] = useState("");
  const [dynamicKeywords, setDynamicKeywords] = useState([]);

  // Fetch data from localStorage
  useEffect(() => {
    const auditData = JSON.parse(localStorage.getItem("auditData")) || {};
    const reportData = JSON.parse(localStorage.getItem("reportData")) || {};
    const storedVideoUrl = localStorage.getItem("productVideoUrl");

    // Set product description from auditData
    const description = auditData.productDescription || "Product description not available.";
    setProductDescription(description);

    // Extract dynamic keywords
    const keywords = extractKeywords(description);
    setDynamicKeywords(keywords);

    // Check if structured benefits are available in reportData
    if (reportData.chatGPTResponses?.product?.analysedProductBenefits) {
      setStructuredBenefits(reportData.chatGPTResponses.product.analysedProductBenefits);
    } else {
      // Fallback to using unstructured benefits text from auditData
      setProductBenefitsText(auditData.productBenefits || "Benefits not available.");
    }

    // Set video URL from localStorage or use a default sample video
    setVideoUrl(storedVideoUrl || "https://www.youtube.com/embed/GD8RUoOalBU?si=t6L_ZLwx33KFK-Op");
  }, []);

  // Convert regular YouTube URLs to embed format if needed
  const convertToEmbedUrl = (url) => {
    const embedUrl = url.replace("watch?v=", "embed/");
    return embedUrl.includes("youtube.com/embed") ? embedUrl : "https://www.youtube.com/embed/y2x4hfgkRNA";
  };

  const handleSaveUrl = () => {
    const formattedUrl = convertToEmbedUrl(inputUrl);
    setVideoUrl(formattedUrl);
    localStorage.setItem("productVideoUrl", formattedUrl);
    setEditMode(false);
  };

  return (
    <Box p={6} bg="rgba(28, 35, 54)" borderRadius="md">
      <VStack align="start" spacing={4}>
        <Text fontSize="xl" color="gray.300" fontWeight="bold">Product Overview</Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} w="full">
          <Box>
            {editMode ? (
              <VStack align="start" spacing={2}>
                <Input
                  placeholder="Enter YouTube video URL"
                  value={inputUrl}
                  onChange={(e) => setInputUrl(e.target.value)}
                  color="white"
                />
                <Button onClick={handleSaveUrl} colorScheme="teal">Save Video</Button>
              </VStack>
            ) : (
              <AspectRatio ratio={16 / 9}>
                <iframe
                  src={videoUrl}
                  title="Product Video"
                  allowFullScreen
                />
              </AspectRatio>
            )}
            <Button
              mt={2}
              size="sm"
              onClick={() => setEditMode(!editMode)}
              colorScheme="teal"
              variant="outline"
            >
              {editMode ? "Cancel" : "Change Video"}
            </Button>
          </Box>

          <Text fontSize="md" color="white" lineHeight="1.6">
            <Highlight
              query={dynamicKeywords}
              styles={{ px: "1", py: "1", bg: "rgba(56, 178, 172, 0.3)", color: "white", fontWeight: "bold" }}
            >
              {productDescription}
            </Highlight>
          </Text>
        </SimpleGrid>

        <Divider borderColor="gray.600" my={2} />

        <Text fontSize="lg" color="gray.300" fontWeight="bold" mt={0}>Key Benefits</Text>

        {structuredBenefits ? (
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} w="full">
            {structuredBenefits.map((benefit, index) => (
              <VStack key={index} align="start" spacing={1}>
                <Text fontSize="md" color="teal.300" fontWeight="bold">{benefit.title}</Text>
                <Text fontSize="sm" color="gray.300" lineHeight="1.4">{benefit.description}</Text>
              </VStack>
            ))}
          </SimpleGrid>
        ) : (
          <Text fontSize="md" color="gray.300" lineHeight="1.6" mt={2}>
            {productBenefitsText}
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default ProductDescriptionSection;
