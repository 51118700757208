import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Text,
  VStack,
  CircularProgress,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabIndicator,
  Tooltip,
  IconButton,
  Flex,
  Button,
} from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../constants';
import TopMetrics from './reportv2/TopMetrics';
import GrowthChart from './reportv2/GrowthChart';
import FinancialsSection from './reportv2/FinancialsSection';
import MarketSection from './reportv2/MarketSection';
import CustomersSection from './reportv2/CustomersSection';
import ProductSection from './reportv2/ProductSection';
import TeamSection from './reportv2/TeamSection';
import Recommendations from './reportv2/shared/Recommendations';
import GoodBadAnalysis from './reportv2/shared/GoodBadAnalysis';
import GenerateAnalysisButton from './reportv2/shared/GenerateAnalysisButton';
import StartupDescription from './reportv2/shared/StartupInfo';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';

const ReportV2 = () => {
  const navigate = useNavigate();
  const { token, refreshAccessToken } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [auditData, setAuditData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [showAnalysisSections, setShowAnalysisSections] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const startupId = localStorage.getItem('startupId');
      const userId = localStorage.getItem('userId');

      const statusResponse = await axios.get(`${API_URL}/status/${userId}/${startupId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const statusData = statusResponse.data;
      const { auditId, reportId, modifiedAt, reportAnalysis, reportStatus } = statusData || {};

      if (reportAnalysis === 'completed' || localStorage.getItem('reportAnalysis') === 'completed') {
        localStorage.setItem('reportAnalysis', 'completed');
        setShowAnalysisSections(true);
      } else {
        localStorage.setItem('reportAnalysis', reportAnalysis);
        setShowAnalysisSections(reportAnalysis === 'completed');
      }

      localStorage.setItem('auditId', auditId);
      localStorage.setItem('reportId', reportId);
      localStorage.setItem('reportStatus', reportStatus);

      if (reportStatus !== 'completed' || !auditId || !reportId) {
        navigate('/audit-completed');
        return;
      }

      const auditResponse = await axios.get(`${API_URL}/v2/audits/${auditId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const audit = auditResponse.data;
      localStorage.setItem('auditData', JSON.stringify(audit));
      setAuditData(audit);

      const reportResponse = await axios.get(`${API_URL}/v2/reports/${reportId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const report = reportResponse.data;
      localStorage.setItem('reportData', JSON.stringify(report));
      setReportData(report);

      setShortDescription(report.startupDescriptionShort || 'No short description available');
      setLongDescription(report.startupDescriptionLong || 'No long description available');

      if (modifiedAt) {
        localStorage.setItem('modifiedAt', modifiedAt);
        setLastUpdated(format(new Date(modifiedAt), 'PPP p'));
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data. Please try again later.');
      setLoading(false);

      if (error.response && error.response.status === 401) {
        await refreshAccessToken();
      }
    }
  }, [token, refreshAccessToken, navigate]);

  const updateReportData = useCallback(async () => {
    const reportId = localStorage.getItem('reportId');
    try {
      const response = await axios.get(`${API_URL}/v2/reports/${reportId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const updatedReport = response.data;
      localStorage.setItem('reportData', JSON.stringify(updatedReport));
      setReportData(updatedReport);

      localStorage.setItem('reportAnalysis', 'completed');
      setShowAnalysisSections(true);
    } catch (error) {
      console.error('Error updating report data:', error);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <VStack
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        spacing={6}
        align="center"
        justify="center"
        p={8}
        minHeight="70vh"
      >
        <Text fontSize="2xl" color="blue.400">
          Analyzing
        </Text>
        <CircularProgress isIndeterminate color="blue.400" trackColor="#1e293b" size="120px" />
      </VStack>
    );
  }

  if (error) {
    return (
      <VStack
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        spacing={6}
        align="center"
        justify="center"
        p={8}
        minHeight="70vh"
      >
        <Text fontSize="2xl">Error</Text>
        <Text color="gray.300">{error}</Text>
        <Button colorScheme="red" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </VStack>
    );
  }

  const renderAnalysisSection = (section) => {
    const analysisData = reportData?.chatGPTResponses?.[section];
    return analysisData ? (
      <VStack
        spacing={0}
        align="stretch"
        p={{ base: 2, md: 1 }}
        bg="#1e293b"
        borderRadius="lg"
      >
        <GoodBadAnalysis
          auditData={auditData}
          reportData={reportData}
          section={section}
        />
        <Recommendations
          auditData={auditData}
          reportData={reportData}
          section={section}
        />
      </VStack>
    ) : (
      <VStack
        spacing={4}
        mt={6}
        mb={6}
        align="center"
        justify="center"
        bg="#1e293b"
        borderRadius="lg"
        p={{ base: 2, md: 4 }}
      >
        <Text fontSize={{ base: "md", md: "lg" }} color="gray.400">
          {`${section.charAt(0).toUpperCase() + section.slice(1)} analysis is being generated...`}
        </Text>
      </VStack>
    );
  };  

  return (
    <Box
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      maxW={{ base: "100%", md: "7xl" }}
      mx="auto"
      p={{ base: 0, md: 6 }}
      bg="#1e293b"
      rounded="lg"
      minHeight="100vh"
    >
      <Tabs position="relative" variant="unstyled">
        {/* Section Navigation and Secondary Menu for Desktop */}
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={4}
          position="sticky"
          top="0"
          zIndex="sticky"
          bg="#1e293b 0.7"
          overflowX="auto"
          overflowY="hidden"
          whiteSpace="nowrap"
          css={{ '::-webkit-scrollbar': { display: 'none' } }}
        >
          {/* Unified Section Navigation */}
          <TabList
            display="flex"
            gap={4}
            flexGrow={1}
            overflowX="auto"
            overflowY="hidden"
            whiteSpace="nowrap"
            css={{ '::-webkit-scrollbar': { display: 'none' } }}
            position="relative"
          >
            <Tab
              _selected={{ color: "white", fontWeight: "bold" }}
              color="gray.400"
              fontSize={{ base: "md", md: "lg" }}
              px={4}
            >
              Investment
            </Tab>
            <Tab
              _selected={{ color: "white", fontWeight: "bold" }}
              color="gray.400"
              fontSize={{ base: "md", md: "lg" }}
              px={4}
            >
              Financials
            </Tab>
            <Tab
              _selected={{ color: "white", fontWeight: "bold" }}
              color="gray.400"
              fontSize={{ base: "md", md: "lg" }}
              px={4}
            >
              Market
            </Tab>
            <Tab
              _selected={{ color: "white", fontWeight: "bold" }}
              color="gray.400"
              fontSize={{ base: "md", md: "lg" }}
              px={4}
            >
              Customers
            </Tab>
            <Tab
              _selected={{ color: "white", fontWeight: "bold" }}
              color="gray.400"
              fontSize={{ base: "md", md: "lg" }}
              px={4}
            >
              Product
            </Tab>
            <Tab
              _selected={{ color: "white", fontWeight: "bold" }}
              color="gray.400"
              fontSize={{ base: "md", md: "lg" }}
              px={4}
            >
              Team
            </Tab>
            <TabIndicator
              mt="-2px"
              height="2px"
              bg="blue.500"
              borderRadius="1px"
              position="absolute"
              bottom="0"
            />
          </TabList>
  
          {/* Secondary Menu */}
          <Flex
            display={{ base: "none", md: "flex" }}
            alignItems="center"
            gap={4}
            pr={4}
            overflow="visible"
          >
            <GenerateAnalysisButton reportId={reportData?._id} onUpdateReport={updateReportData} />
            <StartupDescription shortDescription={shortDescription} longDescription={longDescription} />
            <Tooltip
              label={`Last updated: ${lastUpdated || "N/A"}`}
              aria-label="Last updated tooltip"
              bg="gray.700"
              color="white"
              p={2}
              borderRadius="md"
            >
              <IconButton
                aria-label="Last updated"
                icon={<TimeIcon />}
                variant="ghost"
                color="gray.400"
                size="sm"
                _hover={{ color: 'white' }}
              />
            </Tooltip>
          </Flex>
        </Flex>
  
        {/* Secondary Menu for Mobile */}
        <Flex
          display={{ base: "flex", md: "none" }}
          position="fixed"
          bottom={75}
          right={2}
          direction="column"
          alignItems="center"
          gap={4}
          zIndex="tooltip"
        >
          <GenerateAnalysisButton reportId={reportData?._id} onUpdateReport={updateReportData} />
          <StartupDescription shortDescription={shortDescription} longDescription={longDescription} />
          <Tooltip
            label={`Last updated: ${lastUpdated || "N/A"}`}
            aria-label="Last updated tooltip"
            bg="gray.700"
            color="white"
            p={2}
            borderRadius="md"
          >
            <IconButton
              aria-label="Last updated"
              icon={<TimeIcon />}
              variant="ghost"
              color="gray.400"
              size="md"
              _hover={{ color: 'white' }}
            />
          </Tooltip>
        </Flex>
  
        {/* Main Tab Panels */}
        <Box overflowY="auto" flex="1" mb={2}>
          <TabPanels>
            <TabPanel>
              <TopMetrics auditData={auditData} reportData={reportData} />
              <GrowthChart auditData={auditData} reportData={reportData} />
              {showAnalysisSections && renderAnalysisSection("investment")}
            </TabPanel>
            <TabPanel>
              <FinancialsSection auditData={auditData} reportData={reportData} />
              {showAnalysisSections && renderAnalysisSection("financials")}
            </TabPanel>
            <TabPanel>
              <MarketSection auditData={auditData} reportData={reportData} />
              {showAnalysisSections && renderAnalysisSection("market")}
            </TabPanel>
            <TabPanel>
              <CustomersSection auditData={auditData} reportData={reportData} />
              {showAnalysisSections && renderAnalysisSection("customers")}
            </TabPanel>
            <TabPanel>
              <ProductSection auditData={auditData} reportData={reportData} />
              {showAnalysisSections && renderAnalysisSection("product")}
            </TabPanel>
            <TabPanel>
              <TeamSection auditData={auditData} reportData={reportData} />
              {showAnalysisSections && renderAnalysisSection("team")}
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </Box>
  );  
};

export default ReportV2;
