// src/reportv2/team/topMetrics/DiverseSkillsScore.js

import React from 'react';
import { Box, Stat, StatLabel, StatNumber, Tooltip, Text } from '@chakra-ui/react';

const DiverseSkillsScore = ({ score }) => (
  <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
    <Stat>
      <Tooltip
        label={<Text>Assesses the diversity of skills within the team.</Text>}
        aria-label="Diverse Skills Score tooltip"
        placement="top"
        hasArrow
        bg="gray.700"
        color="white"
        p={3}
        borderRadius="md"
        fontSize="xs"
      >
        <StatLabel color="gray.300" cursor="help">Diverse Skills Score</StatLabel>
      </Tooltip>
      <StatNumber color="white" fontSize="2xl">{score}</StatNumber>
    </Stat>
  </Box>
);

export default DiverseSkillsScore;
