// src/reportv2/product/topMetrics/ProductDifferentiation.js

import React from 'react';
import { Box, Stat, StatLabel, StatNumber, Tooltip, Text, Divider, VStack } from '@chakra-ui/react';

const ProductDifferentiation = ({ auditData }) => {
  const differentiationFactor = auditData?.productDifferentiation || "N/A";

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Product Differentiation</Text>
              <Text>Highlights the unique factors that distinguish this product in the market.</Text>
            </>
          }
          aria-label="Product Differentiation tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Product Differentiation</StatLabel>
        </Tooltip>

        <StatNumber color="white" fontSize="2xl" mt={0}>
          {differentiationFactor}
        </StatNumber>

        <Divider my={2} borderColor="gray.600" />

        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.400">
            Unique Selling Point:
          </Text>
          <Text fontSize="sm" color="white" mt={0}>
            {differentiationFactor !== "N/A" ? differentiationFactor : "Information not provided"}
          </Text>
        </VStack>
      </Stat>
    </Box>
  );
};

export default ProductDifferentiation;
