import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Progress, Divider, Flex, Tooltip } from '@chakra-ui/react';
import { TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons';

// Helper function to determine color based on ratio
const getColorScheme = (ratio) => {
  if (ratio < 40) return 'green';
  if (ratio >= 40 && ratio <= 60) return 'yellow';
  return 'red';
};

const BurnToRevenueRatio = () => {
  const [burnToRevenueRatio, setBurnToRevenueRatio] = useState(0);
  const [isIncreasing, setIsIncreasing] = useState(false);

  useEffect(() => {
    // Fetch report data from local storage
    const storedReportData = JSON.parse(localStorage.getItem('reportData'));

    if (storedReportData && storedReportData.timeSeriesData) {
      const { burnHistory, revenueHistory } = storedReportData.timeSeriesData;

      // Calculate the most recent burn-to-revenue ratio
      const latestBurn = burnHistory[burnHistory.length - 1]?.burn || 0;
      const latestRevenue = revenueHistory[revenueHistory.length - 1]?.revenue || 0;
      const prevBurn = burnHistory.length > 1 ? burnHistory[burnHistory.length - 2]?.burn : 0;
      const prevRevenue = revenueHistory.length > 1 ? revenueHistory[revenueHistory.length - 2]?.revenue : 0;

      // Calculate the ratio, ensuring no division by zero
      const currentRatio = latestRevenue > 0 ? (latestBurn / latestRevenue) * 100 : 0;
      setBurnToRevenueRatio(currentRatio);

      // Determine if the ratio is increasing
      const prevRatio = prevRevenue > 0 ? (prevBurn / prevRevenue) * 100 : 0;
      setIsIncreasing(currentRatio > prevRatio);
    }
  }, []);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        {/* Tooltip for the Burn-to-Revenue Ratio label */}
        <Tooltip 
          label="The Burn-to-Revenue Ratio indicates how much of your revenue is being spent. A lower ratio is healthier for your startup." 
          aria-label="Burn-to-Revenue Ratio tooltip"
        >
          <StatLabel color="gray.300" textAlign="left" cursor="help">
            Burn-to-Revenue Ratio
          </StatLabel>
        </Tooltip>

        {/* Progress bar with color zones */}
        <Progress
          value={burnToRevenueRatio}
          colorScheme={getColorScheme(burnToRevenueRatio)}
          bg="#1e293b"
          size="xxl"
          height="29px" // Maintain the larger progress bar size
          borderRadius="sm"
          mt={2}
        />

        <Divider mt={3} mb={2} borderColor="gray.600" />

        {/* Tooltip for the percentage value */}
        <Tooltip 
          label={`The Burn-to-Revenue Ratio is ${isIncreasing ? 'increasing' : 'decreasing'}.`} 
          aria-label="Burn-to-Revenue percentage tooltip"
        >
          <Flex justifyContent="center" alignItems="center" cursor="help">
            {isIncreasing ? (
              <TriangleUpIcon color="red.500" boxSize={5} mr={1} />
            ) : (
              <TriangleDownIcon color="green.500" boxSize={5} mr={1} />
            )}
            <StatNumber fontSize="2xl" color="white">
              {burnToRevenueRatio.toFixed(1)}%
            </StatNumber>
          </Flex>
        </Tooltip>
      </Stat>
    </Box>
  );
};

export default BurnToRevenueRatio;
