// src/components/AuditLandingPage.js
import React, { useState } from 'react';
import { VStack, Text, Button, Box, SimpleGrid, Icon, HStack, Checkbox, Flex } from '@chakra-ui/react';
import { CheckCircleIcon, StarIcon, TimeIcon } from '@chakra-ui/icons';
import { FaRobot, FaChartLine, FaUserShield } from 'react-icons/fa';

const AuditLandingPage = ({ onStartAudit }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleToggleTerms = () => {
    setTermsAccepted(!termsAccepted);
  };

  return (
    <VStack
      spacing={{ base: 6, md: 8 }}
      align="center"
      justify="center"
      bg="#1e293b"
      p={{ base: 4, md: 8 }}
      width={{ base: "95%", md: "80%" }}
      maxW="600px"
      mx="auto"
      borderRadius="lg"
      color="white"
    >
      <Text fontSize="4xl" fontWeight="bold" textAlign="center">Welcome to Your Startup Journey</Text>
      <Text color="gray.300" textAlign="center" maxWidth="800px" fontSize="lg">
        Discover invaluable insights to power your startup’s success. Our AI-driven audit reveals key metrics, strengths, and areas for growth, setting the stage for future investment opportunities.
      </Text>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} maxWidth="1000px" width="100%">
        <Box bg="rgba(28, 35, 54)" p={6} rounded="md" textAlign="center" boxShadow="lg">
          <Icon as={FaChartLine} color="green.400" boxSize={10} mb={4} />
          <Text fontSize="xl" fontWeight="bold">Financial & Market Readiness</Text>
          <Text color="gray.300" mt={2}>Gain insights into your startup's financial stability, market position, and growth potential to make data-backed decisions.</Text>
        </Box>

        <Box bg="rgba(28, 35, 54)" p={6} rounded="md" textAlign="center" boxShadow="lg">
          <Icon as={FaRobot} color="blue.400" boxSize={10} mb={4} />
          <Text fontSize="xl" fontWeight="bold">AI-Driven Recommendations</Text>
          <Text color="gray.300" mt={2}>Our advanced AI analyzes your inputs to generate actionable recommendations for immediate improvements and long-term growth.</Text>
        </Box>

        <Box bg="rgba(28, 35, 54)" p={6} rounded="md" textAlign="center" boxShadow="lg">
          <Icon as={FaUserShield} color="yellow.400" boxSize={10} mb={4} />
          <Text fontSize="xl" fontWeight="bold">Investor-Ready Scoring</Text>
          <Text color="gray.300" mt={2}>Understand your startup’s investment appeal through scores that highlight strengths, challenges, and investor interests.</Text>
        </Box>
      </SimpleGrid>

      <VStack spacing={4} align="center" maxWidth="800px" mt={6}>
        <HStack spacing={3}>
          <Icon as={CheckCircleIcon} color="green.400" boxSize={5} />
          <Text color="gray.300">Comprehensive assessment of financial health</Text>
        </HStack>
        <HStack spacing={3}>
          <Icon as={StarIcon} color="yellow.400" boxSize={5} />
          <Text color="gray.300">Actionable insights for strategic growth</Text>
        </HStack>
        <HStack spacing={3}>
          <Icon as={TimeIcon} color="blue.400" boxSize={5} />
          <Text color="gray.300">Easy-to-understand scoring for investors</Text>
        </HStack>
      </VStack>

      {/* Privacy and Terms Box */}
      <Flex
        align="center"
        justify="center"
        mt={8}
        p={6}  // Increased padding
        maxWidth="700px"
        borderWidth="1px"
        borderRadius="md"
        borderColor="gray.600"
        bg={termsAccepted ? "gray.700" : "gray.800"}
        _hover={{ bg: "gray.700", cursor: "pointer" }}
        onClick={handleToggleTerms}
      >
        <Checkbox
          size="lg"
          colorScheme="green"
          isChecked={termsAccepted}
          iconColor="white"
          borderColor="gray.600"
          pointerEvents="none"
        >
          <Text color="white" fontSize="md" ml={5} textAlign="left">
            I understand and agree that my data will be securely processed and will remain private. Your information <b><u>will not</u></b> be shared with any third parties.
          </Text>
        </Checkbox>
      </Flex>

      <Button onClick={onStartAudit} colorScheme="green" size="lg" mt={8} isDisabled={!termsAccepted}>
        Begin Your Journey
      </Button>
    </VStack>
  );
};

export default AuditLandingPage;
