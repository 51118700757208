import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, StatHelpText, StatArrow, Tooltip, Divider } from '@chakra-ui/react';

const ValuationMetric = ({ reportData }) => {
  const [valuationHistory, setValuationHistory] = useState([]);
  const [dcfValuation, setDcfValuation] = useState(0);

  useEffect(() => {
    // Attempt to use passed reportData prop first, fallback to local storage if not available
    const data = reportData || JSON.parse(localStorage.getItem('reportData'));

    if (data?.timeSeriesData) {
      setValuationHistory(data.timeSeriesData.valuationHistory || []);
      setDcfValuation(data.financialMetrics?.dcfValuation || 0);
    }
  }, [reportData]);

  // Determine initial and latest valuations for display
  const initialValuation = valuationHistory.length > 0 ? valuationHistory[0]?.valuation : 0;
  const latestValuation = valuationHistory.length > 0 
    ? valuationHistory[valuationHistory.length - 1]?.valuation 
    : dcfValuation;

  // Calculate percentage change in valuation (between last two points)
  const recentValuationChange = valuationHistory.length > 1
    ? ((latestValuation - valuationHistory[valuationHistory.length - 2]?.valuation) / 
      valuationHistory[valuationHistory.length - 2]?.valuation) * 100
    : 0;

  // Format valuations for display (in millions)
  const formattedDcfValuation = `$${(dcfValuation / 1000000).toFixed(2)}M`;
  const formattedLatestValuation = `$${(latestValuation / 1000000).toFixed(1)}M`;

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <StatLabel color="gray.300">Valuation</StatLabel>
        
        <Tooltip 
          label="DCF (Discounted Cash Flow) is a valuation method used to estimate the value of a company based on its future cash flows."
          aria-label="DCF valuation tooltip"
        >
          <StatNumber color="white" cursor="help">
            {formattedDcfValuation} <Text as="span" fontSize="sm" color="gray.400">(DCF)</Text>
          </StatNumber>
        </Tooltip>

        <Divider borderColor="gray.600" my={2} />

        {valuationHistory.length > 0 ? (
          <>
            <Tooltip 
              label="Last known valuation represents the latest recorded valuation from historical data."
              aria-label="Last known valuation tooltip"
            >
              <StatHelpText color="gray.400" cursor="help" display="inline">
                Last Known Valuation: <Text as="span" color="white">{formattedLatestValuation}</Text>
              </StatHelpText>
            </Tooltip>

            <StatHelpText display="inline" color="white">
              &nbsp;<StatArrow type={recentValuationChange >= 0 ? 'increase' : 'decrease'} />
              {Math.abs(recentValuationChange).toFixed(1)}%
            </StatHelpText>
          </>
        ) : (
          <Text color="gray.400" mt={2}>
            No historical valuation data available.
          </Text>
        )}
      </Stat>
    </Box>
  );
};

export default ValuationMetric;
