// src/reportv2/team/ProfilePromptModal.js

import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Avatar,
  VStack,
  Text,
  HStack,
  Textarea,
  Icon,
} from '@chakra-ui/react';
import { AiOutlineUpload, AiOutlineSend } from 'react-icons/ai';

const ProfilePromptModal = ({ isOpen, onClose, handleSubmitProfile }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent bg="gray.800" color="white">
        <ModalHeader>Upload Profile & Chat Prompt</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            {/* Photo Upload Section */}
            <Box
              textAlign="center"
              p={6}
              border="2px dashed rgba(255, 255, 255, 0.3)"
              borderRadius="md"
              bg="rgba(28, 35, 54, 0.6)"
            >
              <Avatar size="2xl" mb={4} />

              {/* Centered Upload Button */}
              <VStack spacing={2}>
                <Button
                  leftIcon={<Icon as={AiOutlineUpload} />}
                  colorScheme="green"
                  bg="#16a34a"
                  variant="solid"
                  _hover={{ bg: '#12833b' }}
                >
                  Upload Photo
                </Button>
                <Text fontSize="sm" color="gray.400">
                  Upload a profile picture (JPEG or PNG)
                </Text>
              </VStack>
            </Box>

            {/* Profile Information Input */}
            <Box>
              <Text fontSize="lg" mb={2} fontWeight="bold">
                Provide Profile Information
              </Text>
              <Text fontSize="sm" color="gray.400" mb={2}>
                Copy-paste or drop files with your CV details here.
              </Text>
              <Textarea
                placeholder="Paste or drop CV details or type summary here..."
                size="md"
                resize="vertical"
                bg="gray.700"
                color="white"
                borderRadius="md"
                _placeholder={{ color: 'gray.500' }}
              />
            </Box>

            {/* Mock Chat Interface */}
            <Box mt={4}>
              <Text fontSize="lg" mb={2} fontWeight="bold">
                Chat Preview
              </Text>
              <VStack spacing={3} align="stretch">
                <HStack bg="gray.700" p={4} borderRadius="md" justifyContent="space-between">
                  <Text color="gray.300">User:</Text>
                  <Text>Here is my experience in software development...</Text>
                </HStack>
                <HStack bg="gray.700" p={4} borderRadius="md" justifyContent="space-between">
                  <Text color="green.300">AI Assistant:</Text>
                  <Text>Thanks for sharing! Here’s a summary based on your input...</Text>
                </HStack>
              </VStack>
            </Box>

            {/* Action Buttons */}
            <HStack justify="end" mt={4}>
              <Button
                onClick={onClose}
                variant="solid"
                bg="gray.600"
                color="white"
                _hover={{ bg: 'gray.500' }}
                borderRadius="md"
              >
                Cancel
              </Button>
              <Button
                leftIcon={<Icon as={AiOutlineSend} />}
                bg="#16a34a"
                colorScheme="green"
                borderRadius="md"
                _hover={{ bg: '#12833b' }}
                onClick={() => {
                  handleSubmitProfile(); // Placeholder function to submit profile
                  onClose();
                }}
              >
                Submit Profile
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProfilePromptModal;
