import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { API_URL } from '../constants';

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);

  // Handle logout and selectively clear specific keys
  const handleLogout = useCallback((errorMessage = 'User logged out') => {
    setToken(null);

    // Preserve auditProgress
    const auditProgress = localStorage.getItem('auditProgress');

    // Remove specific keys from localStorage
    const keysToRemove = [
      'token',
      'refreshToken',
      'tokenExpiration',
      'userId',
      'startupId',
      'auditId',
      'reportId',
      'reportStatus',
      'reportAnalysis',
      'auditData',
      'reportData',
      'modifiedAt',
      'lastError',
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));

    // Restore auditProgress if it exists
    if (auditProgress) {
      localStorage.setItem('auditProgress', auditProgress);
    }

    // Store the last error message for debugging purposes
    localStorage.setItem('lastError', errorMessage);

    // Redirect to login page
    window.location.href = '/login';
  }, []);

  // Refresh token logic
  const refreshAccessToken = useCallback(async () => {
    const storedRefreshToken = localStorage.getItem('refreshToken');

    try {
      if (!storedRefreshToken) throw new Error('No refresh token available');

      const response = await axios.post(`${API_URL}/auth/refresh-token`, { refreshToken: storedRefreshToken });
      const newToken = response.data.token;
      const newRefreshToken = response.data.refreshToken;
      const decoded = jwtDecode(newToken);
      const expirationTime = decoded.exp * 1000;

      setToken(newToken);
      localStorage.setItem('token', newToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      localStorage.setItem('tokenExpiration', expirationTime);
    } catch (error) {
      console.error('Error refreshing token:', error?.response?.data?.message || error.message);

      // Retry after 2 seconds before logging out
      setTimeout(async () => {
        const retryStoredRefreshToken = localStorage.getItem('refreshToken');
        if (!retryStoredRefreshToken) return handleLogout("Retry failed; no refresh token available");

        try {
          const retryResponse = await axios.post(`${API_URL}/auth/refresh-token`, { refreshToken: retryStoredRefreshToken });
          const newToken = retryResponse.data.token;
          const newRefreshToken = retryResponse.data.refreshToken;
          const expirationTime = jwtDecode(newToken).exp * 1000;

          setToken(newToken);
          localStorage.setItem('token', newToken);
          localStorage.setItem('refreshToken', newRefreshToken);
          localStorage.setItem('tokenExpiration', expirationTime);
        } catch (retryError) {
          handleLogout(retryError?.response?.data?.message || retryError.message || 'Retry token refresh error');
        }
      }, 2000);
    }
  }, [handleLogout]);

  // Check and refresh token if needed
  const checkTokenExpirationAndRefresh = useCallback(() => {
    const expiration = parseInt(localStorage.getItem('tokenExpiration'), 10);
    if (expiration && Date.now() > expiration - 5 * 60 * 1000) {
      refreshAccessToken();
    }
  }, [refreshAccessToken]);

  // Initialize auth on load
  useEffect(() => {
    const initializeAuth = async () => {
      const storedExpiration = parseInt(localStorage.getItem('tokenExpiration'), 10);

      if (storedExpiration && Date.now() > storedExpiration) {
        handleLogout("Token expired on initial load");
      } else if (localStorage.getItem('refreshToken') && !token) {
        await refreshAccessToken();
      }
      setLoading(false);
    };

    initializeAuth();
    const interval = setInterval(checkTokenExpirationAndRefresh, 60000);
    return () => clearInterval(interval);
  }, [token, refreshAccessToken, handleLogout, checkTokenExpirationAndRefresh]);

  return (
    <AuthContext.Provider value={{ token, setToken, refreshAccessToken, handleLogout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
