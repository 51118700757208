import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, StatArrow, Tooltip, Text, Flex } from '@chakra-ui/react';

const RevenueGrowthRate = ({ reportData }) => {
  const [growthRate, setGrowthRate] = useState(0);

  useEffect(() => {
    // Attempt to fetch data from localStorage or fallback to provided reportData prop
    const storedReportData = JSON.parse(localStorage.getItem('reportData'));
    const finalReportData = reportData || storedReportData;

    if (finalReportData && finalReportData.timeSeriesData && finalReportData.timeSeriesData.revenueHistory) {
      const { revenueHistory } = finalReportData.timeSeriesData;

      // Ensure at least two entries to calculate the growth rate
      if (revenueHistory.length > 1) {
        const latestRevenue = revenueHistory[revenueHistory.length - 1]?.revenue || 0;
        const previousRevenue = revenueHistory[revenueHistory.length - 2]?.revenue || 0;

        // Calculate growth rate if previous revenue is available
        if (previousRevenue > 0) {
          const calculatedGrowthRate = ((latestRevenue - previousRevenue) / previousRevenue) * 100;
          setGrowthRate(calculatedGrowthRate);
        }
      }
    }
  }, [reportData]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md" textAlign="center">
      <Flex direction="column" alignItems="center" justifyContent="center" height="100%">
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Revenue Growth Rate</Text>
              <Text>Measures the percentage growth in revenue over a specific period, indicating financial momentum.</Text>
            </>
          }
          aria-label="Revenue growth rate tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <Stat>
            <StatLabel color="gray.300" textAlign="center" cursor="help">Revenue Growth Rate</StatLabel>
            <StatNumber fontSize="2xl" color="white" mt={8} textAlign="center">
              {growthRate >= 0 ? <StatArrow type="increase" /> : <StatArrow type="decrease" />}
              {Math.abs(growthRate).toFixed(1)}%
            </StatNumber>
          </Stat>
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default RevenueGrowthRate;
