import React, { useState } from 'react';
import { Box, Grid, VStack, Text, Avatar, IconButton, Badge, useDisclosure, Icon } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import ProfilePromptModal from './ProfilePromptModal';
import ProfileDetailsModal from './ProfileDetailsModal';

const TeamProfiles = ({ auditData }) => {
  const initialFounders = auditData?.numberOfFounders || 3;
  const [profileCount, setProfileCount] = useState(initialFounders);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onClose: onProfileClose,
  } = useDisclosure();
  const [isSampleProfileVisible, setSampleProfileVisible] = useState(true);

  const addProfile = () => setProfileCount(profileCount + 1);
  const removeSampleProfile = () => setSampleProfileVisible(false);

  const sampleProfile = {
    name: 'Jane Doe',
    role: 'CTO & Co-Founder',
    experience: '10+ years in AI & Software Development',
    profileImage: 'https://i.pravatar.cc/150?img=5',
  };

  return (
    <VStack spacing={4} align="stretch">
      <Grid templateColumns="repeat(auto-fit, minmax(150px, 1fr))" gap={6}>
        {isSampleProfileVisible && (
          <Box
            bg="rgba(28, 35, 54)"
            height="340px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            cursor="pointer"
            position="relative"
            onClick={onProfileOpen}
          >
            <Avatar src={sampleProfile.profileImage} size="4xl" mb={4} />
            <Text fontWeight="bold" color="white" mt={2}>
              {sampleProfile.name}
            </Text>
            <Text color="gray.400" mt={1}>
              {sampleProfile.role}
            </Text>

            <Box display="flex" alignItems="center" mt={2}>
              <Badge
                variant="outline"
                colorScheme="red"
                fontSize="xs"
                px={2}
                py={1}
                borderRadius="md"
              >
                Sample Founder
              </Badge>
              <IconButton
                aria-label="Remove sample profile"
                icon={<MinusIcon color="white" />}
                size="xs"
                bg="red.500"
                colorScheme="red"
                borderRadius="md"
                ml={2}
                _hover={{ bg: 'red.600' }}
                onClick={(e) => {
                  e.stopPropagation();
                  removeSampleProfile();
                }}
              />
            </Box>
          </Box>
        )}

        {/* Render Add Founder boxes based on profile count */}
        {[...Array(profileCount - (isSampleProfileVisible ? 1 : 0))].map((_, index) => (
          <Box
            key={index}
            bg="rgba(28, 35, 54)"
            height="340px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            cursor="pointer"
            onClick={onOpen}
          >
            <VStack spacing={3} align="center" bg="rgba(28, 35, 54 0.2)" borderRadius="md" p={4}>
              <Icon as={AddIcon} w={5} h={5} color="gray.400" />
              <Text color="gray.400">Add Founder</Text>
            </VStack>
          </Box>
        ))}
      </Grid>

      {/* Profile Prompt Modal */}
      <ProfilePromptModal isOpen={isOpen} onClose={onClose} />

      {/* Profile Details Modal for the prefilled profile */}
      <ProfileDetailsModal
        isOpen={isProfileOpen}
        onClose={onProfileClose}
        profile={sampleProfile} // Pass the prefilled profile data
      />
    </VStack>
  );
};

export default TeamProfiles;
