// src/reportv2/CustomersSection.js

import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import CustomersTopMetrics from './customers/CustomersTopMetrics';
import CustomersGrowthChart from './customers/CustomersGrowthChart';

const CustomersSection = ({ auditData, reportData }) => {
  return (
    <VStack spacing={0} align="stretch" mb={6}>
      {/* Top Metrics Section */}
      <Box>
        <CustomersTopMetrics auditData={auditData} reportData={reportData} />
      </Box>

      {/* Growth Chart Section */}
      <CustomersGrowthChart auditData={auditData} reportData={reportData} />
    </VStack>
  );
};

export default CustomersSection;
