// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { API_URL } from '../constants';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Image
} from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';
import BullRiseLogo from '../assets/bullrise-logo.svg';

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setToken } = useAuth(); // Use AuthContext to set token

  const updateStatusDocument = async (statusData, token) => {
    if (!statusData.reportAnalysis) {
      // Update status document to include reportAnalysis field set to "Pending"
      await axios.put(`${API_URL}/status/${statusData._id}`, { reportAnalysis: 'pending' }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      statusData.reportAnalysis = 'pending'; // Reflect this change locally as well
    }
    return statusData;
  };

  const updateLocalStorageAndNavigate = (token, userId, startupId, statusData) => {
    try {
      // Store relevant data in local storage
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('startupId', startupId);
      localStorage.setItem('auditId', statusData.auditId);
      localStorage.setItem('reportId', statusData.reportId);
      localStorage.setItem('reportStatus', statusData.reportStatus);
      localStorage.setItem('reportAnalysis', statusData.reportAnalysis);

      // Navigate based on report status
      if (statusData.reportStatus === 'completed') {
        navigate(`/report/v2/${statusData.reportId}`);
      } else if (['failed', 'fetched', 'processing'].includes(statusData.reportStatus)) {
        navigate(`/audit-completed-v2/${statusData.auditId}`);
      } else {
        navigate('/audit-form-v2');
      }
    } catch (error) {
      console.error('Error updating localStorage or navigating:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/auth/login`, formData);

      if (response.status === 200 && response.data.token && response.data.user) {
        const { token, refreshToken, user, status } = response.data;
        setToken(token); // Immediately set token in AuthContext
        localStorage.setItem('refreshToken', refreshToken);

        // Decode the token to store expiration time
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000;
        localStorage.setItem('tokenExpiration', expirationTime);

        // Fetch status and update if necessary, then navigate
        const statusResponse = await axios.get(`${API_URL}/status/${user.id}/${status.startupId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        let statusData = statusResponse.data;

        // Ensure `reportAnalysis` field is set in the status document
        statusData = await updateStatusDocument(statusData, token);
        updateLocalStorageAndNavigate(token, user.id, status.startupId, statusData);
      } else {
        setError('Login failed. Please check your credentials.');
      }
    } catch (error) {
      setError('Login failed. Please check your credentials.');
      console.error('Login error:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Box bg="#0f172a" minH="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={6}>
      <Image src={BullRiseLogo} alt="BullRise Logo" boxSize="40px" mb={8} />
      <Box maxW="lg" w="full" textAlign="center">
        <Text fontSize="3xl" fontWeight="bold" mb={6} color="white">Login</Text>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl isRequired>
              <FormLabel color="gray.300">Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                focusBorderColor="blue.500"
                bg="#1e293b"
                borderColor="gray.600"
                color="white"
                placeholder="Enter your email"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel color="gray.300">Password</FormLabel>
              <Input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                focusBorderColor="blue.500"
                bg="#1e293b"
                borderColor="gray.600"
                color="white"
                placeholder="Enter your password"
              />
            </FormControl>
            {error && <Text color="red.500" fontSize="sm">{error}</Text>}
            <Button
              type="submit"
              isLoading={loading}
              bg="#15803d"
              color="white"
              _hover={{ bg: "#166534" }}
              width="full"
              mt={4}
            >
              Login
            </Button>
          </VStack>
        </form>
        <Text mt={4} fontSize="sm" color="gray.300">
          Don't have an account?{' '}
          <Link to="/signup" style={{ color: '#60a5fa' }}>Sign up here</Link>.
        </Text>
      </Box>
    </Box>
  );
};

export default Login;
