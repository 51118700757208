// src/components/shared/StartupDescription.js

import React from 'react';
import { 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton, 
  ModalBody, 
  Text, 
  Box, 
  VStack, 
  HStack, 
  useDisclosure, 
  IconButton, 
  Tooltip, 
  Divider, 
  Icon 
} from '@chakra-ui/react';
import { InfoOutlineIcon, CheckIcon } from '@chakra-ui/icons';

const StartupDescription = ({ shortDescription, longDescription }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label="View Startup Description" aria-label="Startup description tooltip">
        <IconButton
          icon={<InfoOutlineIcon boxSize={4} />}
          onClick={onOpen}
          variant="ghost"
          color="gray.400"
          _hover={{ color: 'white' }}
          aria-label="Show Startup Description"
          size="sm"
          p={0}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white">
          <ModalHeader>Startup Analysis</ModalHeader>
          <ModalCloseButton color="gray.400" _hover={{ color: 'white' }} />
          <ModalBody pb={6}>
            <VStack spacing={6} align="stretch">

              {/* Key Highlights */}
              <Box>
                <Text fontSize="lg" fontWeight="bold" mb={3} color="gray.300">
                  Key Highlights
                </Text>
                <VStack align="stretch" spacing={3}>
                  <HStack spacing={3}>
                    <Box
                      bg="green.500"
                      w={6}
                      h={6}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="full"
                    >
                      <Icon as={CheckIcon} color="white" boxSize={3} />
                    </Box>
                    <Text color="gray.400">
                      Focuses on AI-driven solutions for agriculture and financial inclusion.
                    </Text>
                  </HStack>

                  <HStack spacing={3}>
                    <Box
                      bg="green.500"
                      w={6}
                      h={6}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="full"
                    >
                      <Icon as={CheckIcon} color="white" boxSize={3} />
                    </Box>
                    <Text color="gray.400">
                      Aims to address challenges in underserved markets.
                    </Text>
                  </HStack>

                  <HStack spacing={3}>
                    <Box
                      bg="green.500"
                      w={6}
                      h={6}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="full"
                    >
                      <Icon as={CheckIcon} color="white" boxSize={3} />
                    </Box>
                    <Text color="gray.400">
                      Leverages strategic partnerships to enhance impact.
                    </Text>
                  </HStack>
                </VStack>
              </Box>

              <Divider borderColor="gray.600" />

              {/* Company Overview */}
              <Box>
                <Text fontSize="lg" fontWeight="bold" mb={2} color="gray.300">
                  Company Overview
                </Text>
                <Text fontSize="md" color="gray.400">
                  {shortDescription}
                </Text>
              </Box>

              <Divider borderColor="gray.600" />

              {/* Detailed Analysis */}
              <Box>
                <Text fontSize="lg" fontWeight="bold" mb={2} color="gray.300">
                  Detailed Analysis
                </Text>
                <Text fontSize="md" color="gray.400">
                  {longDescription}
                </Text>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StartupDescription;
