// src/reportv2/TeamSection.js

import React from 'react';
import { Box, VStack, Heading, IconButton, useDisclosure } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import TeamTopMetrics from './team/TeamTopMetrics';
import TeamProfiles from './team/TeamProfiles';
import ProfilePromptModal from './team/ProfilePromptModal';

const TeamSection = ({ auditData, reportData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack spacing={6} align="stretch" mb={6}>
      {/* Top Metrics Section */}
      <Box>
        <TeamTopMetrics auditData={auditData} reportData={reportData} />
      </Box>

      {/* Team Profiles Section */}
      <Box>
        <Heading as="h3" size="md" color="gray.300" mb={4} display="flex" alignItems="center">
          Team Profiles
          <Box
            ml={2}
            p={1}
            bg="rgba(28, 35, 54)"
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ bg: 'gray.600' }}
          >
            <IconButton
              aria-label="Add more profiles"
              icon={<AddIcon />}
              size="xs"
              colorScheme="gray.300"
              variant="ghost"
              onClick={onOpen}
              borderRadius="full"
              _hover={{ bg: 'transparent' }}
            />
          </Box>
        </Heading>
        <TeamProfiles auditData={auditData} /> {/* Displaying profile grid based on number of founders */}
      </Box>

      {/* Profile Prompt Modal */}
      <ProfilePromptModal isOpen={isOpen} onClose={onClose} handleSubmitProfile={() => { /* Handle submission logic */ }} />
    </VStack>
  );
};

export default TeamSection;
