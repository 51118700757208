// src/reportv2/market/topMetrics/TargetMarketSize.js

import React from 'react';
import { Box, Stat, StatLabel, StatNumber, Tooltip, Text, Divider, VStack } from '@chakra-ui/react';

const TargetMarketSize = ({ auditData }) => {
  // Placeholder values for market size and region
  const marketSize = auditData?.marketOpportunity || '$1B+';
  const targetRegion = auditData?.targetMarket?.join(', ') || 'Global';

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Target Market Size</Text>
              <Text>This represents the estimated market size and regions you’re targeting.</Text>
            </>
          }
          aria-label="Target Market Size tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Target Market Size</StatLabel>
        </Tooltip>

        <StatNumber color="white" fontSize="2xl" mt={0}>
          {marketSize}
        </StatNumber>

        <Divider my={2} borderColor="gray.600" />

        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.400">
            Target Region:
          </Text>
          <Text fontSize="sm" color="white" mt={0}>
            {targetRegion}
          </Text>
        </VStack>
      </Stat>
    </Box>
  );
};

export default TargetMarketSize;
