import React from 'react';
import CoffeeLoader from './CoffeeLoader'; // Correct path to the component
import { Box, Button, VStack, FormControl, FormLabel, Select, Input, Checkbox, CheckboxGroup, Text, Progress, CircularProgress } from '@chakra-ui/react';

const CompletionScreen = () => {
  return (
        <VStack spacing={6} align="center" justify="center" bg="#1e293b" p={8} rounded="lg" minHeight="70vh">
          <Text fontSize="2xl" color="white">Audit Completed!</Text>
          <Text color="gray.300">Your report will be ready soon. Have some coffee while waiting...</Text>
          <CircularProgress isIndeterminate color="green.300" size="120px" thickness="4px" />
        </VStack>
  );
};

export default CompletionScreen;
