import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, Tooltip, Divider } from '@chakra-ui/react';

const RunwayMetric = ({ runwayMonths, auditData }) => {
  const [displayRunwayDays, setDisplayRunwayDays] = useState(0);
  const [endDate, setEndDate] = useState('');
  const [displayCash, setDisplayCash] = useState(0);
  const [decrementPerSecond, setDecrementPerSecond] = useState(0);

  useEffect(() => {
    try {
      const storedAuditData = auditData || JSON.parse(localStorage.getItem('auditData')) || {};

      const lastMonthRevenue = storedAuditData?.revenueLastMonths?.lastMonthRevenue || 0;
      const lastMonthCost = storedAuditData?.monthlyCost?.lastMonthCost || 0;

      // Ensure burn rate is always positive
      const burnRate = Math.abs(lastMonthCost - lastMonthRevenue);

      // Initial cash based on burn rate and runway months
      const initialCash = runwayMonths * burnRate;

      // Daily burn rate
      const dailyBurn = burnRate / 30.44;

      // Calculate days since report creation
      const reportCreationDate = new Date(storedAuditData.createdAt || new Date());
      const today = new Date();
      const daysSinceReportCreation = Math.floor((today - reportCreationDate) / (1000 * 60 * 60 * 24));
      const totalRunwayDays = Math.round(runwayMonths * 30.44);
      const currentRunwayDays = Math.max(totalRunwayDays - daysSinceReportCreation, 0);

      // Calculate decrement per second
      const totalSecondsRemaining = currentRunwayDays * 24 * 60 * 60;
      const decrementPerSec = initialCash / totalSecondsRemaining;

      // Update state variables
      setDisplayRunwayDays(currentRunwayDays);
      setDecrementPerSecond(decrementPerSec);

      // Calculate the initial cash at this point in time
      const secondsSinceReportCreation = daysSinceReportCreation * 24 * 60 * 60;
      const currentDisplayCash = initialCash - (secondsSinceReportCreation * decrementPerSec);
      setDisplayCash(Math.max(currentDisplayCash, 0));

      // Calculate and set the end date
      const endDateObj = new Date(today.getTime() + currentRunwayDays * 24 * 60 * 60 * 1000);
      setEndDate(endDateObj.toLocaleString('default', { month: 'long', year: 'numeric' }));
    } catch (error) {
      console.error('Error in calculating runway metrics:', error);
    }
  }, [runwayMonths, auditData]);

  useEffect(() => {
    if (displayCash > 0 && decrementPerSecond > 0) {
      const countdownInterval = setInterval(() => {
        setDisplayCash((prevCash) => Math.max(prevCash - decrementPerSecond, 0));
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [decrementPerSecond, displayCash]);

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <StatLabel color="gray.300" cursor="help">
          Runway
        </StatLabel>
  
        <StatNumber color="white">
          {displayRunwayDays.toLocaleString()} days
        </StatNumber>
  
        <Divider mt={3} mb={2} borderColor="gray.600" />
  
        <Text fontSize="sm" color="gray.400">
          Runs out in: <Text as="span" color="white">{endDate}</Text>
        </Text>
  
        <Tooltip label="This is an estimated amount based on your burn rate and runway duration." aria-label="Estimated cash tooltip">
          <Text fontSize="sm" color="gray.400" mt={0} cursor="help">
            In your bank: <Text as="span" color="white">${displayCash.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Text>
          </Text>
        </Tooltip>
      </Stat>
    </Box>
  );
};

export default RunwayMetric;
