import React, { useMemo } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import ProductReadinessScore from './topMetrics/ProductReadinessScore';
import ProductDifferentiation from './topMetrics/ProductDifferentiation';
import DevelopmentStage from './topMetrics/DevelopmentStage';
import PatentsProprietaryTech from './topMetrics/PatentsProprietaryTech';
import ProductMarketFit from './topMetrics/ProductMarketFit';

const ProductTopMetrics = () => {
  const auditData = useMemo(() => JSON.parse(localStorage.getItem('auditData')), []);
  const reportData = useMemo(() => JSON.parse(localStorage.getItem('reportData')), []);

  return (
    <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={6}>
      <ProductReadinessScore score={reportData?.sectionScores?.productReadiness || 0} />
      <ProductDifferentiation auditData={auditData} />
      <DevelopmentStage auditData={auditData} />
      <PatentsProprietaryTech auditData={auditData} />
      <ProductMarketFit auditData={auditData} />
    </SimpleGrid>
  );
};

export default ProductTopMetrics;
