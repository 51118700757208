import React, { useMemo } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import CompetitorsCount from './topMetrics/CompetitorsCount';
import MarketOpportunityScore from './topMetrics/MarketOpportunityScore';
import MarketPenetrationSpeed from './topMetrics/MarketPenetrationSpeed';
import TargetMarketSize from './topMetrics/TargetMarketSize';

const MarketTopMetrics = () => {
  const auditData = useMemo(() => JSON.parse(localStorage.getItem('auditData')), []);
  const reportData = useMemo(() => JSON.parse(localStorage.getItem('reportData')), []);

  return (
    <SimpleGrid columns={[1, 2, 3, 4]} spacing={6}>
      <MarketOpportunityScore score={reportData?.sectionScores?.marketOpportunity || 0} />
      <CompetitorsCount auditData={auditData} />
      <MarketPenetrationSpeed reportData={reportData} />
      <TargetMarketSize auditData={auditData} />
    </SimpleGrid>
  );
};

export default MarketTopMetrics;
