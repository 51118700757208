import React, { useRef, useEffect } from 'react';
import { Field, FieldArray } from 'formik';
import { Select, Checkbox, CheckboxGroup, Input, Textarea, IconButton, VStack, Box, Button, HStack, Text } from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';
import axios from 'axios';

import { getAuditValidationSchema } from './validationSchemas';
import { API_URL } from '../constants';
import { allCountries, regions, industries, investmentStages, customerTypes, useOfFunds, stageOptions, marketOpportunities, competitorLandscapes, productRoadmaps, gtmStrategies, founderExperiences, months } from './options';
import auditQuestions from './auditQuestions';
import auditQuestionsV2 from './auditQuestionsV2';

// Auto-resizing Textarea component with ref handling
const AutoResizeTextarea = ({ name, placeholder, bg, borderColor, color, value, setFieldValue }) => {
  const textareaRef = useRef(null);

  const handleTextareaChange = (e) => {
    setFieldValue(name, e.target.value || '');

    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 500)}px`;
    }
  };

  useEffect(() => {
    if (textareaRef.current && value) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 500)}px`;
    }
  }, [value]);

  return (
    <Textarea
      ref={textareaRef}
      name={name}
      placeholder={placeholder}
      bg={bg}
      borderColor={borderColor}
      color={color}
      width="100%"
      resize="vertical"
      minH="100px"
      maxH="500px"
      value={value}
      onChange={handleTextareaChange}
    />
  );
};

// Helper function to generate years for year-dropdown
export const generateYears = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 20 }, (_, i) => currentYear - i);
};

// Helper function to generate months for month-dropdown
export const generateMonths = () => [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' }
];

// Helper function to dynamically choose which auditQuestions to use
export const getAuditQuestions = (version) => version === 'v2' ? auditQuestionsV2 : auditQuestions;

// Helper function to determine if a question should be displayed based on a conditional
export const shouldDisplayQuestion = (question, values) => {
  if (!question.conditional) return true;
  const { field, value } = question.conditional;
  return values[field] === value;
};

// Function to generate validation schema for each step based on the current step
export const generateStepValidationSchema = (step, values, version = 'v2') => {
  const auditQuestions = getAuditQuestions(version);
  const currentQuestion = auditQuestions[step - 1];

  if (!currentQuestion || !shouldDisplayQuestion(currentQuestion, values)) {
    return Yup.object().shape({});
  }

  const validationField = currentQuestion.fieldName;
  const validationSchema = getAuditValidationSchema(version);

  return Yup.object().shape({
    [validationField]: validationSchema.fields[validationField]
  });
};

// Function to validate if a step should be skipped based on conditional logic
export const validateStep = (step, values, version = 'v1') => {
  const auditQuestions = getAuditQuestions(version);
  const question = auditQuestions[step - 1];
  return question && !shouldDisplayQuestion(question, values);
};

// Function to set default values for different input types
export const initializeFormValues = (version = 'v1') => {
  const auditQuestions = getAuditQuestions(version);
  const initialValues = {};
  auditQuestions.forEach((question) => {
    switch (question.inputType) {
      case 'multi-select':
      case 'multi-text':
        initialValues[question.fieldName] = [];
        break;
      case 'dropdown':
      case 'year-dropdown':
      case 'founder-dropdown':
      case 'month-year-dropdown': 
        initialValues[question.fieldName] = ''; 
        break;
      case 'number':
      case 'formatted-number':
        initialValues[question.fieldName] = 0;
        break;
      case 'textarea':
      case 'text':
      default:
        initialValues[question.fieldName] = '';
        break;
    }
  });
  return initialValues;
};

// Helper function to render the correct input field based on type
export const renderField = (question, values, setFieldValue) => {
  switch (question.inputType) {
    case 'text':
      return (
        <Field
          as={Input}
          name={question.fieldName}
          placeholder={question.placeholder || ''} 
          bg="gray.700"
          borderColor="gray.600"
          color="white"
          width="100%"
          autoFocus
        />
      );

    case 'textarea':
      return (
        <AutoResizeTextarea
          name={question.fieldName}
          placeholder={question.placeholder || ''}
          bg="gray.700"
          borderColor="gray.600"
          color="white"
          value={values[question.fieldName] || ''}
          setFieldValue={setFieldValue}
        />
      );

    case 'number':
      return (
        <NumericFormat
          name={question.fieldName}
          placeholder={`Enter ${question.fieldName.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
          thousandSeparator={true}
          allowEmptyFormatting={true}
          customInput={Input}
          onValueChange={(val) => setFieldValue(question.fieldName, val.value || '')}
          value={values[question.fieldName] || ''}
          bg="gray.700"
          borderColor="gray.600"
          color="white"
          width="100%"
          autoFocus
        />
      );

    case 'formatted-number':
      return (
        <NumericFormat
          name={question.fieldName}
          placeholder={question.placeholder || `$`}
          thousandSeparator={true}
          prefix={'$'}
          allowEmptyFormatting={true}
          customInput={Input}
          onValueChange={(val) => setFieldValue(question.fieldName, val.value || '')}
          value={values[question.fieldName] || ''}
          bg="gray.700"
          borderColor="gray.600"
          color="white"
          width="100%"
          autoFocus
        />
      );
    case 'multi-text':
      return (
        <FieldArray name={question.fieldName}>
          {({ remove, push }) => (
            <VStack align="start" spacing={2}>
              {values[question.fieldName]?.map((url, index) => (
                <Box key={index} width="100%" display="flex" alignItems="center">
                  <Field
                    as={Input}
                    name={`${question.fieldName}.${index}`}
                    placeholder="Enter URL"
                    bg="gray.700"
                    borderColor="gray.600"
                    color="white"
                    width="90%"
                    autoFocus
                  />
                  <IconButton
                    aria-label="Remove URL"
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    onClick={() => remove(index)}
                    ml={2}
                  />
                </Box>
              ))}
              <Button onClick={() => push('')} leftIcon={<AddIcon />} colorScheme="green" size="sm">
                Add another URL
              </Button>
            </VStack>
          )}
        </FieldArray>
      );
    case 'dropdown':
      const options = Array.isArray(question.options) ? question.options : getOptions(question.options);
      return (
        <Field as={Select} name={question.fieldName} bg="gray.700" borderColor="gray.600" color="white" width="100%" autoFocus>
          <option value="">{`Select ${question.placeholder || question.fieldName.replace(/([A-Z])/g, ' $1').toLowerCase()}`}</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </Field>
      );
      case 'year-dropdown':
        const yearOptions = generateYears();
        return (
          <Field as={Select} name={question.fieldName} bg="gray.700" borderColor="gray.600" color="white" width="100%" autoFocus>
            <option value="">{question.placeholder || "Select Year"}</option>
            {yearOptions.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </Field>
        );

        case 'multi-select': {
          const multiSelectOptions = Array.isArray(question.options) ? question.options : getOptions(question.options);
          return (
            <Field name={question.fieldName}>
              {({ field }) => (
                <CheckboxGroup
                  value={field.value || []}  
                  onChange={(values) => setFieldValue(question.fieldName, values)}
                >
                  <VStack align="start" spacing={2}>
                    {multiSelectOptions.map((option, index) => (
                      <Checkbox key={index} value={option} colorScheme="green" color="gray.300">
                        {option}
                      </Checkbox>
                    ))}
                  </VStack>
                </CheckboxGroup>
              )}
            </Field>
          );
        }           

        case 'multi-number':
          return (
            <VStack align="start" spacing={2}>
              {question.fields.map((field, index) => (
                <Box key={index} width="100%" display="flex" alignItems="center">
                  <NumericFormat
                    name={`${question.fieldName}.${field.name}`}
                    placeholder={field.label} 
                    thousandSeparator={true}
                    prefix={field.format === 'currency' ? '$' : ''}  
                    customInput={Input}
                    onValueChange={(val) => setFieldValue(`${question.fieldName}.${field.name}`, val.value || '')}
                    value={values[question.fieldName]?.[field.name] || ''}  
                    bg="gray.700"
                    borderColor="gray.600"
                    color="white"
                    width="100%"
                  />
                </Box>
              ))}
            </VStack>
          );                  
  
    case 'founder-dropdown':
      const founderOptions = Array.from({ length: 10 }, (_, i) => i + 1);
      return (
        <Field as={Select} name={question.fieldName} bg="gray.700" borderColor="gray.600" color="white" width="100%" autoFocus>
          <option value="">{question.placeholder || "Select Number of Founders"}</option>
          {founderOptions.map((founder, index) => (
            <option key={index} value={founder}>
              {founder}
            </option>
          ))}
        </Field>
      );

      case 'month-year-dropdown':
        const months = generateMonths();
        const years = generateYears();
      
        // Parse foundingDate to extract the current month and year for the dropdowns
        const [currentYear, currentMonth] = (values[question.fieldName] || '').split('-');
      
        return (
          <HStack spacing={4} width="100%">
            {/* Month Dropdown */}
            <Field
              as={Select}
              name={`${question.fieldName}_month`}  // Temporary field to handle UI
              bg="gray.700"
              borderColor="gray.600"
              color="white"
              placeholder="Select Month"
              value={currentMonth || ''}  // Prepopulate the current month if available
              width="50%"
              onChange={(e) => {
                const month = e.target.value;
                const year = currentYear || '';  // Keep the existing year
                setFieldValue(question.fieldName, `${year}-${month}`);  // Save the final string
              }}
            >
              {months.map((month, index) => (
                <option key={index} value={month.value}>
                  {month.label}
                </option>
              ))}
            </Field>
      
            {/* Year Dropdown */}
            <Field
              as={Select}
              name={`${question.fieldName}_year`}  // Temporary field to handle UI
              bg="gray.700"
              borderColor="gray.600"
              color="white"
              placeholder="Select Year"
              value={currentYear || ''}  // Prepopulate the current year if available
              width="50%"
              onChange={(e) => {
                const year = e.target.value;
                const month = currentMonth || '';  // Keep the existing month
                setFieldValue(question.fieldName, `${year}-${month}`);  // Save the final string
              }}
            >
              {years.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </Field>
          </HStack>
        );      
    default:
      return null;
  }
};

// Helper function to get options from utils
export const getOptions = (optionsKey) => {
  switch (optionsKey) {
    case 'allCountries':
      return allCountries;
    case 'regions':
      return regions;
    case 'industries':
      return industries;
    case 'investmentStages':
      return investmentStages;
    case 'customerTypes':
      return customerTypes;
    case 'useOfFunds':
      return useOfFunds;
    case 'stageOptions':
      return stageOptions;
    case 'marketOpportunities':
      return marketOpportunities;
    case 'competitorLandscapes':
      return competitorLandscapes;
    case 'productRoadmaps':
      return productRoadmaps;
    case 'gtmStrategies':
      return gtmStrategies;
    case 'founderExperiences':
      return founderExperiences;
    case 'months':
      return months;
    default:
      return [];
  }
};

// Create report after audit submission
export const createReportAfterAudit = async (auditId) => {
  try {
    const response = await axios.post(`${API_URL}/reports`, {
      auditId,
      status: 'processing',
      sectionScores: {
        financials: null,
        market: null,
        product: null,
        team: null,
        goToMarket: null,
        traction: null,
        risks: null,
        useOfFunds: null,
        customerFeedback: null,
        productAdoption: null,
      },
      recommendations: {
        financials: '',
        market: '',
        product: '',
        team: '',
        goToMarket: '',
        traction: '',
        risks: '',
        useOfFunds: '',
        customerFeedback: '',
        productAdoption: '',
      },
      overallScore: null,
    });

    console.log('Report initialized:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating report:', error);
    throw error;
  }
};
