import React, { useEffect, useState } from 'react';
import { Box, Stat, StatLabel, StatNumber, Text, Tooltip, Divider, VStack } from '@chakra-ui/react';

const TotalCustomers = ({ auditData, reportData }) => {
  const [customerHistory, setCustomerHistory] = useState([]);
  const [currentCustomerCount, setCurrentCustomerCount] = useState(0);
  const [payingCustomerCount, setPayingCustomerCount] = useState(0);

  // Fetch data from local storage or props (reportData and auditData)
  useEffect(() => {
    const storedReportData = JSON.parse(localStorage.getItem('reportData'));
    const storedAuditData = JSON.parse(localStorage.getItem('auditData')) || auditData;

    // Fetch total customers from reportData
    if (storedReportData && storedReportData.timeSeriesData) {
      const { customerHistory } = storedReportData.timeSeriesData;
      setCustomerHistory(customerHistory || []);
      setCurrentCustomerCount(customerHistory[customerHistory.length - 1]?.customers || 0);
    } else if (reportData) {
      const { customerHistory } = reportData.timeSeriesData;
      setCustomerHistory(customerHistory || []);
      setCurrentCustomerCount(customerHistory[customerHistory.length - 1]?.customers || 0);
    }

    // Fetch paying customers from auditData
    if (storedAuditData) {
      const lastMonthPayingCustomers = storedAuditData.payingCustomersLastMonths?.lastMonthCustomers || 0;
      setPayingCustomerCount(lastMonthPayingCustomers);
    }
  }, [auditData, reportData]);

  // Calculate paying customer percentage
  const payingCustomerPercentage = currentCustomerCount > 0
    ? ((payingCustomerCount / currentCustomerCount) * 100).toFixed(1)
    : 0;

  return (
    <Box p={4} bg="rgba(28, 35, 54)" borderRadius="md">
      <Stat>
        <Tooltip
          label={
            <>
              <Text fontWeight="bold">Total Customers</Text>
              <Text>This represents the total number of customers, including paying customers.</Text>
            </>
          }
          aria-label="Total Customers tooltip"
          placement="top"
          hasArrow
          bg="gray.700"
          color="white"
          p={3}
          borderRadius="md"
          fontSize="xs"
          textAlign="left"
        >
          <StatLabel color="gray.300" cursor="help">Total Customers</StatLabel>
        </Tooltip>

        <StatNumber color="white" fontSize="2xl" mt={0}>
          {currentCustomerCount.toLocaleString()}
        </StatNumber>

        <Divider my={2} borderColor="gray.600" />

        {/* Display total paying customers and percentage */}
        <VStack align="start" spacing={0}>
          <Text fontSize="sm" color="gray.400">
            Paying Customers:
          </Text>
          <Text fontSize="sm" color="white" mt={0}>
            {payingCustomerCount.toLocaleString()} &nbsp; 
            <Text as="span" color="gray.400">({payingCustomerPercentage}%)</Text>
          </Text>
        </VStack>
      </Stat>
    </Box>
  );
};

export default TotalCustomers;
